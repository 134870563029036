import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { setDefaultOptions } from 'date-fns'
import { enGB, cs, el, sk, pl } from 'date-fns/locale'

import enTranslation from './en.json';
import csTranslation from './cs.json';
import elTranslation from './el.json';
import skTranslation from './sk.json';
import plTranslation from './pl.json';

const resources = {
    en: { translation: enTranslation },
    cs: { translation: csTranslation },
    el: { translation: elTranslation },
    sk: { translation: skTranslation },
    pl: { translation: plTranslation }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next).init({
        resources,
        debug: false,
        //lng: 'en-US',
        fallbackLng: 'en-US',
        interpolation: {
            escapeValue: false
        }
    });

if (i18n.language.substring(0, 2) === 'cs') setDefaultOptions({ locale: cs });
else if (i18n.language.substring(0, 2) === 'en') setDefaultOptions({ locale: enGB })
else if (i18n.language.substring(0, 2) === 'el') setDefaultOptions({ locale: el })
else if (i18n.language.substring(0, 2) === 'sk') setDefaultOptions({ locale: sk })
else if (i18n.language.substring(0, 2) === 'pl') setDefaultOptions({ locale: pl })
    
document.documentElement.lang = i18n.language

export default i18n;