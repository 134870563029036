import React, { useEffect, useState } from "react";
import GridTable from '@nadavshaar/react-grid-table';
import { useTranslation } from 'react-i18next';

import { AiOutlineSearch } from 'react-icons/ai'

import { dddVehicleColumns } from "./DDDViewColumns";
import { dddViewVehicleRows } from "./DDDViewRows";
import { Loader } from "../RidesView/ridesRows";
import DDDViewFile from "./DDDViewFile"
import DDDViewListFilesModal from "./DDDViewListFilesModal"
import Loading from "../Loading";

export default function DDDViewVehicle({ devices, files, filesInfo, openFile, loading, setOpenFile, height, setFileDownload }) {
    const { t } = useTranslation('translation');
    const [rows, setRows] = useState([]);
    const [fileInfoOpen, setFileInfoOpen] = useState(false);
    const [filesList, setFileList] = useState(false);
    // const [isAscending, seIsAscending] = useState({ colId: 3, isAsc: true });

    const c = dddVehicleColumns(setOpenFile, setFileInfoOpen, setFileList);

    useEffect(() => {
        if (filesInfo) setRows(dddViewVehicleRows(filesInfo, devices))
    }, [filesInfo])
    return (
        <div style={{ height: height + 'px' }}>
            {!loading ? (
                <GridTable
                    style={{ minHeight: 'unset', height: height + 'px' }}
                    columns={c}
                    isLoading={loading}
                    pageSize={5000}
                    rows={rows}
                    showSearch={true}
                    showColumnVisibilityManager={false}
                    showRowsInformation={false}
                    isPaginated={false}
                    texts={{
                        search: '',
                        noResults: t('devices.notFind')
                    }}
                    className="DDDViewVehicle"
                    icons={{ search: <AiOutlineSearch /> }}
                    onSearchTextChange={(searchText) => { sessionStorage.setItem("d-search", searchText) }}
                    // onColumnResizeEnd={({ column }, tableManager) => { updateColumnsLayout(tableManager) }}
                    // onColumnReorderEnd={(sortData, tableManager) => { updateColumnsLayout(tableManager) }}
                    // onSortChange={({ colId, isAsc }) => seIsAscenfding({ colId: colId, isAsc: isAsc })}
                    components={{ Loader }}
                    enableColumnsReorder={false}
                // sort={(isAscending)}
                />) : (<Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} className="mt-5" height={height} />)}
            {openFile && fileInfoOpen ? (
                <DDDViewFile
                    openFile={openFile}
                    fileInfoOpen={fileInfoOpen}
                    setOpenFile={setOpenFile}
                />
            ) : null}

            {filesList && files.length > 0 ? (
                <DDDViewListFilesModal
                    files={files}
                    filesList={filesList}
                    setFileList={setFileList}
                    setOpenFile={setOpenFile}
                    openFile={openFile}
                    setFileDownload={setFileDownload}
                    loading={loading}
                />
            ) : null}

        </div>

    )
}