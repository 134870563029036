export const layoutTachoDefault = {
    lg: [
        { w: 6, h: 10, x: 0, y: 0, i: "DDDViewVehicle", minH: 5, minW: 4 },
        { w: 6, h: 10, x: 6, y: 0, i: "DDDViewDrivers", minH: 5, minW: 4 },
        { w: 12, h: 8, x: 0, y: 10, i: "DDDViewListFiles", minH: 7, minW: 9 }
    ],
    md: [
        { w: 5, h: 8, x: 0, y: 0, i: "DDDViewVehicle", minH: 5, minW: 4 },
        { w: 5, h: 8, x: 5, y: 0, i: "DDDViewDrivers", minH: 5, minW: 4 },
        { w: 10, h: 10, x: 0, y: 10, i: "DDDViewListFiles", minH: 7, minW: 9 }
    ],
    sm: [
        { w: 8, h: 6, x: 0, y: 0, i: "DDDViewVehicle", minH: 5, minW: 4 },
        { w: 8, h: 6, x: 0, y: 5, i: "DDDViewDrivers", minH: 5, minW: 4 },
        { w: 8, h: 10, x: 0, y: 10, i: "DDDViewListFiles", minH: 7, minW: 9 }
    ],
    xs: [
        { w: 4, h: 5, x: 0, y: 0, i: "DDDViewVehicle", minH: 5, minW: 4 },
        { w: 4, h: 5, x: 0, y: 5, i: "DDDViewDrivers", minH: 5, minW: 4 },
        { w: 9, h: 10, x: 0, y: 10, i: "DDDViewListFiles", minH: 7, minW: 9 }
    ],
}

export const layoutTachoAll = [
    { w: 6, h: 8, x: 0, y: 0, i: "DDDViewVehicle", minH: 5, minW: 4 },
    { w: 6, h: 8, x: 6, y: 0, i: "DDDViewDrivers", minH: 5, minW: 4 },
    { w: 12, h: 7, x: 0, y: 10, i: "DDDViewListFiles", minH: 7, minW: 9 },
]

export const layoutNew = (name) => {
    if (name === 'DDDViewVehicle') return { w: 6, h: 5, x: 0, y: 0, i: "DDDViewVehicle", minH: 5, minW: 4 }
    if (name === 'DDDViewDrivers') return { w: 6, h: 5, x: 0, y: 0, i: "DDDViewDrivers", minH: 5, minW: 4 }
    if (name === 'DDDViewListFiles') return { w: 9, h: 7, x: 0, y: 0, i: "DDDViewListFiles", minH: 7, minW: 9 }
}
