import React, { useEffect, useState, useContext } from "react";
import { client } from 'websocket';
import { useTranslation } from 'react-i18next';
import { MdEditRoad } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { Form, Button, FloatingLabel, InputGroup, Spinner, ButtonGroup } from "react-bootstrap";
import { SelectComponentDrivers, SelectComponentPurposes } from "../SelectComponent/SelectComponent"

// import { EditRides } from "../../images";
import { isClient, UserContext } from "../../components";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoMdPerson, IoMdPersonAdd } from "react-icons/io";
import { MdOutlineNoteAlt, MdWorkOutline, MdOutlineMapsHomeWork } from "react-icons/md";

export default function RidesEdit({ ids, WsSend, editRides, setEditRides, drivers, w, m, columns, purposes }) {
    const { t, i18n } = useTranslation('translation');
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState(false);
    const [loading, setLoading] = useState(false);

    const { costumer } = useContext(UserContext)

    const handleOnChange = (value, type) => {
        if (type === 'purpose') setEditData((userData) => { return { ...userData, purpose: value } })
        if (type === 'center') setEditData((userData) => { return { ...userData, center: value } })
        if (type === 'order') setEditData((userData) => { return { ...userData, order: value } })
        if (type === 'isprivate') setEditData((userData) => { return { ...userData, isprivate: value } })
    }

    const handleNewNumeric6 = (value) => {
        const regex = /^\d{6}$/;
        return regex.test(value);
    }


    const handleClose = () => {
        setOpen(false);
        setEditData(false)
        setEditRides(false)
        setLoading(false)
    }

    useEffect(() => {
        if (editRides) handleClose()
    }, [editRides])

    const checkData = (e) => {
        let res = false;
        Object.values(e).forEach((value) => {
            if (value || value === false) res = true;
        })
        return res
    }
    const pA = columns.some(c => c.field === 'purpose_array' && c.visible);
    let pCenter, pOrder;
    if (pA && purposes) {
        const pf = purposes.filter(p => p.includes('|'));
        pCenter = [...new Set(pf.map(p => { return p.split('|')[0] }))]
        pOrder = [...new Set(pf.map(p => { return p.split('|')[1] }))]
    }
    return (
        <>
            <Button variant="light" className="ms-2 my-2" onClick={() => { setOpen(true) }} disabled={ids.length === 0} title={t('widget.editRidesChoosed')}>
                <MdEditRoad className="fs-4" />
                <span className={(w < 850) ? (m ? "ms-1" : '') : "ms-1"}>{(w < 850) ? (m ? t('widget.edit') : '') : t('widget.edit')}</span>
            </Button>
            <Modal size='lg' fullscreen='sm-down' centered show={open} onHide={handleClose} >
                <Modal.Header closeButton >
                    <Modal.Title>{t('widget.editRidesChoosed')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="ridesEdit">
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        if (editData) {
                            let ws = {
                                method: 'setRides',
                                empty: '',
                                oid: 17,
                                id: ids,
                            }
                            if (editData.purpose) ws = { ...ws, purpose: editData.purpose }
                            if (editData.driver) ws = { ...ws, driver: editData.driver.value }
                            if (editData.driver2) ws = { ...ws, driver2: editData.driver2.value }
                            if (editData.center && editData.order) ws = { ...ws, purpose: editData.center + '|' + editData.order }
                            if (editData.center && !editData.order) ws = { ...ws, purpose: editData.center + '|' }
                            if (!editData.center && editData.order) ws = { ...ws, purpose: '|' + editData.order }
                            if (editData.isprivate === true || editData.isprivate === false) ws = { ...ws, isprivate: editData.isprivate }
                            setLoading(true)
                            WsSend(ws);
                        }
                    }}>
                        <div className="d-flex flex-column align-items-center">
                            {!pA ?
                                <InputGroup className="my-2 justify-content-center" >
                                    <InputGroup.Text><MdOutlineNoteAlt /></InputGroup.Text>
                                    <SelectComponentPurposes
                                        className={"w-75 max-w-50vh"}
                                        purposes={purposes}
                                        setPurpose={handleOnChange}
                                        source={'purpose'}
                                    />
                                </InputGroup>
                                : <></>}
                            {!drivers || (drivers && !(drivers.length > 0)) ? '' : (<>
                                <InputGroup className="my-2 justify-content-center" >
                                    <InputGroup.Text><IoMdPerson /></InputGroup.Text>
                                    <SelectComponentDrivers
                                        className={"w-75 max-w-50vh"}
                                        drivers={drivers}
                                        data={editData}
                                        setData={setEditData}
                                    />
                                </InputGroup>

                                <InputGroup className="my-2 justify-content-center" >
                                    <InputGroup.Text><IoMdPersonAdd /></InputGroup.Text>
                                    <SelectComponentDrivers
                                        className={"w-75 max-w-50vh"}
                                        drivers={drivers}
                                        data={editData}
                                        setData={setEditData}
                                        driver2={true}
                                    />
                                </InputGroup></>)}

                            <ButtonGroup className="my-2">
                                <Button active={editData.isprivate === false} onClick={() => handleOnChange(false, 'isprivate')} variant="light">Služební</Button>
                                <Button active={editData.isprivate === true} onClick={() => handleOnChange(true, 'isprivate')} variant="light">Soukromá</Button>
                            </ButtonGroup>

                            {pA ?
                                <>
                                    <InputGroup className="my-2 justify-content-center" >
                                        <InputGroup.Text><MdOutlineMapsHomeWork /></InputGroup.Text>
                                        <SelectComponentPurposes
                                            className={"w-75 max-w-50vh"}
                                            purposes={pCenter}
                                            setPurpose={handleOnChange}
                                            source={'center'}
                                        />
                                    </InputGroup>
                                    <InputGroup className="my-2 justify-content-center" >
                                        <InputGroup.Text><MdWorkOutline /></InputGroup.Text>
                                        {/* costumer && costumer.match(/\[(.*?)\]/)[1] === '06065' && client.url === 'wss://ws02.logiscare.com/' */}
                                        {isClient('geoTest', costumer) ? <SelectComponentPurposes
                                            className={"w-75 max-w-50vh"}
                                            purposes={pOrder}
                                            setPurpose={handleOnChange}
                                            source={'order'}
                                            isValidNewOption={handleNewNumeric6}
                                            noOptionsMessage={() => 'Žádný výsledek hledání. Pro vytvoření napiště 6 číslic.'}
                                        /> : <SelectComponentPurposes
                                            className={"w-75 max-w-50vh"}
                                            purposes={pOrder}
                                            setPurpose={handleOnChange}
                                            source={'order'}
                                        />}

                                    </InputGroup>
                                    <Button className="mb-3" variant="outline-secondary" active={editData.purpose === 'null' ? true : false} onClick={(e) => { handleOnChange(' ', 'purpose'); setTimeout(() => { document.getElementById('submitButton').click() }, 100) }} title={t('stats.deletePurposeArray')}>
                                        <div>
                                            <FaRegTrashAlt className="mb-1 me-3" />
                                            <span>{t('stats.deletePurposeArray')}</span>
                                        </div>
                                    </Button>
                                </> : ''}


                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <Button className={false ? "w2-5" : "w-75 " + "max-w-50vh mb-3 my-2 btn btn-dark"} id="submitButton" type="submit" disabled={checkData(editData) ? false : true}>{
                                loading ? <Spinner animation="border" role="status"><span className="visually-hidden">{t('loading')}</span></Spinner> : t('login.save')
                            }</Button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal >
        </>

    )
}