import Modal from 'react-bootstrap/Modal';

import './loading.css'

export default function Loading({ color, modal, show, height }) {
    if (modal) return (
        <Modal
            centered
            show={show}
            className="d-flex justify-content-center modalLoading"
            fullscreen='sm-down'>
            <Modal.Body>
                <div className="loadingio-spinner-ring">
                    <div className="ldio">
                        <div style={{ borderColor: `transparent ${color} transparent ${color}` }}></div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
    else return (
        <div className={`w-100 ${height ? '' : 'vh-100'} d-flex justify-content-center align-items-center`} style={height ? { height: height + 'px' } : {}}>
            <div className="loadingio-spinner-ring">
                <div className="ldio">
                    <div style={{ borderColor: `transparent ${color} transparent ${color}` }}></div>
                </div>
            </div>
        </div>
    )
}