import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap"

import { FiDownload } from 'react-icons/fi';

import { DDDViewListFiles } from '../../components';
import { DatePickerComponentFull } from '../DatePickerComponent/DatePickerComponent'
import { formatDateObjectTime } from "../../components/DatePickerComponent/DatePickerConfig"
import { newDate, lastThreeMonths, thisWeek } from "../DatePickerComponent/DateSelect";
import { WsSend } from "../../scripts/ws";


export default function DDDViewListFilesModal({ files, filesList, setFileList, setOpenFile, setFileDownload, openFile }) {
    const { t } = useTranslation('translation', { keyPrefix: 'ddd' });
    const [stamp, setStamp] = useState(false);
    const [selected, setSelected] = useState(false);
    //console.log(filesList);
    useEffect(() => {
        if (!stamp && sessionStorage.getItem('date-ddd')) setStamp(newDate(JSON.parse(sessionStorage.getItem('date-ddd'))))
        else if (!stamp) {
            const firstDate = lastThreeMonths();
            if (firstDate.length > 1) {
                setStamp(firstDate)
            }
        }
        if (stamp && stamp.length === 2) {
            sessionStorage.setItem('date-ddd', JSON.stringify([stamp[0].format(formatDateObjectTime), stamp[1].format(formatDateObjectTime)]))
            setStamp(stamp)
        }
    }, [stamp])
    return (
        <Modal
            centered
            show={setFileList && !openFile}
            size="xl"
            fullscreen='xl-down'
            onHide={() => { setFileList(false) }} >
            <Modal.Header closeButton >
                <div>
                    <Modal.Title>{`${t('downloadedFilesDDD')}`}</Modal.Title>
                </div>
                <div className="m-auto"></div>
                <DatePickerComponentFull
                    date={stamp}
                    setDate={setStamp}
                    option={{ page: 'dddView' }}
                    m={true}
                />
                <Button variant="light ms-2 py-2 "
                    disabled={!selected.length}
                    onClick={() => {
                        WsSend({
                            method: 'getDownloadsFiles',
                            ids: selected,
                            oid: 25,
                        })
                    }}
                ><FiDownload className="fs-5" /></Button>
            </Modal.Header>
            <Modal.Body>
                <DDDViewListFiles
                    files={files}
                    filesList={filesList}
                    stamp={stamp}
                    setStamp={setStamp}
                    modal={true}
                    setOpenFile={setOpenFile}
                    setFileDownload={setFileDownload}
                    setSelectedModal={setSelected}
                />
            </Modal.Body>
        </Modal>
    )

}