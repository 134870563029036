import i18n from '../../dictionary/i18n';
import TileLayer from 'ol/layer/Tile';
import * as olSource from "ol/source";
import TileWMS from 'ol/source/TileWMS';
import VectorTile from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT.js';
import VectorTileLayer from 'ol/layer/VectorTile.js';
import { Style, Icon, Text, Fill, Stroke, Circle } from "ol/style";
import { iconMap, place_point_start, place_point_stop, notstatic_1_0, acceleration, breake, cornering, dirrection, dirrectionf, place_point_fueling } from "../../images/map";
import { scale } from 'ol/size';
import { easeOut } from 'ol/easing.js';

export const layers = (activeMapIndex, clientLayers) => {
    const mapLang = ['cs', 'sk', 'en'].includes(i18n.language) ? i18n.language : '';
    return [
        new TileLayer({
            title: 'Logiscare ' + i18n.t('maps.basic'),
            preload: Infinity,
            displayInLayerSwitcher: true,
            baseLayer: true,
            visible: activeMapIndex === 0 ? true : false,
            source: new olSource.OSM({
                // url: 'https://geo.logiscare.com/{z}/{x}/{y}.png',
                url: `//{a-d}.tile.logiscare.com/{z}/{x}/{y}.png?lang=${mapLang}`, //mode=[light,gray,night]
                //url: '//d.tile.logiscare.com/{z}/{x}/{y}.png?format=',
                //url: '//mt{0-3}.google.com/vt/lyrs=m&hl=cs&x={x}&y={y}&z={z}',
            }),
        }),
        new TileLayer({
            title: 'Google ' + i18n.t('maps.basic'),
            preload: Infinity,
            displayInLayerSwitcher: clientLayers ? clientLayers.some(l => l === 'google.map') : false,
            visible: activeMapIndex === 1 ? true : false,
            baseLayer: true,
            source: new olSource.OSM({
                //url: '//mt{0-3}.google.com/vt/lyrs=m&hl=cs&x={x}&y={y}&z={z}',
                url: 'https://iron.tricktools.eu/{z}/{x}/{y}.png',
            }),
        }),
        new TileLayer({
            title: 'Google ' + i18n.t('maps.satellite'),
            preload: Infinity,
            displayInLayerSwitcher: clientLayers ? clientLayers.some(l => l === 'google.hyb') : false,
            visible: activeMapIndex === 2 ? true : false,
            baseLayer: true,
            source: new olSource.OSM({
                // url: '//mt{0-3}.google.com/vt/lyrs=y&hl=cs&x={x}&y={y}&z={z}',
                url: 'https://carbon.tricktools.eu/{z}/{x}/{y}.png',
            }),
        }),
        // new TileLayer({
        //   title: 'Geoserver',
        //   // preload: Infinity,
        //   displayInLayerSwitcher: true,
        //   visible: true,
        //   // visible: false,
        //   source: new olSource.OSM({
        //      url: 'https://geo.logiscare.com/{z}/{x}/{y}.png',
        //     //url: '//d.tile.logiscare.com/{z}/{x}/{y}.png?format=',
        //     //url: '//mt{0-3}.google.com/vt/lyrs=m&hl=cs&x={x}&y={y}&z={z}',
        //   }),
        // }),
        // new TileLayer({
        //   title: 'Geo CS',
        //   // preload: Infinity,
        //   displayInLayerSwitcher: true,
        //   visible: false,
        //   // visible: false,
        //   source: new olSource.OSM({
        //      url: 'https://geo.logiscare.com/cs/{z}/{x}/{y}.png',
        //     //url: '//d.tile.logiscare.com/{z}/{x}/{y}.png?format=',
        //     //url: '//mt{0-3}.google.com/vt/lyrs=m&hl=cs&x={x}&y={y}&z={z}',
        //   }),
        // }),
    ]
}

export const layersTrafic = (refUiConfig, clientLayers) => {
    const trafficLayerParams = {
        "LAYERS": "traffic:traffic_map",
        "TILED": false,
        "FORMAT": "application/vnd.mapbox-vector-tile"
    };
    const trafficSourceOptions = {
        url: "https://traffic.logiscare.com/wms?",
        params: trafficLayerParams,
        serverType: "geoserver"
    };
    const WMSTileSource = new TileWMS(trafficSourceOptions);
    const mvtVectorSource = new VectorTile(
        Object.assign(
            trafficSourceOptions,
            {
                url: undefined,
                format: new MVT({ layerName: "traffic" }),
                tileUrlFunction: function (tileCoord, pixelRatio, projection) {
                    return WMSTileSource.tileUrlFunction(tileCoord, pixelRatio, projection);
                }
            }
        )
    );

    return new VectorTileLayer({
        className: "vectorTile",
        renderMode: "vector",
        declutter: true,
        format: new MVT(),
        source: mvtVectorSource,
        style: trafficStyle,
        title: i18n.t('maps.layerTrafic'),
        type: "overlay",
        visible: refUiConfig.current.map.trafic ? true : false,
        displayInLayerSwitcher: clientLayers ? clientLayers.some(l => l === 'traffic') : false,
    });
}

export function cLusterStyle(style, size) {
    return style = [new Style({
        image: new Circle({
            radius: 13,
            stroke: new Stroke({
                color: '#000000',
                width: 2,
            }),
            fill: new Fill({
                color: '#3399CC'
            })
        }),
        text: new Text({
            text: size.toString(),
            offsetY: 1,
            font: '12px sans-serif',
            fill: new Fill({
                color: '#fff'
            })
        })
    })]
}

function titleObject(title, dev) {
    switch (title) {
        case 'name':
            return dev.name
        case 'plate':
            return dev.plate
        case 'name+plate':
            return dev.name + ' - ' + dev.plate
        case 'name+driver':
            return dev.name + (dev.drivername ? (' - ' + dev.drivername) : '')
        default:
            return dev.name
    }
}

export function deviceStyle(dev, anime, course, index, title) {
    let winScale = window.devicePixelRatio;
    let Scale = winScale > 1 ? 100 / (winScale * 100) : 1;
    return new Style({
        zIndex: index,
        image: new Icon({
            color: '#' + dev.icolor,
            src: anime ? notstatic_1_0 : iconMap(dev),
            scale: 1,
            // scale: anime ? Scale : 1,
            rotateWithView: false,
            rotation: anime ? ((course ? course : dev.pos[7]) * (Math.PI / 180)) : 0,
            anchor: [0.5, 0.5]
            // anchor: anime ? [winScale / 2, winScale / 2] : [0.5, 0.5]
        }),
        text: new Text({
            text: titleObject(title, dev),
            offsetY: 20,
            scale: 1.2,
            fill: new Fill({
                color: '#' + dev.icolor
            }),
            stroke: new Stroke({
                color: 'rgba(255,255,255,1)',
                width: 3,
                lineCap: 'round'
            }),
        })
    });
}

// export function pulseStyle() {
//     let winScale = window.devicePixelRatio;
//     return new Style({
//         image: new Circle({
//             radius: 25 * winScale,
//             stroke: new Stroke({ color: 'red', width: (2.5 * winScale) }),
//         }),
//     })
// }

export function pulseStyle(elapsed, duration) {
    const elapsedRatio = elapsed / duration;
    const radius = easeOut(elapsedRatio) * 15 + 5;
    const opacity = easeOut(1 - elapsedRatio);

    return new Style({
        image: new Circle({
            radius: radius,
            stroke: new Stroke({
                color: 'rgba(255, 0, 0, ' + opacity + ')',
                width: 1.5 + opacity,
            }),
        }),
    });
}

export function pointStartStyle() {
    return new Style({
        image: new Icon({
            src: place_point_start,
        })
    })
}

export function pointStoptStyle() {
    return new Style({
        image: new Icon({
            src: place_point_stop,
        })
    })
}

export function pointFuelingStyle() {
    return new Style({
        image: new Icon({
            src: place_point_fueling,
        })
    })
}

export function pointGreenDrivingStyle(value) {
    if (value === 1)
        return new Style({
            image: new Icon({
                src: acceleration,
            })
        })
    else if (value === 2)
        return new Style({
            image: new Icon({
                src: breake,
            })
        })
    else if (value === 3)
        return new Style({
            image: new Icon({
                src: cornering,
            })
        })
}

export function directionStyle(rotation, speed, focused) {
    return new Style({
        image: new Icon({
            src: focused ? dirrectionf : dirrection,
            anchor: [0.75, 0.5],
            rotateWithView: true,
            rotation: -rotation,
            color: speedGroup(speed)
        })
    })
}

function speedGroup(speed) {
    if (!speed) return '#03AFEF'
    else {
        if (speed < 51) return '#03AF4E'
        if (speed < 91) return '#03AFEF'
        if (speed < 131) return '#F51C26'
        return '#AC00EE'
    }
}

export function routeStyleFocus(speed) {
    return [
        new Style({
            stroke: new Stroke({
                color: speedGroup(speed),
                width: 4,
                lineCap: 'round',
                lineJoin: 'round'
            }),
            zIndex: -1,
        }),
        new Style({
            stroke: new Stroke({
                color: '#000000',
                width: 7,
                lineCap: 'round',
                lineJoin: 'round'
            }),
            zIndex: -2,
        }),

    ]
}

export function routeStyle(speed) {
    return [new Style({
        stroke: new Stroke({
            color: speedGroup(speed),
            width: 5,
            lineCap: 'round',
            lineJoin: 'round'
        }),
        zIndex: -3,
    })]
}

export function routeStyleFuture() {
    return new Style({
        stroke: new Stroke({
            color: '#0FC000',
            width: 1,
            lineCap: 'round'
        })
    })
}

export function routeStyleAnime() {
    return new Style({
        stroke: new Stroke({
            color: '#0FC000',
            width: 5,
            lineCap: 'round'
        })
    })
}

function trafficStyle(feature) {
    if (feature.get('highway') == 'motorway' || feature.get('highway') == 'primary' || feature.get('highway') == 'trunk') {
        function trafficWidth(feature) {
            if (feature.get('highway') == 'motorway') return 4;
            if (feature.get('highway') == 'primary' || feature.get('highway') == 'trunk') return 3;
            return 2;
        }

        let f = feature.get("speed");
        if (f) {
            let sp = trafficSpeed(f, feature.get('highway') == 'motorway');
            if (sp) {
                return new Style({ stroke: new Stroke({ color: sp, width: trafficWidth(feature) }) });
            } else {
                return null;
            }
        }
        return null;

        function trafficSpeed(sp, motorway) {
            if (motorway) {
                if (sp == null || sp == 0) return null;
                if (sp < 5000) return '#A92626';
                if (sp < 7000) return '#F24E42';
                if (sp < 9000) return '#17E098';
                return '#17E098';
            }
            else {
                if (sp == null || sp == 0) return null;
                if (sp < 2000) return '#A92626';
                if (sp < 4000) return '#F24E42';
                if (sp < 9000) return '#17E098';
                return '#17E098';
            }
        }
    } else return null
}
