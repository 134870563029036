import React, { useState, useContext, useRef, useEffect, Suspense, lazy } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Form, InputGroup } from "react-bootstrap"
import { useNavigate, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import { UserContext } from "../../components";
import { checkClientRoles, checkNavbarItem, isClient, normalizeText } from "../../components/tools";
import { listMenu } from "./listMenu";
import { IconsMenu } from "../../images";
import './Menu.css'

import { BsBuildingsFill } from "react-icons/bs";
import { IoIosStarOutline, IoIosStar, IoIosArrowBack, IoIosSearch } from "react-icons/io";
import { FaCar, FaRoute } from 'react-icons/fa';
import { IoStatsChartSharp, IoSettings } from 'react-icons/io5'
import { FaTachographDigital } from "react-icons/fa6";
import { MdDevices, MdOutlineMailOutline, MdOutlineSensors } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import { IoListSharp } from "react-icons/io5";
import { TfiFiles } from "react-icons/tfi";
import { FaAddressCard } from "react-icons/fa6";

export const renderMenuIcon = (iconName, className) => {
    const iconMap = {
        FaCar,
        BsBuildingsFill,
        FaRoute,
        IoStatsChartSharp,
        FaTachographDigital,
        IoSettings,
        MdDevices,
        MdOutlineMailOutline,
        MdOutlineSensors,
        BiWorld,
        IoListSharp,
        TfiFiles,
        FaAddressCard,
        StatsVehicle: IconsMenu['StatsVehicle'],
        StatsDriverCheck: IconsMenu['StatsDriverCheck'],
        StatsDriverCheck2: IconsMenu['StatsDriverCheck2'],
        StatsSelection: IconsMenu['StatsSelection'],
        Equipments: IconsMenu['Equipments'],
        ConfigDrivers: IconsMenu['ConfigDrivers']
    };
    const IconComponent = iconMap[iconName];
    if (!IconComponent) return null
    return <IconComponent className={className} />;
}


export default function Menu({ children }) {
    const { t, i18n } = useTranslation('translation');
    document.title = i18n.t('widget.menu')
    const navigate = useNavigate();
    const location = useLocation();
    const colorSecondary = getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary');
    const { uiConfig, setUiConfig, clientRoles, costumer, user } = useContext(UserContext);
    const sideBar = uiConfig.sideBar === false ? false : true;
    let navbar = useContext(UserContext).uiConfig.navbar;
    const [expanded, setExpanded] = useState();
    const [searchQuery, setSearchQuery] = useState("")
    const menuRef = useRef(null);
    let modulesDefault = listMenu;

    const childrenItem = modulesDefault.filter(mD => mD.code === children)[0]
    if (children) {
        if (childrenItem && childrenItem.modules && childrenItem.modules.length > 0) {
            modulesDefault = childrenItem.modules;
        }
        else modulesDefault = [];
    }

    if (childrenItem && childrenItem.code === 'reports' && (isClient('geoTest', costumer) || isClient('develop', costumer)) && !modulesDefault.some(n => n.code === 'costumerReport')) {
        modulesDefault.push({ index: 390, icon: 'IoStatsChartSharp', url: '/reports/costumerReport', code: 'costumerReport', name: t('stats.costumerReport') + costumer.split(']')[1].trim() })
    }

    const [modules, setModules] = useState(modulesDefault)

    useEffect(() => {
        if (location.pathname) {
            setModules(modulesDefault);
        }
    }, [location.pathname])

    const clickHandler = (e) => {
        // setUiConfig({ ...uiConfig, navbar: [] })
        const item = navbar.filter(i => i.code === e.code)[0]
        if (!item) {
            const item = { code: e.code, icon: e.icon, index: e.index, url: e.url };
            if (e.name) item.name = e.name
            navbar.push(item)
        }
        else navbar = navbar.filter(i => i.code !== e.code)
        navbar = navbar.filter(i => !i.oneCostumer)
        navbar.sort((a, b) => a.index - b.index)
        setUiConfig({ ...uiConfig, navbar: navbar })
    }

    const Modul = ({ m }) => {
        const left = ['IoStatsChartSharp', 'FaTachographDigital','IoListSharp','FaAddressCard']
        const halfIcon = left.includes(m.icon) ? 'halfIconL' : 'halfIconR';
        return (
            <>
                <div className={`btn btn-light align-content-center menu-modul position-relative shadow mb-2 mb-sm-5 bg-body rounded mx-1 mx-lg-5 py-2 block col-12 col-xl-3 col-xxl-2 ${sideBar ? 'col-sm-8 col-md-5 col-lg-4' : 'col-12 col-sm-4 col-lg-3'}`}
                    onClick={() => { m.children && !expanded ? setExpanded(m) : navigate(m.url) }}>
                    <a className="fs-6 fw-bold text-center position-relative z-2 d-block mx-0 px-0 px-xl-3 mx-sm-4" >{m.name ? m.name : t('widget.' + m.code)}</a>
                    <Button variant="light" className="position-absolute top-0 end-0 me-2 mt-2 cursorpointer" onClick={(e) => { e.stopPropagation(); clickHandler(m) }} >
                        {navbar.some(i => i.code === m.code) ? <IoIosStar color={colorSecondary} className="fs-5" /> : <IoIosStarOutline color={colorSecondary} className="fs-5" />}
                    </Button>
                    <div className={`position-absolute bottom-0 start-0 me-2 mb-2 ${halfIcon}`} >{renderMenuIcon(m.icon, 'fs-1')}</div>
                </div >
            </>
        )
    }


    useEffect(() => {
        if (searchQuery.length > 1) {
            const lowercasedQuery = searchQuery.toLowerCase();

            let searchModules = [];
            modulesDefault.forEach((m) => {
                const name = t('widget.' + m.code)
                if ((normalizeText(name).includes(lowercasedQuery) || name.toLowerCase().includes(lowercasedQuery)) && !searchModules.some(n => n.code === m.code)) searchModules.push(m)
                m.children?.forEach((child) => {
                    const childName = t('widget.' + child.code)
                    if ((normalizeText(childName).includes(lowercasedQuery) || childName.toLowerCase().includes(lowercasedQuery)) && !searchModules.some(n => n.code === child.code)) searchModules.push(child)
                });
                m.modules?.forEach((child) => {
                    const childName = t('widget.' + child.code)
                    if ((normalizeText(childName).includes(lowercasedQuery) || childName.toLowerCase().includes(lowercasedQuery)) && !searchModules.some(n => n.code === child.code)) searchModules.push(child)
                });
                // const keywords = t('widget.' + m.code + '_search');
            })
            setModules(searchModules)
        }
        else setModules(modulesDefault)
    }, [searchQuery])

    return (
        <div className={`m-3 menu ${children ? 'children' : ''}`}>

            <Transition in={expanded && !children} timeout={300} unmountOnExit nodeRef={menuRef}>
                {state => expanded && !children ? (
                    <div ref={menuRef} className={`menu-animation menu-animationR-${state}`}>
                        <div onClick={() => { navigate(expanded.url) }} className="cursorpointer text-center mb-2 mb-sm-5 h3">
                            <span className='halfIcon'>{renderMenuIcon(expanded.icon, 'mb-1')}</span>
                            <a>{expanded.name ? expanded.name : t('widget.' + expanded.code)}</a>
                            {navbar.some(i => i.code === expanded.code) ? <IoIosStar className="cursorpointer fs-4 ms-2 mb-2 me-3" onClick={(e) => { e.stopPropagation(); clickHandler(expanded) }} /> : <IoIosStarOutline className="cursorpointer ms-2 mb-2 fs-4 me-3" onClick={(e) => { e.stopPropagation(); clickHandler(expanded) }} />}
                        </div>
                        <div className="mx-1 mx-lg-3 bg-white d-flex flex-wrap align-items-center justify-content-center">
                            <Button variant="light" className={`menu-modul position-relative shadow mb-2 mb-sm-5 bg-body rounded mx-3 mx-lg-5 py-2 block col-12 col-xl-3 col-xxl-2 ${sideBar ? 'col-sm-8 col-md-5 col-lg-4' : 'col-12 col-sm-4 col-lg-3'}`} onClick={() => setExpanded(false)}>
                                <IoIosArrowBack className="fs-0" />
                            </Button >
                            <Modul m={expanded} key={expanded.code} />
                        </div>
                        <div className="h4 text-center mt-4 mb-3 mb-sm-0 mt-sm-0">{t('widget.menuContainsWidget')}</div>
                        <hr className="dropdown-divider" role="separator"></hr>
                        <div className="mx-1 mx-lg-3 mt-2 mt-sm-5 bg-white d-flex flex-wrap align-items-center justify-content-center">
                            {expanded.children.map(m => {
                                if (checkClientRoles(clientRoles, checkNavbarItem(m.code, user))) return <Modul m={m} key={m.code} />
                            })}
                        </div>
                    </div>) : null}
            </Transition>

            <Transition in={!expanded && !children} timeout={300} unmountOnExit nodeRef={menuRef}>
                {state => !expanded && !children ? (
                    <div ref={menuRef} className={`menu-animation menu-animationL-${state}`}>
                        <div className="text-center mb-2 mb-sm-3 h3 pe-none">{t('widget.menu')}</div>
                        <div className="m-auto col-8 col-lg-3 mb-3">
                            <InputGroup >
                                <IoIosSearch style={{ zIndex: 6 }} className="fs-5 me-n4 mt-2 text-secondary" />
                                <Form.Control
                                    className="rounded-4 ps-4"
                                    placeholder={t('search')}
                                    type="search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                        <div className="mx-1 mx-lg-3 bg-white d-flex flex-wrap align-items-center justify-content-center">
                            {modules.length > 0 ? modules.map(m => {
                                if (checkClientRoles(clientRoles, checkNavbarItem(m.code, user))) return <Modul m={m} key={m.code} />
                            }) : (<div className="d-flex align-items-center h1 fw-bold text-secondary mt-5 pt-3">{t('notFound')}</div>)}
                        </div>
                    </div>) : null}
            </Transition>

            <Transition in={children ? true : false} timeout={300} unmountOnExit nodeRef={menuRef}>
                {state => children ? (
                    <div ref={menuRef} className={`menu-animation menu-animationL-${state}`}>
                        <div className="text-center mb-2 mb-sm-3 h3">
                            <span className='halfIcon'>{renderMenuIcon(childrenItem.icon, 'mb-1')}</span>
                            <span className="pe-none">{childrenItem.name ? childrenItem.name : t('widget.' + childrenItem.code)}</span>
                            {navbar.some(i => i.code === childrenItem.code) ? <IoIosStar className="cursorpointer fs-4 ms-2 mb-2 me-3" onClick={() => clickHandler(childrenItem)} /> : <IoIosStarOutline className="cursorpointer ms-2 mb-2 fs-4 me-3" onClick={() => clickHandler(childrenItem)} />}
                        </div>
                        <div className="m-auto col-8 col-lg-3 mb-3">
                            <InputGroup >
                                <IoIosSearch style={{ zIndex: 6 }} className="fs-5 me-n4 mt-2 text-secondary" />
                                <Form.Control
                                    className="rounded-4 ps-4"
                                    placeholder={t('search')}
                                    type="search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                        <div className="mx-1 mx-lg-3 bg-white d-flex flex-wrap align-items-center justify-content-center">
                            {searchQuery.length < 1 ? <Button variant="light" className={`menu-modul position-relative shadow mb-2 mb-sm-5 bg-body rounded mx-3 mx-lg-5 py-2 block col-12 col-xl-3 col-xxl-2 ${sideBar ? 'col-sm-8 col-md-5 col-lg-4' : 'col-12 col-sm-4 col-lg-3'}`} onClick={() => navigate(-1)}>
                                <IoIosArrowBack className="fs-0" />
                            </Button > : <></>}
                            {modules.length > 0 ? modules.map(m => {
                                if (checkClientRoles(clientRoles, checkNavbarItem(m.code, user))) return <Modul m={m} key={m.code} />
                            }) : (<div className="d-flex align-items-center h1 fw-bold text-secondary mt-5 pt-3">{t('notFound')}</div>)}
                        </div>
                    </div>
                ) : null}
            </Transition>
        </div>
    )
}