import { parseISO, format, isWeekend } from 'date-fns'
import { useState, useEffect } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap"

import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io'
import { FiSettings } from 'react-icons/fi';
import Modal from 'react-bootstrap/Modal';
import { PiUsers } from "react-icons/pi";
import i18n from '../../dictionary/i18n';
import { CarsGroup } from "../../images";
import { FaGasPump } from "react-icons/fa6";


// import colors from 'react-multi-date-picker/plugins/colors';

export const getLabel = ((name, drivFocus, purposeArray) => {
    if (name === 'time') return i18n.t('stats.start') + ';' + i18n.t('stats.end');
    if (name === 'text') return i18n.t('stats.from') + ';' + i18n.t('stats.to');
    if (name === 'driver_purpose' && !drivFocus) return purposeArray ? i18n.t('stats.driver') : i18n.t('stats.purpose') + ';' + i18n.t('stats.driver');
    if (name === 'driver_purpose' && drivFocus) return purposeArray ? i18n.t('stats.plate') : i18n.t('stats.purpose') + ';' + i18n.t('stats.plate');
    if (name === 'purpose_array') return i18n.t('stats.center') + ';' + i18n.t('stats.order');
    if (name === 'fuel') return i18n.t('stats.fuel') + ';' + i18n.t('stats.avaraga_fuel_symbol');
    if (name === 'duration') return i18n.t('stats.time') + ';' + i18n.t('stats.state');
    if (name === 'maxAvrg') return i18n.t('stats.avrgSpeed') + ';' + i18n.t('stats.maxSpeedShort');
    return i18n.t('stats.' + name);
})

export const getCallRender = ((name, setOpenRow, purposeArray, drivFocus) => {
    const twoLineItem = ['time', 'text', 'fuel', 'duration', 'maxAvrg', 'tankTanklast'];
    if (name === 'stamp') return ({ value, data }) => date(value, data, setOpenRow);
    if (twoLineItem.includes(name)) return twoLine;
    if (name === 'driver_purpose') return (({ value, data, tableManager }) => driverPurposeLine(value, data, purposeArray, drivFocus, tableManager));
    if (name === 'purpose_array') return driverPurposeLineArr;
    if (name === 'distance' || name === 'type') return oneLineOrTwoLine;
})

export const getHeaderCellRenderer = ((name,) => {
    const twoLineItem = ['time', 'text', 'fuel', 'driver_purpose', 'purpose_array', 'duration', 'maxAvrg'];
    if (twoLineItem.includes(name)) return headerTwoLine;
    if (name === 'checkbox') return CheckboxRow
})

export function gC(id) {
    let sc = JSON.parse(localStorage.getItem("rides-columns"))
    if (sc) {
        let find;
        sc.forEach((cc) => { if (cc.id === id) { find = { width: cc.width, visible: cc.visible } } })
        return find
    }
    else return null
};

export const headerTwoLine = ({ column }) => {
    const [line1, line2] = column.label.split(';');
    return (
        <div className='cursorgrap'>
            <span>{line1}</span><br />
            <span>{line2}</span>
        </div>
    )
}

export const twoLine = ({ value, data }) => {
    if (value) {
        //if value are undefined  are object from search function
        if (value.length !== undefined) {
            let [text1, text2] = value.split(';');
            if (text1 === i18n.t('stats.fueling')) {
                text1 = <span><FaGasPump className='fs-6 mb-1 me-2' />{text1}</span>
            }
            return (
                <div className='rgt-cell-inner rgt-cell-component' title={text1 + '\n' + text2}>
                    <div className='rgt-text-truncate'>
                        {text1}
                    </div>
                    <div className='rgt-text-truncate'>
                        {text2}
                    </div>
                </div>
            )
        }
        else {
            let aText1 = data.text.split(';')[0]
            if (aText1 === i18n.t('stats.fueling')) {
                aText1 = <span><FaGasPump className='fs-6 mb-1 me-2' />{aText1}</span>
            }
            return (
                <div className='rgt-cell-inner rgt-cell-component'>
                    <div className='rgt-text-truncate'>
                        {aText1}
                    </div>
                    <div className='rgt-text-truncate'>
                        {data.text.split(';')[1]}
                    </div>
                </div>
            )
        }
    }
}

export const date = (value, data, setOpenRow) => {
    if (value === '' && !data.stamp) return (<></>)
    const [stamp1, stamp2, day, countRide, open] = value.split(';');
    const date1 = parseISO(stamp1);
    const date2 = stamp2 ? parseISO(stamp2) : null;

    const openRowLocal = () => {
        // console.log(data);
        if (countRide > 1) setOpenRow(data)
    }
    const getIcon = (open) => {
        if (open === '') return <></>
        if (open === 'false') return <IoIosArrowDropdown className='fs-5 cursorpointer' />
        if (open === 'true') return <IoIosArrowDropup className='fs-5 cursorpointer' />
    }

    // console.log(format(date1, 'dd.MM.yyyy'), format(date2, 'dd.MM.yyyy'), date2);
    if (date2 && format(date1, 'dd.MM.yyyy') !== format(date2, 'dd.MM.yyyy')) {
        return (
            <div className='rgt-cell-inner rgt-cell-component' onClick={openRowLocal} style={countRide > 1 ? { cursor: 'pointer' } : {}}>
                <div className='rgt-text-truncate'>
                    {format(date1, 'dd.MM.yyyy')}
                    {countRide > 1 ? getIcon(open) : ''}
                </div>
                <div className='rgt-text-truncate'>
                    {format(date2, 'dd.MM.yyyy')}
                </div>
            </div>
        )
    }
    else {
        return (
            <div className={`rgt-cell-inner rgt-cell-component ${isWeekend(date1) ? 'weekend' : ''}`} onClick={openRowLocal} style={countRide > 1 ? { cursor: 'pointer' } : {}}>
                <div className='rgt-text-truncate'>
                    {format(date1, 'dd.MM.yyyy') + ' ' + day + ' '}
                    {countRide > 1 ? getIcon(open) : ''}
                </div>

            </div>
        )
    }
}

export const driverPurposeLine = (value, data, purposeArray, drivFocus, table) => {
    if (value) {
        if (value.length !== undefined) {
            const [text1, text2] = value.split(';');
            const data = text2 ? text2.split(',') : '';
            if (data.length < 2) {
                return (
                    <div className='rgt-cell-inner rgt-cell-component' title={text1 + '\n' + (text2 ? text2 : '')}>
                        {!purposeArray ?
                            <div className='rgt-text-truncate' style={{ minHeight: '24px' }}>
                                {text1}
                            </div> : <></>}
                        <div className='rgt-text-truncate' style={{ minHeight: '24px' }}>
                            {text2}
                        </div>
                    </div>
                )
            }
            else {
                const widthColumns = parseInt(table.columnsApi.columns.find(c => c.field === 'driver_purpose').width);
                const context = document.createElement("canvas").getContext('2d');
                context.font = '0.9rem sans-serif';
                const width = context.measureText(`${data.join(', ')}`).width * 1.1;
                const groupDrivers = widthColumns < width
                return (
                    <div className={`rgt-cell-inner rgt-cell-component`}>
                        {!purposeArray ?
                            <div className='rgt-text-truncate' style={{ minHeight: '24px' }}>
                                {text1}
                            </div> : <></>}
                        <div className='rgt-text-truncate cursorpointer' style={{ minHeight: '24px' }}>
                            {groupDrivers ? (
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 400, hide: 400 }}
                                    overlay={(props) => (
                                        <Tooltip {...props}>
                                            {data.map(d => { return <div key={d}>{d}</div> })}
                                        </Tooltip>
                                    )}
                                >
                                    <div>
                                        {!drivFocus ? <span><PiUsers className='fs-5 mb-1' /></span> : <span><CarsGroup height={18} className='mb-1' /></span>}
                                        <span className='ms-2'>{data.length}x</span>
                                    </div>
                                </OverlayTrigger>
                            ) : data.join(', ')}
                        </div>
                    </div>
                )
            }
        }
        else {
            return (
                <div className='rgt-cell-inner rgt-cell-component' title={(!purposeArray ? data.driver_purpose.split(';')[0] : '') + '\n' + (data.driver_purpose.split(';')[1] ? data.driver_purpose.split(';')[1] : '')}>
                    {!purposeArray ? <div className='rgt-text-truncate' style={{ minHeight: '24px' }}>
                        {data.driver_purpose.split(';')[0]}
                    </div> : <></>}
                    <div className='rgt-text-truncate' style={{ minHeight: '24px' }}>
                        {data.driver_purpose.split(';')[1]}
                    </div>
                </div>
            )
        }
    }
}

export const driverPurposeLineArr = ({ value, data }) => {
    if (value) {
        //if value are undefined  are object from search function
        if (value.length !== undefined) {
            const [text1, text2] = value.split(';');
            return (
                <div className='rgt-cell-inner rgt-cell-component' title={text1 + '\n' + (text2 ? text2 : '')}>
                    <div className='rgt-text-truncate' style={{ minHeight: '24px' }}>
                        {text1}
                    </div>
                    <div className='rgt-text-truncate' style={{ minHeight: '24px' }}>
                        {text2}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='rgt-cell-inner rgt-cell-component' title={data.driver_purpose.split(';')[0] + '\n' + (data.driver_purpose.split(';')[1] ? data.driver_purpose.split(';')[1] : '')}>
                    <div className='rgt-text-truncate' style={{ minHeight: '24px' }}>
                        {data.purpose_array.split(';')[0]}
                    </div>
                    <div className='rgt-text-truncate' style={{ minHeight: '24px' }}>
                        {data.purpose_array.split(';')[1]}
                    </div>
                </div>
            )
        }
    }
}

export const oneLineOrTwoLine = ({ value, data }) => {
    if (value) {
        if (value.includes(';')) {
            const [dist, distp] = value.split(';');
            return (
                <div className='rgt-cell-inner rgt-text-truncate' title={dist + '\n' + distp}>
                    <div className='rgt-text-truncate'>
                        {dist}
                    </div>
                    <div className='rgt-text-truncate'>
                        {distp}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='rgt-cell-inner rgt-text-truncate' title={value}>
                    {value}
                </div>
            )
        }
    }
}

export const searchNFD = ({ value, searchText }) => {
    //normalize NFD and replace - remove accents like Řecko to recko
    const aValue = value.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const aSearchText = searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if (aValue.includes(aSearchText)) return value
}

export const CheckboxRow = ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => {

    const masterDelete = (a, b) => {
        if (a || b) tableManager.rowSelectionApi.selectAll.ref.current = [true];
    }

    return (
        // <input className='rgt-clickable undefined' type="checkbox" onChange={onChange} checked={checked} disabled={disabled} onClick={() => { masterDelete(checked, indeterminate) }} />
        <div className='rgt-clickable'>
            <input
                type="checkbox"
                onChange={onChange}
                checked={checked}
                disabled={disabled}
                onClick={() => { masterDelete(checked, indeterminate) }}
            />
            {/* <span className="checkbox-indeterminate cursorpointer" style={{ display: indeterminate ? 'block' : 'none', position: 'absolute', zIndex: '900', top: '2px', fontSize: '25px', paddingLeft: '1px' }}>-</span> */}
        </div>
    )
}

export const columnsRidesView = ((setOpenRow) => {
    return [
        {
            id: 0,
            index: 0,
            field: 'stamp',
            cellRenderer: ({ value, data }) => date(value, data, setOpenRow),
            sortable: true,
            visible: true,
            width: '155px',
            resizable: false,
            searchable: false,
        },
        {
            id: 1,
            index: 1,
            field: 'time',
            cellRenderer: twoLine,
            headerCellRenderer: headerTwoLine,
            sortable: false,
            width: '95px',
            visible: true,
            resizable: false,
            searchable: false,
        },
        {
            id: 2,
            index: 2,
            field: 'text',
            width: 'auto',
            visible: true,
            cellRenderer: twoLine,
            headerCellRenderer: headerTwoLine,
            sortable: false,
            search: searchNFD
        },
        {
            id: 3,
            index: 3,
            field: 'driver_purpose',
            width: 'auto',
            visible: true,
            cellRenderer: driverPurposeLine,
            headerCellRenderer: headerTwoLine,
            sortable: false,
            searchable: true,

        },
        {
            id: 4,
            index: 4,
            field: 'purpose_array',
            width: 'auto',
            visible: false,
            cellRenderer: driverPurposeLineArr,
            headerCellRenderer: headerTwoLine,
            sortable: false,
            searchable: true,
        },
        {
            id: 5,
            index: 5,
            field: 'duration',
            width: 'auto',
            visible: true,
            sortable: false,
            searchable: false,
            cellRenderer: twoLine,
            headerCellRenderer: headerTwoLine,
            className: 'text-end'

        },
        {
            id: 6,
            index: 6,
            field: 'distance',
            cellRenderer: oneLineOrTwoLine,
            width: 'auto',
            visible: true,
            sortable: false,
            searchable: false,
            className: 'text-end'
        },
        {
            id: 8,
            index: 7,
            field: 'type',
            cellRenderer: oneLineOrTwoLine,
            width: 'auto',
            visible: true,
            sortable: false,
            searchable: false,
        },
        {
            id: 9,
            index: 8,
            field: 'maxAvrg',
            cellRenderer: twoLine,
            headerCellRenderer: headerTwoLine,
            width: 'auto',
            visible: true,
            sortable: false,
            searchable: false,
            className: 'text-end'
        },
        {
            id: 10,
            index: 9,
            field: 'fueling',
            cellRenderer: oneLineOrTwoLine,
            width: 'auto',
            visible: false,
            sortable: false,
            searchable: false,
            className: 'text-end'
        },
        {
            id: 11,
            index: 10,
            field: 'tankTanklast',
            cellRenderer: oneLineOrTwoLine,
            headerCellRenderer: headerTwoLine,
            width: 'auto',
            visible: true,
            sortable: false,
            searchable: false,
            className: 'text-end'
        },


        {
            id: 7,
            index: 11,
            field: 'fuel',
            width: 'auto',
            visible: true,
            cellRenderer: twoLine,
            headerCellRenderer: headerTwoLine,
            sortable: false,
            searchable: false,
            className: 'text-end'
        },
        {
            index: 12,
            id: 'checkbox',
            field: 'checkbox',
            pinned: false,
            visible: true,
            width: 'auto',
            sortable: false,
            searchable: false,
            headerCellRenderer: CheckboxRow,
        },

    ]
})