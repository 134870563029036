export const layoutOverviewDefault = {
    lg: [
        { w: 8, h: 10, x: 0, y: 0, i: "MapView", minH: 5, minW: 3 },
        { w: 12, h: 8, x: 0, y: 2, i: "DevicesList", minH: 3, minW: 3 },
        { w: 4, h: 10, x: 8, y: 0, i: "DeviceDetail", minH: 5, minW: 3 },
    ],
    md: [
        { w: 7, h: 10, x: 0, y: 0, i: "MapView", minH: 4, minW: 3 },
        { w: 10, h: 8, x: 0, y: 2, i: "DevicesList", minH: 3, minW: 3 },
        { w: 3, h: 10, x: 7, y: 0, i: "DeviceDetail", minH: 4, minW: 3 },
    ],
    sm: [
        { w: 3, h: 9, x: 0, y: 0, i: "MapView", minH: 4, minW: 2 },
        { w: 6, h: 7, x: 0, y: 2, i: "DevicesList", minH: 3, minW: 3 },
        { w: 3, h: 9, x: 3, y: 0, i: "DeviceDetail", minH: 4, minW: 2 },
    ],
    xs: [
        { w: 2, h: 8, x: 0, y: 0, i: "MapView", minH: 4, minW: 2 },
        { w: 4, h: 6, x: 0, y: 2, i: "DevicesList", minH: 3, minW: 3 },
        { w: 2, h: 8, x: 3, y: 0, i: "DeviceDetail", minH: 4, minW: 2 },
    ],
};

export const layoutNew = (name) => {
    if (name === 'MapView') return { w: 8, h: 6, x: 0, y: 0, i: "MapView", minH: 4, minW: 2 }
    if (name === 'DevicesList') return { w: 12, h: 6, x: 0, y: 0, i: "DevicesList", minH: 3, minW: 3 }
    if (name === 'DeviceDetail') return { w: 4, h: 6, x: 0, y: 0, i: "DeviceDetail", minH: 4, minW: 2 }
}


