
export const listMenu = [
    {
        index: 100, default: true, icon: (process.env.REACT_APP_BRAND === 'alfakomtemp' ? 'BsBuildingsFill' : 'FaCar'), url: '/overview', code: 'overview', children: [
            { index: 110, icon: 'BiWorld', url: '/overview/MapView', code: 'map' },
            { index: 120, icon: 'IoListSharp', url: '/overview/DevicesList', code: 'devicesList' },
        ]
    },
    { index: 200, default: true, icon: 'FaRoute', url: '/rides', code: 'rides' },
    {
        index: 300, default: true, icon: 'IoStatsChartSharp', url: '/reports', code: 'reports', modules: [
            { index: 310, icon: 'StatsVehicle', url: '/reports/statsVehicles', code: 'statsVehicles' },
            { index: 320, icon: 'StatsSelection', url: '/reports/statsSelection', code: 'statsSelection' },
            { index: 330, icon: 'StatsDriverCheck2', url: '/reports/driverCheck2', code: 'driverCheck2' },
            { index: 340, icon: 'StatsDriverCheck', url: '/reports/driverCheck', code: 'driverCheck' },
            { index: 350, icon: 'MdOutlineSensors', url: '/reports/sensorsReport', code: 'sensorsReport' }
            // 390 costumerReport 
        ]
    },
    {
        index: 400, icon: 'FaTachographDigital', url: '/tacho', code: 'tacho', children: [
            { index: 410, icon: 'FaTachographDigital', url: '/tacho/DDDViewVehicle', code: 'DDDViewVehicle' },
            { index: 420, icon: 'FaAddressCard', url: '/tacho/DDDViewDrivers', code: 'DDDViewDrivers' },
            { index: 430, icon: 'TfiFiles', url: '/tacho/DDDViewListFiles', code: 'DDDViewListFiles' }
        ]
    },
    {
        index: 500, default: true, icon: 'IoSettings', url: '/config', code: 'config', modules: [
            { index: 510, icon: (process.env.REACT_APP_BRAND === 'alfakomtemp' ? 'MdDevices' : 'Equipments'), url: '/config/equipments', code: 'equipments' },
            // { index: 520, icon: 'ConfigDrivers', url: '/config/drivers', code: 'configDrivers' },
            { index: 530, icon: 'MdOutlineMailOutline', url: '/config/fences', code: 'fences' }
        ]
    },
];
