import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';

import { UserContext } from "./userDetails";
import { FiSettings } from 'react-icons/fi';
import Modal from 'react-bootstrap/Modal';
import { columnsDriverCheck, columnsDriverCheck2, columnsSensorsReport, columnsStatsVehicles, getLabel as getLabelStats, getCallRender as getCallRenderStats, columnsStatsSelection } from "./Stats/statsColumns";
import { columnsRidesView, getLabel as getLabelRides, getCallRender as getCallRenderRides, getHeaderCellRenderer as getHeaderCellRendererRides } from '../components/RidesView/ridesColumns'
// import { ridesColumns, updateColumnsLayout, getLabel, columnsRidesView, getCallRender, getHeaderCellRenderer} from "./ridesColumns";
import { checkRole } from '../components/RidesView/RidesView'

export const updateColumnsLayout = (t, uiConfig, setUiConfig, source, hideColumns) => {

    if (hideColumns) {
        setTimeout(() => {
            const splitColumns = source === 'sensorsReport' ? t.columnsApi.columns.concat(hideColumns) : hideColumns.concat(t.columnsApi.columns.filter(c => !hideColumns.some(hC => hC.id === c.id)))
            const nc = splitColumns.map((cc) => {
                return ({ id: cc.id, index: cc.index, field: cc.field, width: cc.width, visible: cc.visible, sortable: cc.sortable, searchable: cc.searchable, pinned: cc.pinned })
            }).sort((a, b) => a.index - b.index);
            if (source === 'sensorsReport') setUiConfig({ ...uiConfig, columnsSensorsReport: nc })
            else if (source === 'ridesView') setUiConfig({ ...uiConfig, columnsRidesView: nc })
        }, 200);
    }
    else {
        setTimeout(() => {
            const nc = t.columnsApi.columns.map((cc) => {
                return ({ id: cc.id, index: cc.index, field: cc.field, width: cc.width, visible: cc.visible, sortable: cc.sortable, searchable: cc.searchable, pinned: cc.pinned })
            })
            if (source === 'driverCheck') setUiConfig({ ...uiConfig, columnsDriverCheck: nc })
            else if (source === 'driverCheck2') setUiConfig({ ...uiConfig, columnsDriverCheck2: nc })
            else if (source === 'statsVehicles') setUiConfig({ ...uiConfig, columnsStatsVehicles: nc })
            else if (source === 'statsSelection') setUiConfig({ ...uiConfig, columnsStatsSelection: nc })
        }, 200);
    }
}

const getDefaultColumns = (type, setRowOpen) => {
    if (type === 'driverCheck') return columnsDriverCheck;
    else if (type === 'driverCheck2') return columnsDriverCheck2;
    else if (type === 'sensorsReport') return columnsSensorsReport;
    else if (type === 'statsVehicles') return columnsStatsVehicles;
    else if (type === 'ridesView') return columnsRidesView(setRowOpen);
    else if (type === 'statsSelection') return columnsStatsSelection;
}

export function ColumnManager(tableManager, btncolumnman, setBtncolumnman, source, aux, hideColumns, devFocus, setRowOpen, hideColumnsRun, setEdit, edit) {
    const { t } = useTranslation('translation');
    const uiConfig = useContext(UserContext).uiConfig;
    const { setUiConfig } = useContext(UserContext);
    const clientRoles = useContext(UserContext).clientRoles;

    const { columnsVisibilityApi, columnsApi, searchApi } = tableManager;
    const { toggleColumnVisibility } = columnsVisibilityApi;
    const { columns, setColumns } = columnsApi;
    const { setSearchText, searchText } = searchApi;
    useEffect(() => {
        if (!searchText) setSearchText(sessionStorage.getItem("rides-search") ? sessionStorage.getItem("rides-search") : '')
    }, []);

    const handleEditRides = () => {
        setEdit(!edit);
    };

    const columnsOption = (idx, columns, source) => {
        if (source === 'ridesView') {
            return idx !== 0 && idx !== 1 && idx !== columns.length - 1
        }
        else return true
    };

    useEffect(() => {
        if (source === 'sensorsReport') {
            const splitColumns = columns.concat(hideColumns);
            // || data.field === 'address' 
            const visibleColumns = splitColumns.filter(data => aux.sensors.includes(data.field) || data.field === 'stamp' || data.field === 'gps')
            setColumns(visibleColumns)
        }
        if (source === 'ridesView') {
            if (!hideColumnsRun.current) {
                setColumns(columns.map(i => {
                    if (hideColumns.some(hC => hC.id === i.id)) return { ...i, visible: false }
                    else return i
                }))
                hideColumnsRun.current = true;
            }
        }
        if (source === 'statsSelection') {
            const onlyForGroupDay = ['day', 'start', 'end', 'durationTotal', 'durationPark']
            setColumns(columns.map(i => {
                if (onlyForGroupDay.includes(i.field)) return { ...i, visible: aux }
                else return i
            }))
        }
    }, [])

    const getColumns = (c) => {
        const statsView = ['driverCheck', 'driverCheck2', 'sensorsReport','statsVehicles', 'statsSelection'];
        if (statsView.includes(source)) {
            return c.map(data => {
                let res = {
                    ...data,
                    visible: c[data.index].visible,
                    width: c[data.index].width,
                    label: getLabelStats(data.field, (devFocus ? devFocus : null))
                }
                const callrender = getCallRenderStats(data.field);
                if (callrender) res = { ...res, cellRenderer: callrender }
                return res
            })
        }
        if (source === 'ridesView') {
            return c.map(data => {
                let res = {
                    ...data,
                    visible: c[data.index].visible,
                    width: c[data.index].width,
                    label: getLabelRides(data.field, false)
                    // headerCellRenderer: headerWithTitle
                }
                const textEnd = ['fuel', 'tankTanklast', 'fueling', 'maxAvrg', 'distance', 'duration']
                if (textEnd.includes(data.field)) res.className = 'text-end';
                const callrender = getCallRenderRides(data.field, setRowOpen);
                const headerCellRenderer = getHeaderCellRendererRides(data.field);
                // //   const getsortf = getSortF(data.field);
                if (callrender) res = { ...res, cellRenderer: callrender }
                if (headerCellRenderer) res = { ...res, headerCellRenderer: headerCellRenderer }
                // //   if (getsortf) res = { ...res, sort: getsortf }
                return res
            })
        }
    }
    return (
        <div className='rgt-columns-manager-wrapper'>
            <button style={{ backgrounColor: 'unset' }} className='rgt-columns-manager-button' onClick={() => { setBtncolumnman(!btncolumnman) }}><FiSettings title={t('widget.managerColumns')} /></button>
            <Modal
                centered
                animation={false}
                show={btncolumnman}
                className="d-flex justify-content-center"
                fullscreen='sm-down'
                onHide={() => {
                    setBtncolumnman(false);
                    setTimeout(() => {
                        updateColumnsLayout(tableManager, uiConfig, setUiConfig, source, hideColumns);
                    }, 500)
                }}>
                <Modal.Header closeButton >
                    <span className="fw-bold me-5">{t('widget.managerView')}</span>
                </Modal.Header>
                <Modal.Body>
                    <div className='rgt-columns-manager-popover-body' style={{ maxHeight: '80vh' }}>
                        {source === 'ridesView' && checkRole(clientRoles, 'edit_events', devFocus) ? <><div className='rgt-clickable rgt-columns-manager-popover-row mb-3' >
                            <label className='rgt-clickable rgt-columns-manager-popover-row pe-6'>
                                {t('widget.editRides')}
                                <input
                                    type="checkbox"
                                    checked={edit}
                                    onChange={handleEditRides}
                                />
                            </label>

                        </div>
                            <span className="fw-bold me-5 mb-1">{t('widget.managerColumns')}</span>
                        </>
                            : ''}

                        {columns.map((column, idx) => (
                            columnsOption(idx, columns, source) ? (
                                <div className='rgt-clickable rgt-columns-manager-popover-row' key={idx} >
                                    <label className='rgt-clickable rgt-columns-manager-popover-row pe-6' htmlFor={`checkbox-${idx}`} style={{ flex: 1, cursor: 'pointer' }}>
                                        {column.label.includes(';') ? column.label.replace(';', ', ') : column.label}
                                    </label>
                                    <input
                                        className='rgt-clickable'
                                        id={`checkbox-${idx}`}
                                        type="checkbox"
                                        onChange={(e) => toggleColumnVisibility(column.id)}
                                        checked={column.visible !== false}
                                    />
                                </div>) : ''
                        ))}
                        <p className='text-center mt-2 mb-0 ' style={{ fontSize: '14px' }} role='button'
                            onClick={() => {
                                setColumns(getColumns(getDefaultColumns(source, setRowOpen)))
                            }}>{t('widget.resetDefault')}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

