import { parseISO, format, formatDistanceStrict, differenceInDays, isToday } from 'date-fns'
import { useState, useEffect, useContext } from "react";
import i18n from '../../dictionary/i18n';

import { BsCheck2Circle } from 'react-icons/bs'
import { FiSettings, FiUsers, FiDownload } from 'react-icons/fi';
import { PiWarning } from 'react-icons/pi';
import { LiaDigitalTachographSolid } from 'react-icons/lia'

import { WsSend } from "../../scripts/ws";

import { UserContext } from "../userDetails";

// import colors from 'react-multi-date-picker/plugins/colors';

const getFilesDevice = (device) => {
    WsSend({
        method: 'modify',
        id: 9,
        params: {
            device: device,
            driver: null
        }
    });
}

const getFilesDrivers = (driver) => {
    WsSend({
        method: 'modify',
        id: 9,
        params: {
            device: null,
            driver: driver
        }
    });
}

export const stampDownload = ({ tableManager, value, data }) => {
    if (data.suspend) {
        const rowsToHighlight = tableManager.refs.tableRef.current.querySelectorAll(`.${data.type} [data-row-index] .vehicleSuspend`)
        rowsToHighlight.forEach(row => {
            const rowIndex = row.closest(`.${data.type} [data-row-index]`).getAttribute('data-row-index');
            const rowsWithSameIndex = document.querySelectorAll(`.${data.type} [data-row-index="${rowIndex}"]`);
            rowsWithSameIndex.forEach(row => {
                row.classList.add('suspend-row');
            });
        });
        return (
            <div className='rgt-cell-inner rgt-cell-component'>
                <span className='rgt-text-truncate vehicleSuspend'>
                    {i18n.t('devices.suspend')}
                </span>
            </div>
        )
    }
    else if (value === undefined || value.length === 0 || typeof value !== 'string') return (<></>)
    let icon;
    const stamp = parseISO(value);
    const passDay = differenceInDays(new Date(), stamp);
    if (data.driver) {
        if (passDay < 27) icon = <BsCheck2Circle className='me-1 fs-5 text-success' />;
        else icon = <PiWarning className='me-1 fs-5 text-danger' />;
    }
    else {
        if (passDay < 89) icon = <BsCheck2Circle className='me-1 fs-5 text-success' />;
        else icon = <PiWarning className='me-1 fs-5 text-danger' />;
    }
    return (
        <div className='rgt-cell-inner rgt-cell-component'>
            <span className='rgt-text-truncate fs-7' title={format(stamp, 'dd.MM.yyyy HH:mm')}>
                {icon}
                {formatDistanceStrict(stamp, new Date(), { addSuffix: true, unit: isToday(stamp) ? undefined : 'day' })}
            </span>
        </div>
    )
}

export const stamp = ({ value, data }) => {
    if (value === undefined || value.length === 0 || typeof value !== 'string') return (<></>)
    const stamp = parseISO(value);
    return (
        <div className='rgt-cell-inner rgt-cell-component'>
            <span className='rgt-text-truncate fs-7' title={format(stamp, 'dd.MM.yyyy HH:mm')}>
                {formatDistanceStrict(stamp, new Date(), { addSuffix: true, unit: isToday(stamp) ? undefined : 'day' })}
            </span>
        </div>
    )
}

export const showFilesVehicle = (value, data, setFileList) => {
    if (!value) return (<></>)
    return (
        <div className='rgt-cell-inner rgt-cell-component rgt-cell-linkHover'
            title={i18n.t('ddd.tachographFilesTitle')}
            onClick={() => {
                getFilesDevice(data.id)
                setFileList({ param: 'vehicle', id: data.id })
            }}
        >
            <LiaDigitalTachographSolid className='fs-4' /> {value}
        </div>
    )
}

export const showFilesDrivers = (value, data, setFileList) => {
    if (!value) return (<></>)
    return (
        <div className='rgt-cell-inner rgt-cell-component rgt-cell-linkHover'
            title={i18n.t('ddd.driversFilesTitle')}
            onClick={() => {
                getFilesDevice(data.id)
                setFileList({ param: 'driver', id: data.id })
            }}
        >
            <FiUsers className='fs-6 mb-1' /> {data.countFilesDrivers}
        </div>
    )
}

export const showFiles = (value, data, setFileList) => {
    if (!value) return (<></>)
    return (
        <div className='rgt-cell-inner rgt-cell-component rgt-cell-linkHover'
            title={i18n.t('ddd.driversFilesTitle')}
            onClick={() => {
                getFilesDrivers(data.id)
                setFileList({ param: 'driver', id: data.id })
            }}
        >
            <FiUsers className='fs-6 mb-1' /> {value}
        </div>
    )
}

export const openFile = (value, data, setOpenFile, setFileInfoOpen, tableManager) => {

    setTimeout(() => {
        if (tableManager && sessionStorage.getItem("d-search").length > 2) {
            const { searchApi } = tableManager;
            const { setSearchText } = searchApi;
            setSearchText(sessionStorage.getItem("d-search") ? sessionStorage.getItem("d-search") : '')
        }
    }, 500);


    return (
        <div className='rgt-cell-inner rgt-cell-component rgt-cell-linkHover' onClick={() => {
            if (data.lastIdFile) {
                setOpenFile(data.lastIdFile);
                setFileInfoOpen({ lastDownload: data.lastDownload })
            }
        }}>
            {value}
        </div>
    )
}

export const kindIcon = ({ value, data }) => {
    if (value === 'driver') {
        return (
            <div className='rgt-cell-inner rgt-cell-component'>
                <FiUsers className='fs-6 mb-1' />
            </div>
        )
    }
    if (value === 'vehicle') {
        return (
            <div className='rgt-cell-inner rgt-cell-component'>
                <LiaDigitalTachographSolid className='fs-4' />
            </div>
        )
    }
}

export const download = (value, data, setFileDownload, setOpenFile) => {

    return (
        <div className='rgt-cell-inner rgt-cell-component rgt-cell-linkHover' onClick={() => { setFileDownload(true); setOpenFile(data.id) }}>
            <FiDownload className='fs-6 mb-1' />
        </div >
    )
}

export function dddVehicleColumns(setOpenFile, setFileInfoOpen, setFileList) {

    const c = [
        // {
        //     id: 0,
        //     field: 'name',
        //     label: i18n.t('devices.name'),
        //     cellRenderer: ({ value, data, tableManager }) => { return openFile(value, data, setOpenFile, setFileInfoOpen, tableManager) },
        //     sortable: true,
        //     width: 'auto',
        //     // resizable: false,
        //     // searchable: false,
        // },
        {
            id: 1,
            field: 'plate',
            label: i18n.t('devices.plate'),
            cellRenderer: ({ value, data }) => { return openFile(value, data, setOpenFile, setFileInfoOpen) },
            sortable: true,
            width: 'auto',
            // resizable: false,
            // searchable: false,
        },
        {
            id: 3,
            field: 'lastDownload',
            label: i18n.t('ddd.lastDownload'),
            sortable: true,
            cellRenderer: stampDownload,
            width: 'auto',
            // sort: sortStamp,
            // resizable: false,
            searchable: false,
        },
        {
            id: 4,
            field: 'lastRide',
            label: i18n.t('devices.lastRide'),
            sortable: true,
            cellRenderer: stamp,
            width: 'auto',
            // resizable: false,
            searchable: false,
        },
        {
            id: 5,
            field: 'countFiles',
            label: i18n.t('ddd.tachograph'),
            cellRenderer: ({ value, data }) => { return showFilesVehicle(value, data, setFileList) },
            sortable: true,
            width: 'auto',
            // resizable: false,
            searchable: false,
        },
        {
            id: 6,
            field: 'countFilesDrivers',
            label: i18n.t('ddd.drivers'),
            cellRenderer: ({ value, data }) => { return showFilesDrivers(value, data, setFileList) },
            sortable: true,
            width: 'auto',
            // resizable: false,
            searchable: false,
        },

    ]
    return c
}

export function dddDriversColumns(setOpenFile, setFileInfoOpen, setFileList) {


    const c = [
        {
            id: 0,
            field: 'name',
            label: i18n.t('devices.name'),
            cellRenderer: ({ value, data }) => { return openFile(value, data, setOpenFile, setFileInfoOpen) },
            sortable: true,
            width: 'auto',
            // resizable: false,
            // searchable: false,
            search: ({ value, searchText }) => {
                //normalize NFD and replace - remove accents like Řecko to recko
                const aValue = value.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                const aSearchText = searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                if (aValue.includes(aSearchText)) return value
            }
        },
        {
            id: 2,
            field: 'lastDownload',
            label: i18n.t('ddd.lastDownload'),
            // cellRenderer: date,
            sortable: true,
            cellRenderer: stampDownload,
            width: 'auto',
            // resizable: false,
            searchable: false,
        },
        {
            id: 3,
            field: 'lastRide',
            label: i18n.t('devices.lastRide'),
            sortable: true,
            cellRenderer: stamp,
            width: 'auto',
            // resizable: false,
            searchable: false,
        },
        {
            id: 4,
            field: 'countFiles',
            label: i18n.t('ddd.files'),
            cellRenderer: ({ value, data }) => { return showFiles(value, data, setFileList) },
            // cellRenderer: showFiles,
            sortable: true,
            width: 'auto',
            // resizable: false,
            searchable: false,
        }

    ]
    return c
}

export function dddListFilesColumns(setFileDownload, setOpenFile, modal) {
    const c = [
        {
            id: 0,
            field: 'vehicle',
            label: i18n.t('devices.vehicle'),
            sortable: true,
            width: 'auto',
        },
        {
            id: 1,
            field: 'driver',
            label: i18n.t('devices.driver'),
            sortable: true,
            width: 'auto',
        },
        {
            id: 2,
            field: 'activityPeriod',
            label: i18n.t('ddd.activityPeriod'),
            sortable: true,
            width: 'auto',
        },
        {
            id: 3,
            field: 'kind',
            label: i18n.t('ddd.kind'),
            cellRenderer: kindIcon,
            width: 'auto',

        },
        {
            id: 4,
            field: 'downloaded',
            label: i18n.t('ddd.downloaded'),
            sortable: true,
            width: 'auto',
        },
        {
            id: 5,
            field: 'download',
            label: i18n.t('ddd.download'),
            cellRenderer: ({ value, data }) => { return download(value, data, setFileDownload, setOpenFile) },
            sortable: true,
            width: 'auto',
            visible: modal ? true : true
        },
        {
            id: 'checkbox',
            pinned: false,
            width: 'auto',
            sortable: false,
            searchable: false,
        }
    ]
    return c
}

export function updateColumnsLayout(t) {

    setTimeout(() => {
        let nc = []
        t.columnsApi.columns.map((cc) => {
            return nc.push({ id: cc.id, index: cc.index, width: cc.width, visible: cc.visible, name: cc.field })
        })
        // setUiConfig({ ...uiConfig, columnsLayoutDevicesView: nc })
    }, 5);
}


export function ColumnVisibility({ tableManager }) {

    const { columnsVisibilityApi, columnsApi } = tableManager;
    const { toggleColumnVisibility } = columnsVisibilityApi;
    const { columns } = columnsApi;
    const [btncolumnman, setBtncolumnman] = useState(false);
    // const tableName = tableManager.refs.rgtRef.current.classList[1];
    return (
        <div className='rgt-columns-manager-wrapper'>
            <button style={{ backgrounColor: 'unset' }} className='rgt-columns-manager-button' onClick={() => { setBtncolumnman(!btncolumnman) }}><FiSettings title={i18n.t('widget.managerColumns')} /></button>
            <div className={'rgt-columns-manager-popover' + (btncolumnman ? ' rgt-columns-manager-popover-open' : '')}>
                <span className='rgt-columns-manager-popover-title'>{i18n.t('widget.managerColumns')}</span>
                <div className='rgt-columns-manager-popover-body'>
                    {columns.map((column, idx) => (
                        // idx !== 0 && idx !== 1 && idx !== columns.length - 1 ?
                        <div className='rgt-clickable rgt-columns-manager-popover-row' key={idx} style={{ flex: 1 }}>
                            <label className='rgt-clickable rgt-columns-manager-popover-row' htmlFor={`checkbox-${idx}`} style={{ flex: 1, cursor: 'pointer' }}>
                                {column.label}
                            </label>
                            <input
                                className='rgt-clickable'
                                id={`checkbox-${idx}`}
                                type="checkbox"
                                onChange={e => {
                                    updateColumnsLayout(tableManager);
                                    toggleColumnVisibility(column.id)
                                }}
                                checked={column.visible !== false}
                            />
                        </div>
                        // : ''
                    ))
                    }
                    <button className='btn btn-dark' style={{ margin: 'auto', fontSize: '14px' }} onClick={() => { setBtncolumnman(!btncolumnman) }}>{i18n.t('widget.close')}</button>
                    <p className='text-center mt-2 mb-0 ' style={{ fontSize: '14px' }} role='button' onClick={() => { localStorage.removeItem("rides-columns"); localStorage.removeItem("rides-columns"); window.location.reload() }}>{i18n.t('widget.resetDefault')}</p>
                </div>
            </div>
        </div >
    )
}

