import React, { useState, useEffect, useContext, useRef } from "react";
import GridTable from '@nadavshaar/react-grid-table';
import { useTranslation } from 'react-i18next';
import { Accordion } from "react-bootstrap"
import * as Sentry from "@sentry/react";

import { FiSettings } from 'react-icons/fi';
import { AiOutlineSearch } from 'react-icons/ai'
import { UserContext } from "../userDetails";
import { getCallRender, getSortF, getLabel, columnsDevicesView } from "./columnsDevicesView";
import { WsSend } from "../../scripts/ws";
import Modal from 'react-bootstrap/Modal';

import "./DevicesView.css"
import { information } from "../RidesView/ridesRows";

export default function DevicesView({ devices, height, width, devFocus, prevDevFocus, setDevFocus, lock }) {

  const { t } = useTranslation('translation');
  const { uiConfig, setUiConfig, clientFilters } = useContext(UserContext);

  const sc = useContext(UserContext).uiConfig.columnsLayoutDevicesView;
  const columSort = useContext(UserContext).uiConfig.columnSortDevicesView;
  const uiGroups = useContext(UserContext).uiConfig.uiGroups;
  const [btncolumnman, setBtncolumnman] = useState(false);
  const [showGroups, setShowGroups] = useState(uiGroups ? uiGroups.show : false);
  const deviceNotinGroups = useRef(null);
  const refRender = useRef(null);

  const ColumnVisibility = ({ tableManager }) => {
    const { columnsVisibilityApi, columnsApi, searchApi } = tableManager;
    const { toggleColumnVisibility } = columnsVisibilityApi;
    const { columns, setColumns } = columnsApi;
    const { setSearchText } = searchApi;
    useEffect(() => {
      setSearchText(sessionStorage.getItem("d-search") ? sessionStorage.getItem("d-search") : '')
    }, []);

    useEffect(() => {
      if (!(columSort && columSort.colId)) {
        if (refRender.current) {
          if (prevDevFocus && devFocus.id !== prevDevFocus.id && !devFocus.zoom) {
            const indexScroll = tableManager.rowsApi.rows.findIndex(d => d.id == devFocus.id);           
            tableManager.rowVirtualizer.scrollToOffset(indexScroll * 48); // 48 is size one rows
          }
        } else if (devFocus) {
          const indexScroll = tableManager.rowsApi.rows.findIndex(d => d.id == devFocus.id);
          setTimeout(() => {
            tableManager.rowVirtualizer.scrollToOffset(indexScroll * 48); // 48 is size one rows
          }, 10);

          refRender.current = true;
        }
      }
    }, [devFocus])
    return (
      <div className={`rgt-columns-manager-wrapper ${showGroups && clientFilters.length > 0 ? 'showGroups' : ''}`} style={lock ? { marginRight: '-25px' } : {}}>
        <button style={{ backgrounColor: 'unset' }} className='rgt-columns-manager-button' onClick={() => { setBtncolumnman(!btncolumnman) }}><FiSettings title={t('widget.managerColumns')} /></button>
        <Modal
          centered
          animation={false}
          show={btncolumnman}
          className="d-flex justify-content-center"
          fullscreen='sm-down'
          onHide={() => {
            setBtncolumnman(false); setTimeout(() => {
              updateColumnsLayout(tableManager);
            }, 500)
          }}>
          <Modal.Header closeButton >
            <span className="fw-bold me-5">{t('widget.managerView')}</span>
          </Modal.Header>
          <Modal.Body>
            <div className='rgt-columns-manager-popover-body' style={{ maxHeight: 'unset' }}>

              {clientFilters.length > 0 ? (
                <div className='rgt-clickable rgt-columns-manager-popover-row mb-3' >
                  <label className='rgt-clickable rgt-columns-manager-popover-row pe-6'>
                    {t('widget.showGroups')}
                    <input
                      type="checkbox"
                      checked={showGroups}
                      onChange={() => setShowGroups(!showGroups)}
                    />
                  </label>

                </div>)
                : ''}

              <span className="fw-bold me-5 mb-1">{t('widget.managerColumns')}</span>
              {columns.map((column, idx) => (
                <div className='rgt-clickable rgt-columns-manager-popover-row' key={idx} >
                  <label className='rgt-clickable rgt-columns-manager-popover-row pe-6' htmlFor={`checkbox-${idx}`} style={{ flex: 1, cursor: 'pointer' }}>
                    {column.label}
                  </label>
                  <input
                    className='rgt-clickable'
                    id={`checkbox-${idx}`}
                    type="checkbox"
                    onChange={(e) => toggleColumnVisibility(column.id)}
                    checked={column.visible !== false}
                  />
                </div>
              ))}
              <p className='text-center mt-2 mb-0 ' style={{ fontSize: '14px' }} role='button'
                onClick={() => { setColumns(getColumns(columnsDevicesView)) }}>{t('widget.resetDefault')}</p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }

  const updateColumnsLayout = (t) => {
    setTimeout(() => {
      // const nc = t.columnsApi.columns.map((cc) => {
      //   return ({ id: cc.id, index: cc.index, field: cc.field, label: cc.label, width: cc.width, visible: cc.visible, sortable: cc.sortable, searchable: cc.searchable })
      // })
      const nc = t.columnsApi.columns.map((cc) => {
        return ({ id: cc.id, index: cc.index, field: cc.field, width: cc.width, visible: cc.visible, sortable: cc.sortable, searchable: cc.searchable })
      })
      setUiConfig({ ...uiConfig, columnsLayoutDevicesView: nc })
    }, 5);
  }

  const getColumns = (c) => {
    return c.map(data => {
      let res = {
        ...data,
        visible: c[data.index].visible,
        width: c[data.index].width,
        label: getLabel(data.field),
      }
      const callrender = getCallRender(data.field);
      const getsortf = getSortF(data.field);
      if (callrender) res = { ...res, cellRenderer: callrender }
      if (getsortf) res = { ...res, sort: getsortf }
      return res
    })
  }

  const getRows = ((devices) => {
    if (!devices) return null
    let res = []
    try {
      devices.forEach((d) => {
        if (d.code) {
          let tmpGroup = null;
          if (d.astatus) {
            if (d.astatus.tmp1 && d.astatus.tmp1 !== '-100.0') tmpGroup = d.astatus.tmp1;
            if (d.astatus.tmp5 && d.astatus.tmp5 !== '-100.0') tmpGroup = ((tmpGroup ? (tmpGroup + ', ') : '') + d.astatus.tmp5);
            if (d.astatus.tmp6 && d.astatus.tmp6 !== '-100.0') tmpGroup = ((tmpGroup ? (tmpGroup + ', ') : '') + d.astatus.tmp6);
            if (d.astatus.tmp7 && d.astatus.tmp7 !== '-100.0') tmpGroup = ((tmpGroup ? (tmpGroup + ', ') : '') + d.astatus.tmp7);
            if (d.astatus.tmp8 && d.astatus.tmp8 !== '-100.0') tmpGroup = ((tmpGroup ? (tmpGroup + ', ') : '') + d.astatus.tmp8);
            if (tmpGroup) tmpGroup = tmpGroup + " ℃"
          }
          // console.log(d,d.name,' lastdrivername:',d.lastdrivername);
          res.push({
            'id': d.id,
            'status': d.status,
            'icon': d.icon,
            'icolor': d.icolor,
            'name': d.name,
            'plate': d.plate,
            'address': d.address,
            'addressstamp': d.addressstamp,
            'statusstamp': d.statusstamp,
            'lastRide': d.ridestamp,
            'speed': d.status & 1 ? d.pos[3] + ' km/h' : '',
            'tmp5': d.astatus.tmp5 ? d.astatus.tmp5 : '',
            'tacho': d.tacho ? Math.round(d.tacho).toLocaleString() + 'km' : '',
            'tank': d.astatus.tank ? d.astatus.tank + 'L' : '',
            'driver': d.driver,
            'drivername': d.drivername ? (d.driver2name ? (d.drivername + ' / ' + d.driver2name) : d.drivername) : null,
            'gitem1': d.gitem1,
            'isstatic': d.isstatic,
            'tmps': tmpGroup
          })
        }
      })
      return res
    }
    catch (error) {
      console.error(error);
      Sentry.captureException(error)
      return null;
    }
  })

  const DeviceTable = ({ devicesFilter, index }) => {
    return <GridTable
      style={{ minHeight: 'unset' }}
      className={'devicesGroup-table'}
      columns={getColumns(sc ? sc : columnsDevicesView)}
      rows={devicesFilter ? getRows(devicesFilter) : ''}
      selectedRowsIds={[devFocus ? devFocus.id : '']}
      showSearch={true}
      showColumnVisibilityManager={index === 0 ? true : false}
      showRowsInformation={false}
      isPaginated={false}
      texts={{
        search: '',
        noResults: t('devices.notFind')
      }}
      icons={{ search: <AiOutlineSearch /> }}
      onColumnResizeEnd={({ column }, tableManager) => { updateColumnsLayout(tableManager) }}
      onColumnReorderEnd={(sortData, tableManager) => { updateColumnsLayout(tableManager) }}
      onSortChange={({ colId, isAsc }) => { setUiConfig({ ...uiConfig, columnSortDevicesView: { colId: colId, isAsc: isAsc } }) }}
      components={{ ColumnVisibility }}  //DragHandle
      enableColumnsReorder={true}
      highlightSearch={false}
      sort={columSort}
      onSearchTextChange={(searchText) => { sessionStorage.setItem("d-search", searchText) }}
      onRowClick={({ data }) => {
        const dev = devicesFilter.find((d) => d.id == data.id)
        const devZoom = { ...dev, zoom: true };
        setDevFocus(devZoom)
      }}
    />
  }

  if (showGroups && !deviceNotinGroups.current) {
    deviceNotinGroups.current = devices.filter(d => {
      return !clientFilters.some(f => { return f.devices.some(fd => { return fd == d.id }) })
    })
  }

  useEffect(() => {
    if (showGroups && !uiGroups) setUiConfig({ ...uiConfig, uiGroups: { show: showGroups } })
    else if (uiGroups && showGroups !== uiGroups.show) setUiConfig({ ...uiConfig, uiGroups: { show: showGroups } })
  }, [showGroups])

  if (showGroups && clientFilters.length > 0) {
    let keyGroup = clientFilters.map(f => { return f.id });
    keyGroup.push(0)
    return (
      <>
        <Accordion alwaysOpen defaultActiveKey={keyGroup} className="devicesFilter overflow-scroll" style={{ height: height + 'px', }}>
          {clientFilters.map((f, index) => {
            const devicesFilter = devices.filter(d => { return f.devices.includes(d.id) });
            return (
              <Accordion.Item key={f.id} eventKey={f.id}>
                <Accordion.Header as={'div'}>{`${f.name} [${f.devices.length}]`}</Accordion.Header>
                <Accordion.Body>
                  <DeviceTable devicesFilter={devicesFilter} index={index} />
                </Accordion.Body>
              </Accordion.Item>)
          })}
          {deviceNotinGroups.current.length > 0 ? (
            <Accordion.Item key={0} eventKey={0}>
              <Accordion.Header as={'div'}>{`${t('devices.uncategorized')} [${deviceNotinGroups.current.length}]`}</Accordion.Header>
              <Accordion.Body>
                <DeviceTable devicesFilter={deviceNotinGroups.current} />
              </Accordion.Body>
            </Accordion.Item>
          ) : <></>}

        </Accordion>
      </>
    )
  } else return (
    <>
      <GridTable
        style={{ minHeight: 'unset', height: height + 'px' }}
        className={'devices-table'}
        columns={getColumns(sc ? sc : columnsDevicesView)}
        rows={devices ? getRows(devices) : ''}
        selectedRowsIds={[devFocus ? devFocus.id : '']}
        showSearch={true}
        showColumnVisibilityManager={true}
        showRowsInformation={false}
        isPaginated={false}
        texts={{
          search: '',
          noResults: t('devices.notFind')
        }}
        icons={{ search: <AiOutlineSearch /> }}
        onColumnResizeEnd={({ column }, tableManager) => { updateColumnsLayout(tableManager) }}
        onColumnReorderEnd={(sortData, tableManager) => { updateColumnsLayout(tableManager) }}
        onSortChange={({ colId, isAsc }) => { setUiConfig({ ...uiConfig, columnSortDevicesView: { colId: colId, isAsc: isAsc } }) }}
        components={{ ColumnVisibility }}  //DragHandle
        enableColumnsReorder={true}
        highlightSearch={false}
        sort={columSort}
        onSearchTextChange={(searchText) => { sessionStorage.setItem("d-search", searchText) }}
        onRowClick={({ data }) => {
          const dev = devices.find((d) => d.id == data.id)
          const devZoom = { ...dev, zoom: true };
          setDevFocus(devZoom)
        }}
      />
    </>
  )

}