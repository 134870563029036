import { format, formatISO } from 'date-fns'

export default function groupDataRides(data) {
    if (!data) return null
    //slice copy array to new array if not using origin array will be edit too
    const dataSort = data.slice()
    dataSort.sort((a, b) => new Date(b.stamp1) - new Date(a.stamp1));
    const groupedData = dataSort.reduce((acc, curr) => {
        // date in stamp is in UTC for convert is using new Date()
        const date = format(new Date(curr.stamp1), 'yyyy-MM-dd');
        if (!acc[date]) {
            // Create new day
            if (curr.subtype === 106) {
                acc[date] = {
                    date: date,
                    stamp1: formatISO(new Date(curr.stamp1)),
                    stamp2: formatISO(new Date(curr.stamp2)),
                    text1: curr.text1,
                    text2: curr.text2,
                    distance: 0,
                    distancep: 0,
                    duration: 0,
                    ride: [curr],
                    rideIds: [curr.id],
                    units: curr.units,
                    purpose: [],
                    drivername: [],
                    plate: [curr.plate],
                    fuel: 0,
                    cons: 0,
                    fuelUnits: 0,
                    type: [],
                    lasttacho: 0,
                    speed: 0,
                    tank: 0,
                    lasttank: 0,
                    fueling: curr.fuel,
                    onlyfuel: true
                };
            }
            else {
                acc[date] = {
                    date: date,
                    stamp1: formatISO(new Date(curr.stamp1)),
                    stamp2: formatISO(new Date(curr.stamp2)),
                    text1: curr.text1,
                    text2: curr.text2,
                    distance: !curr.isprivate ? curr.distance : 0,
                    distancep: curr.isprivate ? curr.distance : 0,
                    duration: new Date(curr.stamp2) - new Date(curr.stamp1),
                    ride: [curr],
                    rideIds: [curr.id],
                    units: curr.units,
                    purpose: [curr.purpose],
                    drivername: [curr.drivername],
                    plate: [curr.plate],
                    fuel: curr.fuel,
                    cons: curr.cons,
                    fuelUnits: curr.fuelUnits,
                    type: [curr.isprivate],
                    lasttacho: curr.lasttacho,
                    speed: curr.speed,
                    tank: curr.tank,
                    lasttank: curr.lasttank,
                    fueling: 0,
                    onlyfuel: false
                };
            }
        } else {
            // Update item for actual day
            if (curr.stamp1 < acc[date].stamp1) {
                acc[date].stamp1 = formatISO(new Date(curr.stamp1));
                acc[date].text1 = curr.text1;
            }
            if (curr.stamp2 > acc[date].stamp2) {
                acc[date].stamp2 = formatISO(new Date(curr.stamp2));
                acc[date].text2 = curr.text2;
            }
            acc[date].ride.push(curr);
            acc[date].rideIds.push(curr.id);
            if (curr.subtype !== 106) {
                if (!curr.isprivate) acc[date].distance += curr.distance;
                else acc[date].distancep += curr.distance;
                acc[date].duration += new Date(curr.stamp2) - new Date(curr.stamp1);
                if (!(acc[date].purpose.includes(curr.purpose))) acc[date].purpose.push(curr.purpose)
                acc[date].purpose = acc[date].purpose.filter(item => item !== '' && item !== null)
                acc[date].onlyfuel = false
                acc[date].fuel += curr.fuel;
                acc[date].cons += curr.cons;
                if (acc[date].lasttacho === 0) acc[date].lasttacho = curr.lasttacho
            }
            else acc[date].fueling += curr.fuel
            if (!(acc[date].drivername.includes(curr.drivername))) acc[date].drivername.push(curr.drivername)
            acc[date].drivername = acc[date].drivername.filter(item => item !== null)

            if (!(acc[date].drivername.includes(curr.driver2name))) acc[date].drivername.push(curr.driver2name)
            acc[date].drivername = acc[date].drivername.filter(item => item !== null)

            if (!(acc[date].plate.includes(curr.plate))) acc[date].plate.push(curr.plate)
            acc[date].plate = acc[date].plate.filter(item => item !== null)

            if (!(acc[date].type.includes(curr.isprivate))) {
                if (curr.isprivate) acc[date].type.push(curr.isprivate)
                else acc[date].type.unshift(curr.isprivate)
            }
            if (curr.speed > acc[date].speed) {
                acc[date].speed = curr.speed;
            }
            acc[date].tank = curr.tank;
        }
        return acc;
    }, {})
    return Object.values(groupedData);
}