import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { iconKind } from "../../images/kind"
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { BiSearchAlt2 } from 'react-icons/bi'
import { Button, Form, Modal } from "react-bootstrap"
import { HiMiniServerStack } from "react-icons/hi2";
import { client as clientws } from 'websocket';

import { CarsGroup } from "../../images";
import { checkModel, WsSend, WsAnal, WSdataList, WSdata } from "../../scripts/ws"
import { useKeyboardShortcut, masterUser } from "../tools";

import "./SelectComponent.css"

const onMenuOpen = () => {
    setTimeout(() => {
        const selectedEl = document.getElementsByClassName("react-select__option--is-selected")[0];
        if (selectedEl) selectedEl.scrollIntoView({ block: 'nearest', behavior: 'auto' });
    }, 15);
};

export function SelectComponentDevices({ devices, devFocus, setDevFocus, WsSend, height, wsId, setPage, drivFocus, setLoading, prevDevFocus, search, setNullDevice, nullDevice, filterPurpose, placeholder }) {
    const { t } = useTranslation('translation');
    const [selectedOption, setSelectedOption] = useState(devFocus ? { value: devFocus.id, label: (devFocus.code ? devFocus.name : '*' + devFocus.name) + ' ' + devFocus.plate, name: (devFocus.code ? devFocus.name : '*' + devFocus.name), plate: devFocus.plate, data: devFocus } : null);

    useEffect(() => {
        if (devFocus === null && (wsId === 6 || wsId === 27)) setSelectedOption(devFocus)
        if (selectedOption === null && devFocus) setSelectedOption({ value: devFocus.id, label: (devFocus.code ? devFocus.name : '*' + devFocus.name) + ' ' + devFocus.plate, name: (devFocus.code ? devFocus.name : '*' + devFocus.name), plate: devFocus.plate, data: devFocus })
        if (selectedOption && devFocus && prevDevFocus && devFocus.id != prevDevFocus.id && wsId !== 13) {
            if (wsId === 19 || wsId === 6) setLoading(true);
            WsSend({
                method: 'modify',
                id: wsId,
                param: 'device',
                value: devFocus.id
            })
            // const findDevFocus = devices.find((dev) => dev.id === devFocus);
            // setSelectedOption({ value: devFocus.id, label: devFocus.name + ' ' + devFocus.plate, name: devFocus.name, plate: devFocus.plate, data: devFocus })
        };
        if (devFocus && selectedOption && devFocus.id !== selectedOption.value) setSelectedOption({ value: devFocus.id, label: (devFocus.code ? devFocus.name : '*' + devFocus.name) + ' ' + devFocus.plate, name: (devFocus.code ? devFocus.name : '*' + devFocus.name), plate: devFocus.plate, data: devFocus })
    }, [devFocus])


    const listDevices = ((devices) => {
        // tohle neni potřeba upravilo se na serveru ale je to pro odstranění háčku a čárek a seřazení
        // const devicesSort = devices.sort((a, b) => (a.name.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")).localeCompare(b.name.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        return devices.map((dev) => {
            return { value: dev.id, label: (dev.code ? dev.name : '*' + dev.name) + ' ' + dev.plate, name: (dev.code ? dev.name : '*' + dev.name), plate: dev.plate, data: dev }
        })
    })

    const formatOptionLabel = ({ name, plate, data }) => {
        return (
            <div className="cursorpointer" style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '90px' }}>
                    {iconKind(data, true)}
                </div>
                <div>
                    <div className="fs-6 ms-1">{name}</div>
                    <div className="fs-7 ms-1">{plate} </div>
                </div>
            </div>
        );
    };

    const handleChange = (selectedOption) => {
        // console.log(selectedOption);
        if (selectedOption) {
            if (nullDevice) {
                setLoading(true);
                setNullDevice(false);
            }
            setSelectedOption(selectedOption);
            localStorage.setItem('devFocus', selectedOption.value)
            setDevFocus(selectedOption.data);
        }
        else if (selectedOption === null) {
            setDevFocus(null);
            if (search || filterPurpose) {
                setLoading(true);
                setNullDevice(true);
            }
        }
        if (setPage) {
            setPage(1)
            WsSend({
                method: 'modify',
                id: wsId,
                params: {
                    device: selectedOption.value,
                    page: 1
                }
            })
        }
        else {
            if (drivFocus && !search) {
                WsSend({
                    method: 'modify',
                    id: wsId,
                    params: {
                        device: selectedOption ? selectedOption.value : null,
                        driver: drivFocus.value
                    }
                })
            }
            if (search) {
                WsSend({
                    method: 'modify',
                    id: wsId,
                    params: {
                        mask: search,
                        device: selectedOption ? selectedOption.value : null,
                        driver: drivFocus ? drivFocus.value : null
                    }
                })
            }
            if (filterPurpose) {
                WsSend({
                    method: 'modify',
                    id: wsId,
                    params: {
                        purpose: filterPurpose.value,
                        device: selectedOption ? selectedOption.value : null,
                        driver: drivFocus ? drivFocus.value : null
                    }
                })
            }
            // tohle zavolá modify jen tam kde se to změni přidano do use effect nahoře když se změni devFocus
            // else {
            //     console.log('run', wsId);
            //     WsSend({
            //         method: 'modify',
            //         id: wsId,
            //         param: 'device',
            //         value: selectedOption.value
            //     })
            // }

        }
    };

    if (!devices) return (<div></div>)
    //style={{ minWidth: '200px', cursor: 'pointer', zIndex: 4 }}
    return (
        <div className="m-2" style={{ maxWidth: '300px' }}>
            <Select
                // defaultMenuIsOpen
                isClearable={drivFocus || search || filterPurpose ? true : false}
                className="w-100 react-select-container"
                classNamePrefix="react-select"
                aria-label="Default select example"
                value={selectedOption}
                onChange={handleChange}
                options={listDevices(devices)}
                placeholder={placeholder ? placeholder : t('devices.filterVehicle')}
                // onFocus={() => { console.log('onFocus') }}
                // onBlur={() => { console.log('onBlur') }}
                //  components={{  ValueContainer }}
                formatOptionLabel={formatOptionLabel}
                noOptionsMessage={() => t('devices.notFind')}
                maxMenuHeight={height > 500 ? (height / 2) : (height - 120)}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, minWidth: 300, }) }}
                menuPortalTarget={document.body}
                onMenuOpen={onMenuOpen}
            />
        </div>
    )
}

export function SelectComponentClients({ client, WsSend, setDev, setDevH, setDevR, setDriv, setClient, sideBar }) {
    const { t } = useTranslation('translation');
    const actualClient = localStorage.getItem('clientName') ? { value: localStorage.getItem('clientid'), label: localStorage.getItem('clientName') } : { value: client.slice(' ')[0], label: client };
    const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false)
    const asyncSelectRef = useRef(null);
    const navigate = useNavigate()
    const timeout = useRef(null);
    const [listClientSearch, setListClientSearch] = useState();

    // const customStyles = {
    //     control: (provided) => ({
    //         ...provided,
    //         maxHeight: '20px',
    //     }),
    // };


    const focusSearchClients = () => {
        if (!sideBar) {
            if (!modalOpen) {
                setModalOpen(true)
                clientws.addEventListener('message', handleMessage);
                // WsSend({ method: 'search', oid: 11, mask: '', pageSize: 20 })
            }
            else {
                clientws.removeEventListener('message', handleMessage);
                setListClientSearch(false)
                WsSend({ method: 'unregister', id: 11 })
            }
        }
        else {
            if (asyncSelectRef.current) {
                asyncSelectRef.current.focus();
            }
        }

    };

    const changeWsKeyboard = (e) => {
        switch (e.code) {
            case 'Digit1': changeWs({ target: { value: 'ws01' } });
                break;
            case 'Digit2': changeWs({ target: { value: 'ws02' } });
                break;
            case 'Backquote': changeWs({ target: { value: 'ws99' } });
                break;
            case 'IntlBackslash': changeWs({ target: { value: 'ws99' } });
                break;
        }
    };

    useKeyboardShortcut(focusSearchClients, { code: 'KeyK', ctrlKey: true, });
    useKeyboardShortcut(changeWsKeyboard, { code: 'Digit1', altKey: true });
    useKeyboardShortcut(changeWsKeyboard, { code: 'Digit2', altKey: true });
    useKeyboardShortcut(changeWsKeyboard, { code: 'Backquote', altKey: true });
    useKeyboardShortcut(changeWsKeyboard, { code: 'IntlBackslash', shiftKey: true, });

    const changeClient = (selectedOption) => {
        setDev(null);
        setDriv(null);
        setDevH(null);
        setDevR(null);
        if (modalOpen) {
            clientws.removeEventListener('message', handleMessage);
            WsSend({ method: 'unregister', id: 11 })
        }
        const models = checkModel(location.pathname);
        if (models) {
            models.map((oid) => {
                WsSend({ method: 'unregister', id: oid });
            })
        }
        localStorage.setItem('clientId', selectedOption.value.toString());
        setClient(selectedOption.label)
        localStorage.setItem('clientName', selectedOption.label);
        sessionStorage.removeItem('d-search')
        WsSend({ method: 'setParam', name: 'client', value: selectedOption.value.toString() })
        setModalOpen(false);
    };

    const CustomDropdownIndicator = (props) => {
        return (
            <div>
                <BiSearchAlt2 className="fs-5 ms-2 me-2 text-secondary"></BiSearchAlt2>
            </div>
        );
    };

    const selectedOptionRef = useRef([]);
    const waitingData = useRef(false);

    useEffect(() => {
        if (listClientSearch) {
            selectedOptionRef.current = listClientSearch;
            waitingData.current = false;
        }
    }, [listClientSearch]);

    const login = JSON.parse(localStorage.getItem('login')) ? JSON.parse(localStorage.getItem('login')) : JSON.parse(sessionStorage.getItem('login'))

    const changeWs = ((e) => {
        localStorage.removeItem('clientId');
        localStorage.removeItem('clientName');
        sessionStorage.removeItem('uiConfig');
        sessionStorage.removeItem('d-search');
        sessionStorage.removeItem('rides-search');
        sessionStorage.removeItem('ft_session');
        localStorage.removeItem('devFocus');
        const newLogin = { ...login, domain: e.target.value }
        localStorage.setItem('login', JSON.stringify(newLogin))
        window.location.reload();
    })

    const servers = () => {
        return (<>
            <option value="ws00">WS00 - test.logiscare.com</option>
            <option value="ws01">WS01 - portal.logiscare.com</option>
            <option value="ws02">WS02 - gps.logiscare.com</option>
            <option value="ws04">WS04 - globaltrack.cz</option>
            <option value="ws05">WS05 - bg-gps.com</option>
            <option value="ws07">WS07 - alfakomgo.gr</option>
            <option value="ws08">WS08 - www2.globaltrack.cz</option>
            {process.env.NODE_ENV === 'development' ? <option value="ws88">WS88 - cj2.logiscare.com</option> : <></>}
            <option value="ws99">WS99 - cj.logiscare.com</option>
        </>)
    }

    const handleMessage = (message) => {
        let data = WsAnal(message)
        // if (data && data.length > 0) {
        //     // update message 
        //     if (data.some(d => d.object === 11)) setListClientSearch(WSdataList(data, listClientSearch, 11));
        // }
        // // first new message from query
        // else 
        if (data && data.id === 11) setListClientSearch(WSdata(data, null, 11));
    }

    if (sideBar) return (
        <div className="mx-2 my-1" style={{ cursor: 'pointer' }}>
            <AsyncSelect
                // defaultMenuIsOpen={menuIsOpen}
                // autoFocus={menuIsOpen}
                ref={asyncSelectRef}
                cacheOptions
                loadOptions={
                    (inputValue) =>
                        new Promise((resolve) => {
                            if (timeout.current) clearTimeout(timeout.current);
                            if (inputValue.toString().length > 2) {
                                timeout.current = setTimeout(() => {
                                    // WsSend({ method: 'modify', id: 11, param: 'mask', value: inputValue.toString() })
                                    WsSend({ method: 'search', oid: 11, mask: inputValue.toString(), pageSize: 20 })
                                    waitingData.current = true;
                                    let count = 1;
                                    const intervalId = setInterval(() => {
                                        count++
                                        if (!waitingData.current) {
                                            clearInterval(intervalId);
                                            if (selectedOptionRef.current.list.length > 0) {
                                                const res = selectedOptionRef.current.list.map((c) => ({
                                                    value: c.id,
                                                    label: c.reseller + ' ' + c.name,
                                                }));
                                                resolve(res);
                                            }
                                            else resolve([])
                                        }
                                        if (count > 100) clearInterval(intervalId);
                                    }, 100);
                                }, 400);
                            }
                        })
                }
                // styles={customStyles}
                className="w-100 react-select-container selectClient"
                classNamePrefix="search react-select"
                aria-label="Default select example"
                defaultValue={actualClient}
                onChange={changeClient}
                onFocus={() => {
                    clientws.addEventListener('message', handleMessage);
                    // WsSend({ method: 'search', oid: 11, mask: '', pageSize: 20 })
                }}
                onBlur={() => {
                    clientws.removeEventListener('message', handleMessage);
                    setListClientSearch(false)
                    WsSend({ method: 'unregister', id: 11 })
                }}
                blurInputOnSelect={true}
                loadingMessage={() => t('devices.loading')}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
                noOptionsMessage={() => t('devices.notFind')}
                styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999, width: 350, }),
                }}
                menuPortalTarget={document.body}
            />
            {login && masterUser.includes(login.uname) ?
                <div className="d-flex">
                    <Form.Select onChange={changeWs} size="sm" className="w-100 mt-2 ps-3 cursorpointer" defaultValue={login.domain ? login.domain : ""} aria-label="Default select example">
                        {servers()}
                    </Form.Select>
                    <Button variant="light" onClick={() => navigate('/admin')} className="mt-2 ms-2 icon-navbar-dropdown px-2"><HiMiniServerStack className="fs-4" /></Button>
                </div>
                : ''}
        </div>
    )
    else return (
        <>
            <div className="text-center">
                <Button variant="light" className="sidebar-menu-toggle collapsed" onClick={() => {
                    setModalOpen(true);
                    clientws.addEventListener('message', handleMessage);
                    WsSend({ method: 'search', oid: 11, mask: '', pageSize: 20 })
                }} ><BiSearchAlt2 className="fs-4" /></Button>
            </div>
            <Modal size='md' fullscreen='sm-down' centered show={modalOpen} onHide={() => {
                setModalOpen(false);
                WsSend({ method: 'unregister', id: 11 })
                clientws.removeEventListener('message', handleMessage);
            }} >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body>
                    <AsyncSelect
                        // defaultMenuIsOpen
                        autoFocus
                        cacheOptions
                        loadOptions={
                            (inputValue) =>
                                new Promise((resolve) => {
                                    if (timeout.current) clearTimeout(timeout.current);
                                    if (inputValue.toString().length > 2) {
                                        timeout.current = setTimeout(() => {
                                            // WsSend({ method: 'modify', id: 11, param: 'mask', value: inputValue.toString() })
                                            WsSend({ method: 'search', oid: 11, mask: inputValue.toString(), pageSize: 20 })
                                            waitingData.current = true;
                                            let count = 1;
                                            const intervalId = setInterval(() => {
                                                count++
                                                if (!waitingData.current) {
                                                    clearInterval(intervalId);
                                                    if (selectedOptionRef.current.list.length > 0) {
                                                        const res = selectedOptionRef.current.list.map((c) => ({
                                                            value: c.id,
                                                            label: c.reseller + ' ' + c.name,
                                                        }));
                                                        resolve(res);
                                                    }
                                                    else resolve([])
                                                }
                                                if (count > 100) clearInterval(intervalId);
                                            }, 100);
                                        }, 400)
                                    }
                                })
                        }
                        // styles={customStyles}
                        className="w-100 react-select-container"
                        classNamePrefix="search react-select"
                        aria-label="Default select example"
                        defaultValue={actualClient}
                        onChange={changeClient}
                        // onFocus={() =>}
                        // onBlur={() => }
                        blurInputOnSelect={true}
                        loadingMessage={() => t('devices.loading')}
                        components={{ DropdownIndicator: CustomDropdownIndicator }}
                        noOptionsMessage={() => t('devices.notFind')}
                    />
                    {login && masterUser.includes(login.uname) ?
                        <div className="d-flex">
                            <Form.Select onChange={changeWs} size="sm" className="w-100 react-select-container wsChange ps-3 mt-3 cursorpointer" defaultValue={login.domain ? login.domain : ""} aria-label="Default select example">
                                {servers()}
                            </Form.Select>
                            <Button variant="light" onClick={() => { navigate('/admin'); setModalOpen(false); WsSend({ method: 'unregister', id: 11 }); }} className="mt-3 ms-2 icon-navbar-dropdown px-2"><HiMiniServerStack className="fs-4" /></Button>
                        </div>
                        : ''}
                </Modal.Body>
            </Modal>
        </>
    )
}

export function FilterComponentDevices({ devices, filterDevice, setFilterDevice, height, filters, isClearable, style, placeholder, required, admin }) {
    const { t } = useTranslation('translation');

    const listDevices = ((devices) => {
        if (filters && filters.length > 0) {
            const res = [];

            if (required && admin) res.push({ value: -1000000, label: t('fences.filterAll'), name: t('fences.filterAll') })

            filters.forEach((f) => {
                res.push({ value: f.id * (-1), label: f.name, name: f.name })
            })

            devices.forEach((dev) => {
                res.push({ value: dev.id, label: (dev.code ? dev.name : '*' + dev.name) + ' ' + dev.plate, name: (dev.code ? dev.name : '*' + dev.name), plate: dev.plate, data: dev })
            })
            return res
        } else {
            const res = [];
            if (required && admin) res.push({ value: -1000000, label: t('fences.filterAll'), name: t('fences.filterAll') })
            devices.forEach((dev) => {
                res.push({ value: dev.id, label: (dev.code ? dev.name : '*' + dev.name) + ' ' + dev.plate, name: (dev.code ? dev.name : '*' + dev.name), plate: dev.plate, data: dev })
            })
            return res
        }
    })

    const formatOptionLabel = ({ name, plate, data }) => {
        return (
            <div className="cursorpointer" style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '90px' }}>
                    {data ? iconKind(data, true) : <CarsGroup height={20} className="ms-2" />}
                </div>
                <div>
                    <div className="fs-6 ms-1">{name}</div>
                    <div className="fs-7 ms-1">{plate} </div>
                </div>
            </div>
        );
    };

    const handleChange = (selectedOption) => {
        setFilterDevice(selectedOption);
    };

    if (!devices) return (<div></div>)
    return (
        <div className={style ? "" : "m-2"} style={style ? style : { minWidth: '200px', maxWidth: '300px', cursor: 'pointer', zIndex: '3' }}>
            <Select
                // defaultMenuIsOpen
                isClearable={isClearable === undefined ? true : isClearable}
                className="w-100 react-select-container"
                classNamePrefix="react-select"
                aria-label="Default select example"
                defaultValue={filterDevice}
                // value={filterDevice}
                onChange={handleChange}
                options={listDevices(devices)}
                placeholder={placeholder ? placeholder : t('devices.filterVehicle')}
                formatOptionLabel={formatOptionLabel}
                noOptionsMessage={() => t('notFound')}
                maxMenuHeight={height > 500 ? (height / 2) : (height - 120)}
                hideSelectedOptions={false}
                onMenuOpen={onMenuOpen}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                required={required}
            />
        </div>
    )
}

export function FilterComponentDrivers({ drivers, filterDrivers, setFiletrDrivers, height }) {
    const { t } = useTranslation('translation');

    const list = ((d) => {
        return d.map((dr) => {
            return { value: dr.id, label: dr.name }
        })
    })

    const handleChange = (selectedOption) => {
        // console.log(selectedOption); 
        setFiletrDrivers(selectedOption);
    };
    if (!drivers || (drivers && !(drivers.length > 0))) return (<div></div>)
    //cursor: 'pointer', zIndex: '3', minWidth: '170px',
    return (
        <div className="m-2" style={{ maxWidth: '300px' }}>
            <Select
                // defaultMenuIsOpen
                isClearable={true}
                className="w-100 react-select-container"
                classNamePrefix="react-select"
                aria-label="Default select example"
                value={filterDrivers}
                onChange={handleChange}
                options={list(drivers)}
                placeholder={t('devices.filterDrivers')}
                // formatOptionLabel={formatOptionLabel}
                noOptionsMessage={() => t('notFound')}
                maxMenuHeight={height > 500 ? (height / 2) : (height - 120)}
                onMenuOpen={onMenuOpen}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, minWidth: 300 }) }}
                menuPortalTarget={document.body}
            />
        </div>
    )
}

export function SelectComponentDrivers({ drivers, data, setData, className, driver2, create, placeholder, required, wsSend, notClearable, rozhlas }) {
    const { t } = useTranslation('translation');

    const list = ((d) => {
        let res = create ? [] : [{ value: 'null', label: t('stats.none') }]
        d.forEach((dr) => {
            res.push({ value: dr.id, label: (dr.name === '' && dr.username ? dr.username : dr.name) })
        })
        return res
    })

    const handleChange = (selectedOption) => {
        if (selectedOption) {
            if (selectedOption.value !== selectedOption.label) {
                if (!driver2) setData({ ...data, driver: selectedOption })
                else setData({ ...data, driver2: selectedOption })
            } else {
                if (!driver2) setData({ ...data, driver: { label: selectedOption.label, value: null } })
                else setData({ ...data, driver2: { label: selectedOption.label, value: null } })
            }
        } else {
            if (!driver2) setData({ ...data, driver: selectedOption })
            else setData({ ...data, driver2: selectedOption })
        }
    };

    if (!drivers || (drivers && !(drivers.length > 0)) && !create) return (<div></div>)
    return (
        <CreatableSelect
            // defaultMenuIsOpen
            isClearable={!notClearable}
            className={className}
            classNamePrefix="react-select"
            aria-label="Default select example"
            onChange={handleChange}
            isValidNewOption={(e) => create && e.length > 2}
            onCreateOption={(e) => {
                if (!driver2) setData({ ...data, driver: { label: e } })
                else setData({ ...data, driver2: { label: e } })
                if (wsSend) {
                    WsSend({
                        method: 'setDriver',
                        oid: 30,
                        driverid: null,
                        name: e
                    })
                }
            }}
            options={list(drivers)}
            placeholder={placeholder ? placeholder : (!driver2 ? t('devices.driver') : t('devices.driver2'))}
            onMenuOpen={onMenuOpen}
            noOptionsMessage={() => t('notFound')}
            formatCreateLabel={(e) => `${t('stats.newItem')}\n"${e}"`}
            value={!driver2 ? (data.driver ? data.driver : null) : (data.driver2 ? data.driver2 : null)}
            required={required}
        />
    )
}

export function SelectComponentStats({ statsType, setStatsType, listStats, height, setDate = { setDate } }) {
    const navigate = useNavigate();
    const { t } = useTranslation('translation');

    const handleChange = (selectedOption) => {
        setStatsType(selectedOption);
        setDate(false);
        localStorage.setItem('stats.statsType', JSON.stringify(selectedOption));
        navigate('/reports/' + selectedOption.value)
    };
    //style={{ minWidth: '250px', cursor: 'pointer', zIndex: '3' }}
    return (
        <div className="m-2 SelectComponentStats cursorpointer" >
            <Select
                // defaultMenuIsOpen
                isClearable={false}
                className="w-100 react-select-container"
                classNamePrefix="react-select"
                aria-label="Default select example"
                isSearchable={false}
                value={statsType}
                onChange={handleChange}
                options={listStats}
                // formatOptionLabel={formatOptionLabel}
                noOptionsMessage={() => t('devices.notFind')}
                maxMenuHeight={height > 500 ? (height / 2) : (height - 120)}
                onMenuOpen={onMenuOpen}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, minWidth: 250 }) }}
                menuPortalTarget={document.body}
            />
        </div>
    )
}

export function SelectComponentPurposes({ purposes, setPurpose, className, source, isValidNewOption, noOptionsMessage }) {
    const { t } = useTranslation('translation');

    const list = ((pl) => {
        let res = source === 'purpose' ? [{ value: 'null', label: t('stats.none') }] : [];
        pl.forEach((p) => {
            res.push({ value: p, label: p })
        })
        return res
    })

    const handleChange = (selectedOption) => {
        setPurpose(selectedOption ? selectedOption.value : (source === 'purpose' ? 'null' : null), source)
    };

    return (
        <CreatableSelect
            // defaultMenuIsOpen
            isClearable={true}
            className={className}
            classNamePrefix="react-select"
            aria-label="Default select example"
            onChange={handleChange}
            options={purposes && purposes.length > 0 ? list(purposes) : []}
            formatCreateLabel={(e) => `${t('stats.newItem')}\n"${e}"`}
            placeholder={t('stats.' + source)}
            onMenuOpen={onMenuOpen}
            noOptionsMessage={noOptionsMessage ? noOptionsMessage : () => t('notFound')}
            isValidNewOption={isValidNewOption ? isValidNewOption : (e) => e.length > 0 ? true : false}
        />
    )
}

export function FilterComponentPurposes({ purposes, filterPurpose, setFilterPurpose, height, purpose_array }) {
    const { t } = useTranslation('translation');

    const list = ((pl) => {
        const res = []
        pl.forEach((p) => {
            if (p === ' ') res.push({ value: p, label: t('stats.none') })
            else {
                if (p.includes('|')) {
                    if (p.split('|')[0].length > 0 && !res.includes('%' + p.split('|')[0] + '%')) res.push({ value: '%' + p.split('|')[0] + '%', label: p.split('|')[0] })
                    if (p.split('|')[1].length > 0 && !res.includes('%' + p.split('|')[1] + '%')) res.push({ value: '%' + p.split('|')[1] + '%', label: p.split('|')[1] })
                }
                else res.push({ value: p, label: p })
            }
        })
        return res
    })

    const handleChange = (selectedOption) => {
        setFilterPurpose(selectedOption);
    };

    if (purposes && purposes.length > 0) return (
        <div className="m-2" style={{ maxWidth: '300px' }}>
            <Select
                // defaultMenuIsOpen
                isClearable={true}
                value={filterPurpose}
                className='w-100 react-select-container'
                classNamePrefix="react-select"
                aria-label="Default select example"
                onChange={handleChange}
                options={list(purposes)}
                formatCreateLabel={(e) => `${t('stats.newItem')}\n"${e}"`}
                maxMenuHeight={height > 500 ? (height / 2) : (height - 120)}
                placeholder={t('devices.' + (!purpose_array ? 'filterPurpose' : 'filterPurposeArray'))}
                onMenuOpen={onMenuOpen}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, minWidth: 250 }) }}
                menuPortalTarget={document.body}
                noOptionsMessage={() => t('notFound')}
            />
        </div>
    )
}

