import { differenceInDays, parseISO, format, formatDistanceStrict, isToday, subDays  } from 'date-fns'

export function dddViewVehicleRows(filesInfo, devices, files) {
  let res = []
  
  const devWithDDD = devices.filter(d => d.cfg && d.cfg.indexOf('ddd') >= 0) 
  filesInfo.forEach((f) => {
    if (f.type === 'device') {
      const suspend = !devWithDDD.some(d => d.id === f.id);
      res.push({
        'id': f.id,
        'name': f.name,
        'plate': f.plate,
        'lastDownload': f.vehicle_count > 0 && !suspend ? f.stamp : null,
        'countFiles': f.vehicle_count > 0 ? (f.vehicle_count) : '',
        'countFilesDrivers': f.driver_count > 0 ? (f.driver_count) : '',
        'lastRide': f.ridestamp && !suspend ? f.ridestamp : null,
        'lastIdFile': f.id_last_file,
        'suspend': suspend,
        'type': 'DDDViewVehicle'
      })
    }
  })

  const withoutFile = devWithDDD.filter(d2 => !res.some(d1 => d2.id === d1.id));
  if (withoutFile.length > 0) withoutFile.forEach(d => {
    res.push({
      'id': d.id,
      'name': d.name,
      'plate': d.plate,
      'lastDownload': null,
      'countFiles': null,
      'countFilesDrivers': null,
      'lastRide': d.ridestamp ? d.ridestamp : null,
      'lastIdFile': null
    })
  })
  function suspendToLast(a, b) {
    if (a.suspend && !b.suspend) return 1;
    if (!a.suspend && b.suspend) return -1;
    return 0;
  }
  res.sort(suspendToLast)
  return res

}

export function dddViewDriversRows(filesInfo, drivers) {
  let res = []

  filesInfo.forEach((f) => {
    if (f.type === 'driver') {
      const suspend = !drivers.some(d => d.id === f.id);
      res.push({
        'id': f.id,
        'did': null,
        'driver': true,
        'name': f.name,
        'lastDownload': f.driver_count > 0 && !suspend ? (f.stamp + ';' + differenceInDays(new Date(), parseISO(f.stamp))) : null,
        'lastRide': f.ridestamp && !suspend ? f.ridestamp : null,
        'countFiles': f.driver_count > 0 ? (f.driver_count) : '',
        'lastIdFile': f.id_last_file,
        'suspend': suspend,
        'type': 'DDDViewDrivers'
      })
    }
  })
  function suspendToLast(a, b) {
    if (a.suspend && !b.suspend) return 1;
    if (!a.suspend && b.suspend) return -1;
    return 0;
  }
  res.sort(suspendToLast)
  return res

}

function isStampInRange(stamp, from, to) {
  return stamp >= from && stamp <= to;
}


export function dddListFilesRows(files, stamp) {
  let res = []
  files.forEach((f) => {
    let stampDownload = parseISO(f.stamp);
    if (isStampInRange(stampDownload, stamp[0], stamp[1])) {
      const data = {
        'id': f.id,
        'kind': f.driver ? 'driver' : 'vehicle',
        'vehicle': f.dname,
        'driver': f.namedriver,
        'activityPeriod': f.stamp1 && f.stamp2 ? `${format(parseISO(f.stamp1), 'dd.MM.yyyy')} - ${format(parseISO(f.stamp2), 'dd.MM.yyyy')}` : '',
        'downloaded': format(stampDownload, 'dd.MM.yyyy HH:mm')
      }
      res.push(data)
    }
  })
  return res
}

export function dddListFilesRowsAll(files, page, pageSize) {
  let res = []
  files.list.forEach((f) => {
    let stampDownload = parseISO(f.stamp)
    const data = {
      'id': f.id,
      'kind': f.driver ? 'driver' : 'vehicle',
      'vehicle': f.dname,
      'driver': f.namedriver,
      'activityPeriod': f.stamp1 && f.stamp2 ? `${format(parseISO(f.stamp1), 'dd.MM.yyyy')} - ${format(parseISO(f.stamp2), 'dd.MM.yyyy')}` : '',
      'downloaded': format(stampDownload, 'dd.MM.yyyy HH:mm')
    }
    res.push(data);
  })

  if (files.list.length > 1) res.sort((a, b) => b.id - a.id);
  // fill empty rows via total count rows from server
  const emptyCount = files.count;
  const emptyArray = new Array(Math.max(0, emptyCount)).fill({});
  const startIndex = (page - 1) * pageSize;
  res = emptyArray.slice(0, startIndex).concat(res, emptyArray.slice(startIndex + res.length));
  return res
}