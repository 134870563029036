import React, { useState, forwardRef, useContext, useEffect, useRef } from "react";
import { client } from 'websocket';
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { MapView, DevicesView, useWindowSize, DeviceView, getQuery, setQuery, clearQuery, } from '../../components';
import { UserContext } from "../../components/userDetails";
import usePrevious from "../../components/usePrevious"
import { newLayots, checkMount, filterLayouts, margin, breakpoints, cols, HeaderGrid, rowHeight, calcMaxH } from "../gridLayout";
import { layoutNew, layoutOverviewDefault } from "./layoutOverview";
import Loading from "../../components/Loading";
import { ToastContainer, toast, Slide } from 'react-toastify';

const ResponsiveGridLayout = WidthProvider(Responsive);

var mapWidth, mapHeight;
var devicesWidth, devicesHeight;
var deviceWidth, deviceHeight;
var timelastdata;

const GridItemComponent = forwardRef(({ style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...props }, ref) => {
  if (props.max === props.id) {
    style.height = (props.heightWindow + 10) + 'px';
    style.width = "99%";
    style.transform = 'translate(5px, 5px)';
    style.MozTransform = 'translate(5px, 5px)';
    style.OTransform = 'translate(5px, 5px)';
    style.WebkitTransform = 'translate(5px, 5px)';
    style.msTransform = 'translate(5px, 5px)';
    style.transition = 'all 200ms ease'
  }
  else {
    if (props.max) style.display = 'none';
  }

  if (props.id === 'MapView') {
    mapHeight = style.height.slice(0, -2);
    mapWidth = style.width.slice(0, -2);
  }
  if (props.id === 'DevicesList') {
    devicesHeight = style.height.slice(0, -2);
    devicesWidth = style.width.slice(0, -2);
  }
  if (props.id === 'DeviceDetail') {
    deviceHeight = style.height.slice(0, -2);
    deviceWidth = style.width.slice(0, -2);
  }
  return (
    <div style={{ ...style }} className={className} ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchEnd={onTouchEnd}>
      {children}
    </div>
  );
})

export default function Overview({ forceUpdate, widgetOnDrop, setWidgetOnDrop, defaultLayout }) {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'widget' });
  const [devices, setDevices] = useState(false);
  const [devFocus, setDevFocus] = useState(false);
  const prevDevFocus = usePrevious(devFocus);
  const [currentRide, setCurrentRide] = useState();
  const loading = useRef(false);
  const loadingCurRideTime = useRef(false);
  const [loadingRide, setLoadingRide] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const refRender = useRef(null);
  const refWs5 = useRef(null);

  document.title = t('overview')

  if (!devices) {
    // console.log('getDevices');
    loading.current = true;
    WsSend({ method: 'getDevices', oid: 4, restriction: 'view_events' })
    setDevices([])
  }

  const [updateItem, setUpdateItem] = useState();
  const heightWindow = useWindowSize().height;
  const lockLayout = useContext(UserContext).lockLayout.overview;
  const sideBar = useContext(UserContext).uiConfig.sideBar;
  const layoutOverview = useContext(UserContext).uiConfig.layoutOverview;
  const { costumer, setDev, dev, uiConfig, setUiConfig, callChangeUiConfig, setNotification } = useContext(UserContext);

  useEffect(() => { callChangeUiConfig.current = 0 }, [])

  client.onmessage = (message) => {
    let data = WsAnal(message)
    // console.log('new data Overview', data);
    // update message
    if (data) {
      if (data.length > 0) {
        timelastdata = Date.now();
        if (devices) {
          const res = WSdata(data, devices, 4);
          setDevices(res);
          setDev(res)
        }
        if (currentRide) setCurrentRide({ val: WSdata(data, currentRide.val, 5), dev: devFocus.id });
      }

      // first new message from query
      else {
        if (data.id === 4) {
          timelastdata = Date.now();
          let res = WSdata(data, devices, 4).list;
          setDevices(res);
          setDev(res)
          loading.current = false;
        }
        if (data.id === 5) {
          refWs5.current = false;
          setCurrentRide({ val: WSdata(data, currentRide, 5).list, dev: devFocus.id })
          if (loadingCurRideTime.current) clearTimeout(loadingCurRideTime.current)
          setLoadingRide(false)
        }
        if (data && data.id === 26) setNotification(WSdata(data, null, 26))
      }
    }
  }

  const resize = (() => {
    setTimeout(() => {
      setUpdateItem(!updateItem);
    }, 10);
  })

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      setUpdateItem(!updateItem);
    }, 400);
  }, [sideBar])

  useEffect(() => {
    if (!devFocus && devices) {
      const device = devices.find((d) => d.id.toString() === localStorage.getItem('devFocus'))

      if (device) setDevFocus(device);
      else setDevFocus(devices[0]);
    }
    if (devFocus && devices && devices.length > 0) {
      const device = devices.find((d) => d.id === devFocus.id)
      if (device) {
        let change = false;
        for (let param in devFocus) {
          if (device.hasOwnProperty(param) && devFocus[param] !== device[param]) change = true
        }
        if (change) setDevFocus(device)

        // old code ont check all parametrs
        // let updateDevFocus;
        // if (device.status !== devFocus.status) updateDevFocus = { ...devFocus, status: device.status }
        // if (device.pos[3] !== devFocus.pos[3]) {
        //   if (!updateDevFocus) updateDevFocus = { ...devFocus, pos: device.pos }
        //   else updateDevFocus = { ...updateDevFocus, pos: device.pos }
        // }
        // if (updateDevFocus) setDevFocus(updateDevFocus);

      }
    }
  }, [devices])

  useEffect(() => {
    if (defaultLayout && defaultLayout[1] === '/overview') {
      setLayout(!lockLayout ? calcMaxH(layoutOverviewDefault, heightWindow) : layoutOverviewDefault);
    }
  }, [defaultLayout])

  useEffect(() => {
    if (devFocus) {
      localStorage.setItem('devFocus', devFocus.id)
      // console.log(devFocus);
      if (prevDevFocus && devFocus.id !== prevDevFocus.id) {
        // console.log('device change');
        if (devFocus.status & 1) {
          if (currentRide && !refWs5.current) {
            setCurrentRide(null)
            // setCurrentRideNewData(null)
            // console.log('device change - unregister + getCurrentride');
            WsSend({ method: 'unregister', id: 5 })
            WsSend({ method: 'getCurrentRide', device: devFocus.id, oid: 5 })
            refWs5.current = true;
            loadingCurRideTime.current = setTimeout(() => { setLoadingRide(true) }, 2000);
            // console.log('device change - modify');
            // WsSend({ method: 'modify', id: 5, param: 'device', value: devFocus.id })
          }
          else if (!refWs5.current) {
            // console.log('device  change - getCurrentRide');
            WsSend({ method: 'getCurrentRide', device: devFocus.id, oid: 5 })
            refWs5.current = true;
            loadingCurRideTime.current = setTimeout(() => { setLoadingRide(true) }, 2000);
          }
        }
        if (prevDevFocus.status & 1 && !(devFocus.status & 1)) {
          if (currentRide) {
            setCurrentRide(null);
            refWs5.current = false;
            // setCurrentRideNewData(null)
          }
          // console.log('device  change - unregister');
          WsSend({ method: 'unregister', id: 5 })
        }
      }
      else {
        // console.log('device not change');
        if (devFocus.status & 1 && !currentRide && !refWs5.current) {
          //console.log('device not change - getCurrentRide');
          WsSend({ method: 'getCurrentRide', device: devFocus.id, oid: 5 })
          loadingCurRideTime.current = setTimeout(() => { setLoadingRide(true) }, 2000);
          refWs5.current = true;
        }
        if (!(devFocus.status & 1) && currentRide) {
          // console.log('device not change - unregister');
          if (currentRide) {
            setCurrentRide(null);
            refWs5.current = false;
            // setCurrentRideNewData(null);
          }
          WsSend({ method: 'unregister', id: 5 })
        }
      }
    }
  }, [devFocus])

  const removeWidget = (removeItem) => {
    const res = filterLayouts(removeItem, layout)
    setLayout(res);
    setUiConfig({ ...uiConfig, layoutOverview: res });
  }

  const [layout, setLayout] = useState();
  const [itemMax, setItemMax] = useState();
  const prevItemMax = usePrevious(itemMax);

  useEffect(() => {
    if (!layout) {
      const l = layoutOverview ? layoutOverview : layoutOverviewDefault;
      setLayout(!lockLayout ? calcMaxH(l, heightWindow) : l)
    }
    if (!lockLayout && refRender.current && layout) {
      setTimeout(() => {
        const newLayout = calcMaxH(layout, heightWindow);
        setLayout(newLayout)
      }, 100)
    } else refRender.current = true;
  }, [heightWindow])

  useEffect(() => {
    if (widgetOnDrop) {
      let newLayouts = newLayots(layoutNew(widgetOnDrop), layout);
      setLayout(newLayouts)
      setUiConfig({ ...uiConfig, layoutOverview: newLayouts });
      setTimeout(() => {
        setWidgetOnDrop(null)
      }, 200);
    }
  }, [widgetOnDrop])

  const onFocus = () => {
    let timeNow = Date.now()
    if (timeNow - timelastdata > 900000 || !timelastdata) {
      console.log('last data older 15 minutes');
      WsSend({ method: 'unregister', id: 4 });
      if (currentRide) WsSend({ method: 'unregister', id: 5 });
      setTimeout(() => {
        setDevices(false);
        setCurrentRide(false);
        refWs5.current = false;
      }, 1000);
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [])

  // useEffect(() => {
  //   setTimeout(() => {
  //     const fullScreen = getQuery('fullScreen', location)
  //     if (fullScreen) setItemMax(fullScreen)
  //   }, 100);
  // }, [location.search]);

  useEffect(() => {
    // if (itemMax) setQuery('fullScreen', itemMax, navigate, location)
    // else clearQuery(navigate)
    if (itemMax || prevItemMax) resize()
  }, [itemMax])

  useEffect(() => {
    if (location.pathname.split('/').length > 1) setItemMax(location.pathname.split('/')[2])
    else setItemMax()
  }, [location.pathname]);

  client.onclose = (e) => {
    console.log('WS connection close on overview clear data', e);
    setDevices(false);
    setDevFocus(false);
    setCurrentRide(false);
  }


  useEffect(() => {
    //delete all if switch costumer
    // dev only for check if costumer switch
    if (!dev) {
      setDevices(dev);
      setDevFocus(false);
      setCurrentRide(false);
    }
  }, [costumer])

  if (devices && devices.length > 0 && layout) {
    return (
      <>
        <ResponsiveGridLayout
          className="layout"
          layouts={layout}
          margin={margin}
          breakpoints={breakpoints}
          cols={cols}
          // onBreakpointChange={(e) => { console.log('layout breakpoint', e); }}
          onLayoutChange={(items, layouts) => {
            if (!itemMax) {
              setLayout(layouts);
              setUiConfig({ ...uiConfig, layoutOverview: layouts });
            }
          }}
          isDraggable={!lockLayout && !itemMax}
          isResizable={!lockLayout && !itemMax}
          rowHeight={rowHeight}
          compactType={'vertical'}
          onResizeStop={resize}
          resizeHandles={['se']}
          draggableHandle=".gridHandleOn"
          isDroppable={false}
          style={{ height: '100vh' }}
        >
          {checkMount('MapView', layout) || itemMax === 'MapView' ? <GridItemComponent key='MapView' id={"MapView"} max={itemMax} heightWindow={heightWindow - 20}>
            <div>
              <HeaderGrid name={"MapView"} title={t('map')} height={mapHeight} width={mapWidth} lock={lockLayout} max={itemMax} setMax={setItemMax} remove={removeWidget} resize={resize} force={forceUpdate} mount={checkMount('MapView', layout)} navigate={navigate} location={location} />
              {mapHeight ? <div className={lockLayout || itemMax ? "gridItemL" : 'gridItem'}>
                <MapView
                  devices={devices}
                  setDevices={setDevices}
                  devFocus={devFocus}
                  setDevFocus={setDevFocus}
                  prevDevFocus={prevDevFocus}
                  currentRide={currentRide}
                  height={mapHeight - 24}
                  width={mapWidth}
                  update={updateItem}
                /> </div> : ''}
            </div>
          </GridItemComponent> : <div></div>}

          {checkMount('DevicesList', layout) || itemMax === 'DevicesList' ? <GridItemComponent key="DevicesList" id={"DevicesList"} max={itemMax}>
            <div>
              <HeaderGrid name={"DevicesList"} title={t('devicesList')} height={devicesHeight} width={devicesWidth} lock={lockLayout} max={itemMax} setMax={setItemMax} remove={removeWidget} resize={resize} force={forceUpdate} mount={checkMount('DevicesList', layout)} navigate={navigate} location={location} />
              {devicesHeight ? <div className={lockLayout || itemMax ? "gridItemL" : 'gridItem'}>
                <DevicesView
                  devices={devices}
                  devFocus={devFocus}
                  prevDevFocus={prevDevFocus}
                  setDevFocus={setDevFocus}
                  height={itemMax ? (heightWindow - 50) : devicesHeight - 24}
                  width={devicesWidth}
                  lock={lockLayout} />
              </div> : ''}

            </div>
          </GridItemComponent> : <div></div>}
          {checkMount('DeviceDetail', layout) ? <GridItemComponent key="DeviceDetail" id={"DeviceDetail"} max={itemMax}>
            <div>
              <HeaderGrid name={"DeviceDetail"} title={t('deviceDetail')} height={deviceHeight} width={deviceWidth} lock={lockLayout} max={itemMax} setMax={setItemMax} remove={removeWidget} resize={resize} force={forceUpdate} mount={checkMount('MapView', layout)} />
              {deviceHeight ? <div className={lockLayout || itemMax ? "gridItemL" : 'gridItem'}>
                <DeviceView
                  devFocus={devFocus ? devFocus : devices[0]}
                  height={itemMax ? (lockLayout ? heightWindow - 50 : heightWindow - 90) : deviceHeight - 20}
                  width={deviceWidth}
                  toast={toast} />
              </div> : ''}
            </div>
          </GridItemComponent> : <div></div>}
        </ResponsiveGridLayout>
        <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-gray-100')} modal={true} show={loadingRide} />
        <ToastContainer
          position="bottom-center"
          autoClose={1500}
          pauseOnHover={false}
          theme="light"
          transition={Slide}
          closeOnClick
          hideProgressBar
        />
      </>
    )
  }
  else if (loading.current) return <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} />
  else return <div className="d-flex justify-content-center align-items-center min-vh-100 fs-2 fw-bold text-secondary">{i18n.t('devices.notFind')}</div>
}