import React, { useEffect, useState, useRef } from "react";
import GridTable from '@nadavshaar/react-grid-table';
import { useTranslation } from 'react-i18next';

import { AiOutlineSearch } from 'react-icons/ai'

import { dddDriversColumns, updateColumnsLayout, ColumnVisibility, } from "./DDDViewColumns";
import { dddViewDriversRows } from "./DDDViewRows";
import DDDViewFile from "./DDDViewFile"
import DDDViewListFilesModal from "./DDDViewListFilesModal"
import Loading from "../Loading";

import './DDDViewStyle.css'

export default function DDDViewDrivers({ drivers, files, filesInfo, openFile, setOpenFile, height, setFileDownload, loading }) {
    const { t } = useTranslation('translation');

    const [rows, setRows] = useState([]);
    const [fileInfoOpen, setFileInfoOpen] = useState(false);
    const [filesList, setFileList] = useState(false);
    // const [isAscending, seIsAscending] = useState({ colId: 2, isAsc: true });

    const c = dddDriversColumns(setOpenFile, setFileInfoOpen, setFileList);

    useEffect(() => {
        if (filesInfo) setRows(dddViewDriversRows(filesInfo, drivers))
    }, [filesInfo])


    return (
        <div style={{ height: height + 'px' }}>
            {!loading ? (
                <GridTable
                    style={{ minHeight: 'unset', height: height + 'px' }}
                    columns={c}
                    isLoading={false}
                    pageSize={5000}
                    rows={rows}
                    showSearch={true}
                    showColumnVisibilityManager={false}
                    showRowsInformation={false}
                    isPaginated={false}
                    texts={{
                        search: '',
                        noResults: t('devices.notFind')
                    }}
                    className="DDDViewDrivers"
                    icons={{ search: <AiOutlineSearch /> }}
                    onColumnResizeEnd={({ column }, tableManager) => { updateColumnsLayout(tableManager) }}
                    onColumnReorderEnd={(sortData, tableManager) => { updateColumnsLayout(tableManager) }}
                    // onSortChange={({ colId, isAsc }) => {
                    //     seIsAscending({ colId: colId, isAsc: isAsc })
                    //     localStorage.setItem("ddd-drivers-isAscending", JSON.stringify({ colId: colId, isAsc: isAsc }))
                    // }}
                    components={{ ColumnVisibility }}
                    enableColumnsReorder={false}
                />) : (<Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} className="mt-5" height={height} />)}

            {openFile && fileInfoOpen ? (
                <DDDViewFile
                    openFile={openFile}
                    fileInfoOpen={fileInfoOpen}
                    setOpenFile={setOpenFile}
                />) : null}

            {filesList && files.length > 0 ? (
                <DDDViewListFilesModal
                    files={files}
                    filesList={filesList}
                    setFileList={setFileList}
                    setOpenFile={setOpenFile}
                    openFile={openFile}
                    setFileDownload={setFileDownload}
                />
            ) : null}
        </div>
    )
}