import { parse, getTime, format, parseISO, addSeconds, addMinutes, addHours, max } from 'date-fns'
import i18n from '../../dictionary/i18n';

const reportLine = ({ value }) => {
    if (value) {
        const noGps = value[0] === 'x' ? true : false
        return (
            <div className={`rgt-cell-inner rgt-cell-component ${noGps ? '' : 'fw-bold'}`} title={value}>
                <div className='rgt-text-truncate'>
                    {value}
                </div>
            </div>
        )
    }
}

const time = ({ value }) => {
    if (value) {
        const [time, before] = value.split(';')
        return (
            <div className={`rgt-cell-inner rgt-cell-component`} title={`${time} ${before ? '[' + before + ']' : ''}`}>
                <div className='rgt-text-truncate'>
                    {time}
                </div>
            </div>
        )
    }
}

const sortReport = ({ a, b, isAscending }) => {
    const maxTime = 2147483647000
    let aa, bb
    if (a && a !== '' && b && b !== '') {
        if (a.includes(';')) aa = getTime(parse(a.split(';')[0], 'HH:mm:ss', new Date()));
        else aa = getTime(parse(a, 'dd.MM.yy HH:mm:ss', new Date()));
        if (b.includes(';')) bb = getTime(parse(b.split(';')[0], 'HH:mm:ss', new Date()));
        else bb = getTime(parse(b, 'dd.MM.yy HH:mm:ss', new Date()));
        return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
    }
}

export const deviceReportRawColumns = () => {
    const c = [
        {
            id: 1,
            field: 'time',
            label: i18n.t('devices.time'),
            sortable: true,
            width: '125px',
            resizable: false,
            pinned: true,
            searchable: false,
            cellRenderer: time,
            sort: sortReport
        },
        {
            id: 2,
            field: 'code',
            label: '',
            sortable: false,
            width: '40px',
            resizable: false,
            searchable: false,
        },
        {
            id: 3,
            field: 'param',
            label: i18n.t('devices.param'),
            sortable: false,
            width: 'auto',
            resizable: false,
            searchable: true,
            cellRenderer: reportLine,
        },
    ]
    return c
}