import { client } from "websocket";
import ReconnectingWebSocket from 'reconnecting-websocket';
import package_json from '../../package.json';

let pageview = null;

const messageQueue = [];
let isSending = false;

const processQueue = () => {
    if (isSending || messageQueue.length === 0 || client.readyState !== WebSocket.OPEN) {
        return;
    }

    isSending = true;
    const message = messageQueue.shift();

    client.send(message, () => {
        isSending = false;
        processQueue();
    });

    client.addEventListener('message', (message) => {
        isSending = false;
        processQueue();
    }, { once: true });
};

export const clearWSQueue = () => {
    isSending = false
};

export const WsNew = (d) => {
    // console.log('WsNew', client);
    //WebSocket
    // client = new WebSocket('wss://' + d + '.logiscare.com:443');
    // if (client) client.close()
    if (d === 'ws88' || d === 'ws77') client = new ReconnectingWebSocket('ws://' + d + '.logiscare.com:80', [], { debug: false, connectionTimeout: 10000, minUptime: 8000, });
    else client = new ReconnectingWebSocket('wss://' + d + '.logiscare.com:443', [], { debug: process.env.NODE_ENV !== 'development', connectionTimeout: 10000, minUptime: 8000, });
}

export const WsLogin = (name, password) => {
    console.log('WsLogin successfully');
    let data = {
        method: "login",
        oid: 2,
        username: name,
        password: password,
        version: package_json.version,
        appName: package_json.name,
        platform: navigator.userAgent,
        buildNumber: package_json.buildNumber,
        autoLogin: true,
        packageName: package_json.packageName,
        lang: navigator.language,
        buildTimestamp: package_json.buildTimestamp,
        client: 3
    }
    WsSend(data)
}

export const WsLogout = () => {
    let data = { method: 'logout', oid: 3 }
    WsSend(data)
    localStorage.clear()
    sessionStorage.clear()
    client.close()
    clearWSQueue()
}


export const WsSend = (data) => {
    //if(client.readyState == 3) WsSend('ws99');
    // if (data) {
    //     client.send(JSON.stringify(data))
    // }
    if (data) {
        messageQueue.push(JSON.stringify(data));
        processQueue();
    }
}

export const WsAnal = (message) => {
    // console.log(message.data);
    let rc = JSON.parse(message.data);
    //   console.log(rc);
    if (rc.objects[0]) {
        return rc.objects[0]
    }
    if (rc.operations.length >= 1) {
        return rc.operations
    }

}

//Describe OID for query
//getTimestamp 1
//login 2
//logout 3
//getDevices 4
//getCurrentRide 5
//getRides 6
//getRide 7
//getUser 8
//getFiles (with driver or vehicle)  9
//getFile 10
//search 11
//getFileInfo 12
//getReportRaw 13
//setDevice 14
//getDriverCheck 15
//getDrivers 16
//setRide 17
//getEquipments 18
//getReport 19
//getStats 20
//getFiles(all) 21
//getFences 22
//setFence 23
//getContats 24
//getDownloadsFiles 25
//getMemos 26
//getPool 27
//getExportNode 28
//getExportNode 29 with download
//setDriver 30
//getUsers 31
//createUser 32
//getStatsSelection 33

export const checkModel = (page) => {
    if (page.includes('/overview')) return [4, 5]
    if (page === '/rides') return [6, 16, 13]
    if (page.includes('/tacho')) return [9, 4, 16, 21]
    if (page.includes('/reports')) return [15, 16, 19, 20, 33]
    if (page === '/profile') return [8]
    if (page === '/config/equipments') return [4]
    if (page === '/config/fences') return [22, 24]
    if (page === '/autopujcovna') return [27, 16]
}

export const SetPage = (page) => {
    let unregister = true;
    const pageWithWidget = ['overview', 'reports', 'tacho']
    if (pageview && page.split('/')[1] === pageview.split('/')[1]) unregister = !(pageWithWidget.some(u => u === page.split('/')[1]))
    if (pageview && pageview !== page && unregister) {
        if (checkModel(pageview)) {
            // console.log('unregister', checkModel(pageview));
            checkModel(pageview).map((oid) => {
                WsSend({ method: 'unregister', id: oid });
            })
        }
    }
    pageview = page;
}

// export const WSdataUpdate = (dataNow, Id, atr, newVal) => {
//     const newdevices = dataNow.map((dev, index) => {
//         if (index === Id) {
//             return { ...dev, [atr]: newVal };
//         } else {
//             return dev;
//         }
//     });
//     return newdevices;
// }

//n=number query
export const WSdata = (data, dataNow, n, aux) => {
    if (data) {
        //first messaqe on new query
        if (data.value && (data.id === n || data.oid === n)) return data.value;
        // if (data.value && data.oid === n) return data.value.list;
        //query with update
        if (!aux && data.length > 0) {
            // let updateData = [];
            // if (updateData.length === 0) updateData = dataNow;
            // if (n === 5) console.log('dataNow', dataNow);
            data.forEach((item) => {
                //im not sure if it is good code
                if (item.kind === 'update' && item.object === n) {
                    if (!dataNow) console.log('WS data', item);
                    dataNow = dataNow.map((d, index) => {
                        if (index === item.rowid - 1) {
                            return { ...d, [item.attribute]: item.newValue };
                        } else {
                            return d;
                        }
                    });

                }
                if (item.kind === 'drop' && item.object === n) dataNow.splice(item.oldValue - 1, 1);
                if (item.kind === 'append' && item.object === n) dataNow.splice(item.oldValue - 1, 0, item.newValue);
                if (item.kind === 'replace' && item.object === n) dataNow.splice(item.oldValue - 1, 1, item.newValue);
                if (item.kind === 'change' && item.object === n) dataNow = item.newValue;
            })
            // if (n === 5) console.log('finishupdate',dataNow);
            // console.log(dataNow);
            return dataNow
        }
        if (aux) {
            // console.log(dataNow);
            // console.log(data);
            let res = null;
            data.forEach((item) => {
                if (item.attribute === 'aux' && item.object === n) res = item.newValue;
            })
            return res
        }
        // if (data.length === 1 && data[0].kind === 'update' && data[0].object === n) {
        //     return WSdataUpdate(dataNow, data[0].rowid - 1, data[0].attribute, data[0].newValue)
        // }
    }
}

//all object with list page size aux atd..
export const WSdataList = (data, dataNow, n) => {
    if (data) {
        let dataNowList
        if (dataNow && dataNow.list) dataNowList = dataNow.list;
        //first messaqe on new query
        if (data.value && (data.id === n || data.oid === n)) return data.value;
        // if (data.value && data.oid === n) return data.value.list;
        //query with update
        if (data.length > 0) {
            // let updateData = [];
            // if (updateData.length === 0) updateData = dataNow;
            // if (n === 5) console.log('dataNow', dataNow);
            // console.log(data);
            // console.log('dataNow', dataNow);
            data.forEach((item) => {
                //im not sure if it is good code
                if (!item.attribute) {
                    if (item.kind === 'update' && item.object === n) {
                        dataNowList = dataNowList.map((d, index) => {
                            if (index === item.rowid - 1) {
                                return { ...d, [item.attribute]: item.newValue };
                            } else {
                                return d;
                            }
                        });
                    }
                    // if (item.kind === 'drop' && item.object === n) console.log('dataNowList ', dataNowList, 'item ', item)
                    if (item.kind === 'drop' && item.object === n) dataNowList.splice(item.oldValue - 1, 1);
                    if (item.kind === 'append' && item.object === n) dataNowList.splice(item.oldValue - 1, 0, item.newValue);
                    if (item.kind === 'replace' && item.object === n) dataNowList.splice(item.oldValue - 1, 1, item.newValue)
                    if (item.kind === 'change' && item.object === n) dataNow = item.newValue;

                }
                else {
                    if (item.kind === 'update' && item.object === n) {
                        for (const key in dataNow) {
                            if (dataNow.hasOwnProperty(key)) {
                                if (key === item.attribute) {
                                    dataNow[key] = item.newValue;
                                }
                            }

                            // nevím k čemu to bylo nejspíš kvuli statistice
                            // nevím proč to tu vadilo zapnul jsem to kvuli fences updatu
                            // console.log('dataNowList ', dataNowList);
                            dataNowList = dataNowList.map((d, index) => {
                                if (index === item.rowid - 1) {
                                    return { ...d, [item.attribute]: item.newValue };
                                } else {
                                    return d;
                                }
                            });
                        }
                    }
                }
            })
            // console.log('dataNowList', dataNowList);
            if (dataNowList) dataNow = { ...dataNow, list: dataNowList }
            // console.log('dataNow', dataNow);
            return dataNow
        }
    }
}

export const downloadFromContent = (data, filename, open) => {
    if (!open) {
        const uint8Array = new Uint8Array(data);
        const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
    }
    else {
        const uint8Array = new Uint8Array(data);
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const blobUrl = window.URL.createObjectURL(blob);

        const newTab = window.open(blobUrl, '_blank');
        if (newTab) newTab.focus();
    }
}

export async function loginSmallTalk(code) {
    const loginsave = JSON.parse((localStorage.getItem('login') ? localStorage.getItem('login') : sessionStorage.getItem('login')));
    const domain = () => {
        switch (loginsave.domain) {
            case 'ws99': return 'cj'
            case 'ws00': return 'test'
            case 'ws01': return 'portal'
            case 'ws02': return 'test'
        }
    }

    console.log('jss/login');
    const res = await fetch(`https://${domain()}.logiscare.com/jss/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "username": loginsave.uname,
            "password": loginsave.pass,
            "origin": window.location.origin
        }),
    })
    const data = await res.json();
    if (data.session) {
        const clientId = localStorage.getItem('clientId');
        sessionStorage.setItem('ft_session', data.session)
        window.open(`https://${domain()}.logiscare.com/${code}?ft_session=${data.session}${clientId ? '&clientId=' + clientId: ''} `, "_blank");
    }
}
