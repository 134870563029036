import { lazy } from 'react';

import Login from "./Login";
import Overview from "./Overview/Overview";
import Rides from "./Rides/Rides";
import Mapview from "./Map";
import Tacho from "./Tacho/Tacho";
import Time from "./Time";
import Error from "./Error";
import Profile from "./Profile/Profile"
import Config from "./Config/Config"
import Equipments from "./Config/Equipments"
import Fences from "./Config/Fences"
const Reports = lazy(() => import("./Reports/Reports"));
const CarRental = lazy(() => import("./CarRental/CarRental"));
const Admin = lazy(() => import("./Admin/Admin"));
const SCard = lazy(() => import("./Admin/sCard"));
const AllClients = lazy(() => import("./Admin/AllClients"));
const DDDcheck = lazy(() => import("./Admin/DDDcheck"));
const Menu = lazy(() => import("./Menu/Menu"));
const Drivers = lazy(() => import("./Config/Drivers"));
// const Menu = lazy(() => import('./Menu/Menu').then(module => ({ default: module.Menu })));

export {
	Login,
	Overview,
	Rides,
	Mapview,
	Tacho,
	Time,
	Error,
	Profile,
	Config,
	Equipments,
	Reports,
	Fences,
	CarRental,
	Admin,
	SCard,
	AllClients,
	DDDcheck,
	Menu,
	Drivers
};