import { parseISO, format, isToday, formatDistanceStrict } from 'date-fns'

export const deviceReportRawRows = (r, page, pageSize) => {
    let res = []
    r.list.forEach((f) => {
        const stamp = parseISO(f.stamp)
        const time = isToday(stamp) ? (format(stamp, 'HH:mm:ss') +';'+ formatDistanceStrict(stamp, new Date(), { addSuffix: true })) : (format(stamp, 'dd.MM.yy HH:mm:ss'))
        res.push({
            "id": f.id,
            'time': time,
            'code': f.code,
            'param': f.pars,
        })
    })
    if (r.list.length > 1) res.sort((a, b) => b.id - a.id);
    // fill empty rows via total count rows from server
    const emptyCount = r.count;
    const emptyArray = new Array(Math.max(0, emptyCount)).fill({});
    const startIndex = (page - 1) * pageSize;
    res = emptyArray.slice(0, startIndex).concat(res, emptyArray.slice(startIndex + res.length));
    return res
}