// export const layoutRidesDefault = {
//     lg: [
//         { w: 7, h: 14, x: 0, y: 0, i: "RidesView", minH: 5, minW: 3 },
//         { w: 5, h: 14, x: 7, y: 0, i: "MapView", minH: 5, minW: 3 },
//     ],
//     md: [
//         { w: 5, h: 14, x: 0, y: 0, i: "RidesView", minH: 4, minW: 3 },
//         { w: 5, h: 14, x: 7, y: 0, i: "MapView", minH: 5, minW: 3 },
//     ],
//     sm: [
//         { w: 6, h: 12, x: 0, y: 0, i: "RidesView", minH: 3, minW: 2 },
//         { w: 6, h: 6, x: 0, y: 6, i: "MapView", minH: 3, minW: 3 },
//     ],
//     xs: [
//         { w: 4, h: 10, x: 0, y: 0, i: "RidesView", minH: 2, minW: 2 },
//         { w: 4, h: 5, x: 0, y: 4, i: "MapView", minH: 2, minW: 3 },
//     ]
// }

export const layoutRidesDefault = {
    lg: [
        { w: 14, h: 15, x: 0, y: 10, i: "RidesView", minH: 5, minW: 3 },
        { w: 14, h: 10, x: 0, y: 0, i: "MapView", minH: 5, minW: 3 },
    ],
    md: [
        { w: 10, h: 15, x: 0, y: 10, i: "RidesView", minH: 4, minW: 3 },
        { w: 10, h: 10, x: 0, y: 0, i: "MapView", minH: 5, minW: 3 },
    ],
    sm: [
        { w: 6, h: 15, x: 0, y: 9, i: "RidesView", minH: 3, minW: 2 },
        { w: 6, h: 9, x: 0, y: 0, i: "MapView", minH: 3, minW: 3 },
    ],
    xs: [
        { w: 4, h: 14, x: 0, y: 8, i: "RidesView", minH: 2, minW: 2 },
        { w: 4, h: 8, x: 0, y: 0, i: "MapView", minH: 2, minW: 3 },
    ]
}

export const layoutRidesAll = [
    { w: 7, h: 12, x: 0, y: 0, i: "RidesView", minH: 5, minW: 3 },
    { w: 5, h: 12, x: 7, y: 0, i: "MapView", minH: 5, minW: 3 },
    { w: 7, h: 12, x: 0, y: 0, i: "DeviceReport", minH: 5, minW: 3 },
]

export const layoutNew = (name) => {
    if (name === 'RidesView') return { w: 7, h: 12, x: 0, y: 0, i: "RidesView", minH: 5, minW: 3 }
    if (name === 'MapView') return { w: 5, h: 12, x: 7, y: 0, i: "MapView", minH: 5, minW: 3 }
    if (name === 'DeviceReport') return { w: 7, h: 12, x: 0, y: 0, i: "DeviceReport", minH: 5, minW: 3 }
}
