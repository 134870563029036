import React, { useEffect, useState, useRef } from "react";
import GridTable from '@nadavshaar/react-grid-table';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai'

import { DatePickerComponentFull } from "../DatePickerComponent/DatePickerComponent"
import { newDate, thisWeek } from "../DatePickerComponent/DateSelect";
import { SelectComponentDevices } from "../SelectComponent/SelectComponent"
import { formatDateObjectTime } from "../../components/DatePickerComponent/DatePickerConfig"

import { deviceReportRawRows } from "./DeviceReportRows"
import { deviceReportRawColumns } from "./DeviceReportColumns"
import Loading from "../Loading";

import "./DeviceReportView.css"

// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';


export default function DeviceReportView({ report, devices, devFocus, WsSend, setDevFocus, height, width, max, date, setDate, ridesView, prevDevFocus, loading, setLoading }) {
    const { t } = useTranslation('translation');
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [isAscending, setIsAscending] = useState()
    // const [date, setDate] = useState(false)
    const [search, setSearch] = useState(false)
    const refRender = useRef(null);
    const refWS = useRef(null);
    const refTimeout = useRef(null);

    const [graphData, setGraphData] = useState(false);

    // useEffect(() => {
    //     console.log(report);
    // }, [report])

    if (!refWS.current && !report && devFocus && date.length > 1) {
        WsSend({
            method: 'getReportRaw',
            oid: 13,
            device: devFocus.id,
            stampFrom: new Date(date[0].format(formatDateObjectTime)),
            stampTo: new Date(date[1].format(formatDateObjectTime)),
            page: page,
            pageSize: pageSize
        })
        refWS.current = true;
        // setReport([]);
    }

    const handleNextPage = (nextPage) => {
        setPage(nextPage);
        if (refTimeout.current) clearTimeout(refTimeout.current);
        refTimeout.current = setTimeout(() => {
            WsSend({
                method: 'modify',
                id: 13,
                params: {
                    device: devFocus.id,
                    page: nextPage,
                }
            })
        }, 1000);
    }

    const handlePageSize = (newPageSize) => {
        setPageSize(newPageSize);
        setPage(1)
        if (refTimeout.current) clearTimeout(refTimeout.current);
        refTimeout.current = setTimeout(() => {
            WsSend({
                method: 'modify',
                id: 13,
                params: {
                    device: devFocus.id,
                    pageSize: newPageSize,
                    page: 1
                }
            });
        }, 1000);
    }


    useEffect(() => {
        if (report && report.list && report.list.length > 0) setRows(deviceReportRawRows(report, page, pageSize))
        else setRows(null)
        setLoading(false)
    }, [report])

    useEffect(() => {
        if (!date && sessionStorage.getItem('rides.date')) setDate(newDate(JSON.parse(sessionStorage.getItem('rides.date'))))
        else if (!date) {
            const firstDate = thisWeek();
            if (firstDate.length > 1) {
                setDate(firstDate)
            }
        }
        if (devFocus && date.length > 1) {
            setPage(1)
            if (refRender.current) {
                WsSend({
                    method: 'modify',
                    id: 13,
                    params: {
                        device: devFocus.id,
                        stampFrom: new Date(date[0].format(formatDateObjectTime)),
                        stampTo: new Date(date[1].format(formatDateObjectTime)),
                        page: 1
                    }
                });
                sessionStorage.setItem('rides.date', JSON.stringify([date[0].format(formatDateObjectTime), date[1].format(formatDateObjectTime)]))
            }
            //skip first render where set first date
            refRender.current = true;
        }
    }, [date])

    useEffect(() => {
        if (prevDevFocus && ridesView && devFocus) {
            const prevDevFocusId = prevDevFocus ? prevDevFocus.id : 0;
            if (devFocus.id != prevDevFocusId) {
                WsSend({
                    method: 'modify',
                    id: 13,
                    params: {
                        device: devFocus.id,
                        page: 1
                    }
                })
                setPage(1);
            };
        }
    }, [devFocus])


    // ChartJS.register(
    //     CategoryScale,
    //     LinearScale,
    //     PointElement,
    //     LineElement,
    //     Title,
    //     Tooltip,
    //     Legend
    // );

    let data = false;
    let tmp = false;
    if (report.list && !graphData && tmp) {

        // console.log(extractedValues);

        let parseTmp = report.list.map(s => {
            const inputString = s.pars
            // const inputString = "x 20.03.2024 13:29:46 lat 0.000000 lon 0.000000, 0km/h, course 0, alt 1m, 0s beaconAdv= tmp001=19.52, tmp003=21.37, tmp004=-14.84, tmp005=21.69, tmp006=21.62, tmp008=21.84, tmp009=21.90, tmp011=21.80, tmp015=20.26, tmp018=21.34, tmp019=20.82, tmp020=21.27, tmp022=23.44, tmp024=22.41, tmp026=22.63, tmp032=22.62, tmp035=22.18, tmp037=22.05, tmp039=22.48, tmp040=22.87, tmp046=22.39, tmp048=23.02, tmp058=22.93, tmp060=10.83, count=24";
            // Regulární výraz pro extrakci všech hodnot
            const regex = /(\w+)=(\-?\d+(\.\d+)?)/g;
            let match;
            const extractedValues = {};
            while ((match = regex.exec(inputString)) !== null) {
                // match[1] obsahuje klíč, match[2] obsahuje hodnotu
                extractedValues[match[1]] = match[2];
            }
            const stamp = inputString.match(/(\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}:\d{2})/)[1];
            extractedValues.stamp = stamp;
            if (extractedValues.tmp001 || extractedValues.tmp002 || extractedValues.tmp003) return extractedValues

        })
        parseTmp = parseTmp.filter(f => f !== undefined)
        // console.log(parseTmp);

        // Předpokládáme, že vaše pole hodnot se nazývá dataPoints
        const dataPoints = parseTmp;

        // Vytvoříme prázdné pole pro labels a datasets
        const labels = [];
        const datasets = [];

        // // Projdeme všechny objekty v poli a získáme časový údaj z klíče 'stamp'
        // dataPoints.forEach(dataPoint => {
        //     const stamp = dataPoint['stamp'];
        //     labels.push(stamp); // Přidáme hodnotu 'stamp' do pole labels

        //     const dataValues = [];

        //     // Projdeme každý objekt v poli a získáme hodnoty mimo klíče 'stamp'
        //     for (let key in dataPoint) {
        //         if (key !== 'stamp') {
        //             dataValues.push(parseFloat(dataPoint[key]));
        //         }
        //     }

        //     // Vytvoříme dataset pro každý objekt v poli
        //     datasets.push({
        //         label: stamp, // Použijeme hodnotu 'stamp' jako label
        //         data: dataValues,
        //         borderColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,
        //         backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.5)`,
        //     });
        // });
        // console.log(dataPoints);
        dataPoints.forEach(dataPoint => {
            const stamp = dataPoint['stamp'];
            labels.push(stamp); // Přidáme hodnotu 'stamp' do pole labels
        });

        // Pro každý klíč v každém objektu v poli, vytvoříme odpovídající dataset
        for (let i = 0; i < dataPoints.length; i++) {
            const dataValues = [];

            for (let key in dataPoints[i]) {
                if (key !== 'stamp') {
                    dataValues.push(parseFloat(dataPoints[i][key]));
                }
            }

            datasets.push({
                label: `tmp${i + 1}`,
                data: dataValues,
                borderColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,
                backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.5)`,
            });
        }

        // Vytvoříme dataset pro graf
        // const data = {
        //     labels: labels,
        //     datasets: datasets,
        // };

        // setGraphData(data);
        console.log(labels);
        console.log(datasets);
    }



    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    data = {
        labels: ['3', '4', '5'],
        datasets: [
            {
                label: 'tmp1',
                data: [5, 6, 7],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'tmp2',
                data: [3, 2, 1],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    }

    if (!date && !devFocus && !report.list) return <></>
    else {
        return (
            <div >{!ridesView || !devFocus || max ?
                <div className="d-flex">
                    <SelectComponentDevices
                        devices={devices}
                        devFocus={devFocus}
                        setDevFocus={setDevFocus}
                        WsSend={WsSend}
                        height={height}
                        wsId={13}
                        setPage={setPage}
                        prevDevFocus={prevDevFocus}
                    />
                    <DatePickerComponentFull
                        date={date}
                        setDate={setDate}
                        w={width}
                        h={height}
                        m={max}
                    />
                </div>
                : <></>}
                {!loading && data ? (
                    <GridTable
                        className="DeviceReportRaw"
                        style={{ minHeight: 'unset', height: (height - (!ridesView || !devFocus || max ? 75 : 0)) + 'px' }}
                        columns={deviceReportRawColumns()}
                        isLoading={false}
                        page={page}
                        pageSize={pageSize}
                        pageSizes={[100, 500, 2000, 5000]}
                        rows={rows}
                        showSearch={true}
                        showColumnVisibilityManager={false}
                        onSearchTextChange={e => {
                            if (e.length > 0) setSearch(true)
                            else setSearch(false)
                        }}
                        showRowsInformation={width > 600 || max ? true : false}
                        isPaginated={!search}
                        texts={{
                            search: '',
                            noResults: report && report.list.length === 0 || search ? t('devices.notFind') : t('login.loading'),
                            totalRows: t('tablePaginated.totalRows'),
                            rows: t('tablePaginated.rows'),
                            rowsPerPage: t('tablePaginated.rowsPerPage'),
                            page: t('tablePaginated.page'),
                            of: t('tablePaginated.of'),
                            prev: t('tablePaginated.prev'),
                            next: t('tablePaginated.next')
                        }}
                        icons={{ search: <AiOutlineSearch /> }}
                        onPageChange={nextPage => handleNextPage(nextPage)}
                        onPageSizeChange={newPageSize => handlePageSize(newPageSize)}
                        // onColumnResizeEnd={({ column }, tableManager) => { updateColumnsLayout(tableManager) }}
                        // onColumnReorderEnd={(sortData, tableManager) => { updateColumnsLayout(tableManager) }}
                        onSortChange={({ colId, isAsc }) => {
                            setIsAscending({ colId: colId, isAsc: isAsc })
                        }}
                        // components={{ ColumnVisibility }}
                        enableColumnsReorder={false}
                        sort={isAscending}
                    />
                ) : (<Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} height={height} />)}
            </div>
        )
    }
}