import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Error } from './pages';
import * as Sentry from "@sentry/react";

import './dictionary/i18n';
import packageJson from '../package.json';
const root = ReactDOM.createRoot(document.getElementById('root'));
const page = window.location.pathname !== 'error' ? window.location.pathname + window.location.search : null;

// window.onerror = function (message, source, lineno, colno, error) {
//   console.error('Global error:', { message, source, lineno, colno, error });
// };

// window.addEventListener('unhandledrejection', function (event) {
//   console.error('Unhandled promise rejection:', event.reason);
// });

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://0c9d11cc74c8c9a958fcb735133242e7@o4507819765858304.ingest.de.sentry.io/4507819769397328",
    integrations: [
      Sentry.replayIntegration(),
    ],
    release: `logiscare-portal@${packageJson.version}`,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
        <Route path="/error" element={<Error page={page} />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
