import React, { useState, useContext, useEffect, useRef } from "react";
import { client } from 'websocket';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { generatePassword } from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Form, Button, FloatingLabel, InputGroup, Spinner } from "react-bootstrap";
import { WsNew, WsAnal, WsSend } from "../../scripts/ws";
import { ToastContainer, toast, Slide } from 'react-toastify';

export default function RegistrationCarRental({ show, set }) {
    const navigate = useNavigate();
    const location = useLocation();
    const formData = useRef()
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(undefined);

    if (show && client) {
        client.onmessage = (message) => {
            let data = WsAnal(message)
            console.log('new data RegistrationCarRental', data);
            if (data.value && data.oid === 32) {
                set(false)
                toast.info('Na Váš email byli odeslány přihlašovací údaje')
            }
            else if (data.oid === 32 & !data.value) {
                toast.error('Uživatel již existuje!', { autoClose: 3000 })
            }
        }
    }

    const handleExternalSubmit = () => {
        if (formData.current) {
            if (formData.current.checkValidity()) {
                formData.current.requestSubmit();
            } else {
                formData.current.reportValidity();
            }
        }
    };

    const sendRegistration = () => {
        const email = new FormData(formData.current).get('email')
        if (email.includes('@')) {
            setValid(false);
        } else {
            setValid(true);
            WsSend({
                method: 'createUser',
                oid: 32,
                client: 11598,
                username: 'cr.ap.' + generatePassword(6),
                email: email + '@rozhlas.cz'
            })
        }

        setLoading(true)
    }


    return (<>
        <Modal centered show={show} onShow={() => { if (!client) WsNew('ws01') }} onHide={() => { set(false) }} >
            <Modal.Header closeButton >
                <Modal.Title>Nová registrace</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form ref={formData} onSubmit={(e) => {
                    e.preventDefault(); sendRegistration();
                }}>
                    <InputGroup className="w-100  my-3" >
                        <FloatingLabel controlId="email" label={'E-mail'} >
                            <Form.Control name="email" type="text" placeholder="E-mail" required />
                        </FloatingLabel>
                        <InputGroup.Text>@rozhlas.cz</InputGroup.Text>
                        {valid === false ? <span className="text-danger mx-2 mt-2 text-center"> Doplňte pouze začátek e-mailové adresy,<br/> např. <b>jan.novak</b> pro emailovou adresu <b>jan.novak@rozhlas.cz</b></span> : ''}
                    </InputGroup>
                </Form >
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleExternalSubmit}>Potvrdit</Button>
            </Modal.Footer>
        </Modal>
        <ToastContainer
            position="bottom-center"
            autoClose={false}
            closeButton={true}
            theme="light"
            transition={Slide}
            closeOnClick
        />
    </>
    )
}