import React, { useState, useContext, useRef, useEffect, Suspense, lazy } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Button, Container, Dropdown, DropdownButton, Offcanvas } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import SidebarMenu from 'react-bootstrap-sidebar-menu';
// import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Logo } from "../../images";

import package_json from '../../../package.json';
import { WsLogout, WsSend, } from "../../scripts/ws";
import { layoutOverviewDefault } from "../../pages/Overview/layoutOverview"
import { layoutRidesAll, layoutRidesDefault } from "../../pages/Rides/layoutRides"
import { layoutTachoAll, layoutTachoDefault } from "../../pages/Tacho/layoutTacho"
import { checkName } from "../../pages/gridLayout";
import { UserContext, useWindowSize } from ".."
// import { Logomin } from "../../images";
import { parseISO, format } from 'date-fns';
import { isClient } from "..";
import { renderMenuIcon } from "../../pages/Menu/Menu";
import { checkNavbarItem, checkClientRoles, masterUser } from "../tools";

import { BsSignpost2Fill } from "react-icons/bs";
import { SlLogout, SlUser } from 'react-icons/sl';
import { BiAddToQueue } from 'react-icons/bi';
import { FaBell } from 'react-icons/fa';
import { RiLayoutMasonryFill } from 'react-icons/ri';
import { RxLockClosed, RxLockOpen2, RxReload } from 'react-icons/rx'
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdCarRental, MdDashboardCustomize  } from "react-icons/md";

import './NavBar.scss'

const SelectComponentClients = React.lazy(() => import('../SelectComponent/SelectComponent').then(module => ({ default: module.SelectComponentClients })));
const Avatar = lazy(() => import('react-avatar'));

const NavBar = ({ setLogin, setWidgetOnDrop, setDefaultLayout }) => {
  function logout() {
    WsLogout();
    setLogin(false);
  }

  const { t } = useTranslation('translation', { keyPrefix: 'widget' });
  const userDetails = useContext(UserContext);
  const widthWindow = useWindowSize().width;
  const sideBar = useContext(UserContext).uiConfig.sideBar === false ? false : true;
  const layoutOverview = useContext(UserContext).uiConfig.layoutOverview;
  const layoutRides = useContext(UserContext).uiConfig.layoutRides;
  const layoutTacho = useContext(UserContext).uiConfig.layoutTacho;
  const navbar = useContext(UserContext).uiConfig.navbar;
  // const [ost, setOst] = useState(false);

  const { uiConfig, setUiConfig, setDev, setDevH, setDevR,setDriv, costumer, setCostumer, clientRoles, user } = useContext(UserContext);
  const pathname = useLocation().pathname.split('/');
  const navigate = useNavigate()
  const routerToFP = useRef(useLocation().pathname === '/' ? false : true);
  const [notification, setNotification] = useState(false);
  const login = JSON.parse(localStorage.getItem('login')) ? JSON.parse(localStorage.getItem('login')) : JSON.parse(sessionStorage.getItem('login'))
  const layoutSetting = ['overview', 'rides', 'tacho']

  useEffect(() => {
    // if (isClient('ceskyRozhlas', costumer) ) { //|| isClient('develop', costumer)
    //   if (!navbar.some(n => n.code === 'autopujcovna')) navbar.push({ index: 389, icon: 'MdCarRental', url: '/autopujcovna', code: 'autopujcovna', name: 'Autopůjčovna', oneCostumer: true })
    // }
    if (!routerToFP.current) {
      const navbarChecked = navbar.filter(m => checkClientRoles(clientRoles, checkNavbarItem(m.code, user)))
      routerToFP.current = true;
      if (navbarChecked.length > 0) navigate(navbarChecked[0].url);
    }
  }, [])

  const checkRoleForSearch = (r) => {
    let res = false
    r.forEach(v => {
      if (v === 'iscurator' || v === 'dealer' || v === 'resellers') res = true;
    })
    return res
  }

  const checkMount = (findItem, layouts) => {
    const res = layouts.lg.find(i => {
      return i.i === findItem
    })
    if (res) return true
    else return false
  }

  const chooseLayout = ((page) => {
    if (page === "/overview") {
      return (layoutOverviewDefault.lg.map((i) => {
        const l = layoutOverview ? layoutOverview : layoutOverviewDefault;
        return ({ val: i.i, mounted: checkMount(i.i, l), title: checkName(i.i), storage: "layoutOverview" })
      }))
    }
    else if (page === "/rides") {
      return (layoutRidesAll.map((i) => {
        const l = layoutRides ? layoutRides : layoutRidesDefault;
        return ({ val: i.i, mounted: checkMount(i.i, l), title: checkName(i.i), storage: "layoutRides" })
      }))
    }
    else if (page === "/tacho") {
      return (layoutTachoAll.map((i) => {
        const l = layoutTacho ? layoutTacho : layoutTachoDefault;
        return ({ val: i.i, mounted: checkMount(i.i, l), title: checkName(i.i), storage: "layoutTacho" })
      }))
    }
    else return null
  })

  const layoutWidget = chooseLayout(useLocation().pathname);
  const page = useLocation().pathname;

  const leyoutWidgetAll = (() => {
    if (!layoutWidget) return true
    let res = true;
    layoutWidget.forEach((item) => {
      if (!item.mounted) res = false;
    })
    return res
  })

  const LayoutWidget = () => {
    if (!layoutWidget) return (<></>)
    return layoutWidget.map((item) => {
      if (!item.mounted) {
        return (<Dropdown.Item key={item.val} onClick={() => { setWidgetOnDrop(item.val) }}> <BiAddToQueue style={{ width: '40px' }} />{item.title}</Dropdown.Item>)
      }
    })
  }
  
  return (
    <>
      <SidebarMenu bg="light" variant="light" expand="sm" expanded={sideBar} activeKey={"default_null"} >
        <div className={`logomin cursorpointer ${sideBar ? 'sidebar' : ''}`} onClick={() => navigate('/menu')}>
          <Logo.Min title={"Powered by LOGISCARE  " + package_json.version} />
        </div>
        <SidebarMenu.Collapse onKeyDown={(e => e.stopPropagation())} getScrollValue={270}>
          <SidebarMenu.Header>
            <SidebarMenu.Brand>
              <Container>
                <Logo.Main className="sidebar-logo-header cursorpointer my-1" title={"Powered by LOGISCARE  " + package_json.version} onClick={() => navigate('/menu')} />
              </Container>
            </SidebarMenu.Brand>
            <SidebarMenu.Toggle title={t('panelColapseExpande')} onClick={() => {
              setUiConfig({ ...uiConfig, sideBar: !sideBar })
            }} />

          </SidebarMenu.Header>
          {checkRoleForSearch(userDetails.clientGroups) ? (
            <Suspense>
              <SelectComponentClients
                client={userDetails.costumer}
                WsSend={WsSend}
                setDev={setDev}
                setDevH={setDevH}
                setDevR={setDevR}
                setDriv={setDriv}
                setClient={setCostumer}
                sideBar={widthWindow > 577 && sideBar ? true : false}
              />
            </Suspense>) : null}
          <div className={`sidebar-menu-maindiv ${sideBar ? 'sideBarShow' : ''} ${checkRoleForSearch(userDetails.clientGroups) ? 'selectClient' : ''} ${masterUser.includes(login.uname) ? 'selectServer' : ''}`} >
            {/* <hr className="dropdown-divider" role="separator"></hr> */}

            <SidebarMenu.Body className="mt-1">
              <SidebarMenu.Nav>

                <LinkContainer to="/menu">
                  <SidebarMenu.Nav.Link eventKey="menu" className="btn btn-light d-flex">
                    <OverlayTrigger placement="right" overlay={(props) => sideBar ? <></> : <Tooltip {...props}>{t('menu')}</Tooltip>}>
                      <SidebarMenu.Nav.Icon className='fs-4'> <MdDashboardCustomize  /></SidebarMenu.Nav.Icon>
                    </OverlayTrigger>
                    <SidebarMenu.Nav.Title>{t('menu')}</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </LinkContainer>
                {/* || isClient('develop', costumer) */}
                {isClient('ceskyRozhlas', costumer) ? <LinkContainer to="/autopujcovna" className="btn btn-light">
                  <SidebarMenu.Nav.Link eventKey="autopujcovna" className="btn btn-light d-flex">
                    <SidebarMenu.Nav.Icon className='fs-4' ><MdCarRental /></SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Autopůjčovna</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </LinkContainer> : <></>}

                {navbar.map(m => {
                  if (checkClientRoles(clientRoles, checkNavbarItem(m.code, user))) return (
                    <LinkContainer key={m.code} to={m.url}>
                      <SidebarMenu.Nav.Link eventKey={m.code} className="btn btn-light d-flex align-items-center">
                        <OverlayTrigger placement="right" overlay={(props) => sideBar ? <></> : <Tooltip {...props}>{m.name ? m.name : t(m.code)}</Tooltip>}>
                          <SidebarMenu.Nav.Icon className='fs-4'>{renderMenuIcon(m.icon)}</SidebarMenu.Nav.Icon>
                        </OverlayTrigger>
                        <SidebarMenu.Nav.Title className="text-no-wrap">{m.name ? m.name : t(m.code)}</SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </LinkContainer>
                  )
                })}

                {/* link with submenu only test*/}
                {/* <SidebarMenu.Sub expanded={ost}>
                  <LinkContainer to="/overview" className="btn btn-light">
                    <SidebarMenu.Nav.Link eventKey="over" className="btn btn-light d-flex" style={{ alignItems: 'center' }}>
                      <SidebarMenu.Nav.Icon className='fs-4' ><FaCar /></SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>aaa</SidebarMenu.Nav.Title>
                      <SidebarMenu.Nav.Icon onClick={() => setOst(!ost)} className="float-end ms-4"  ><RiArrowDropRightLine style={ost ? { transform: 'rotate(90deg)', transition: 'transform 0.3s ease', marginBottom: '5px' } : { transform: 'rotate(0deg)', transition: 'transform 0.3 ease', marginBottom: '5px' }} /></SidebarMenu.Nav.Icon>
                    </SidebarMenu.Nav.Link>
                  </LinkContainer>
                  <SidebarMenu.Sub.Collapse>
                    <LinkContainer to="/map">
                      <SidebarMenu.Nav.Link eventKey="map" className="btn btn-light d-flex">
                        <SidebarMenu.Nav.Icon className='fs-4'><FaRoute /></SidebarMenu.Nav.Icon>
                        <SidebarMenu.Nav.Title>Mapa</SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </LinkContainer>
                  </SidebarMenu.Sub.Collapse>
                </SidebarMenu.Sub> */}

              </SidebarMenu.Nav>
            </SidebarMenu.Body>
            <div className="m-auto"></div>
            <SidebarMenu.Footer className="sidebar-menu-body d-table">
              <hr className="dropdown-divider" role="separator"></hr>
              {layoutSetting.some(l => l === pathname[1]) ?
                <>
                  <DropdownButton
                    id="dropdown-button-navbar"
                    drop="up-centered"
                    variant="light"
                    title={<>
                      <span className="footer-button-icon">
                        <RiLayoutMasonryFill className="fs-4 icon-navbar-dropdown" title={t('display')} />
                      </span>
                      <span className="sidebar-menu-nav-title">
                        {sideBar ? t('display') : ''}
                      </span>
                    </>}
                  >
                    <LayoutWidget />
                    {leyoutWidgetAll() ? <></> : <Dropdown.Divider />}
                    <Dropdown.Item onClick={() => {
                      setDefaultLayout([Math.random(), page]);
                      // setUiConfig({ ...uiConfig, lockLayout: false });
                    }}>
                      <RxReload
                        style={{ width: '40px' }}
                      /> {t('resetDefault')}
                    </Dropdown.Item>
                    <Dropdown.Item active={false}
                      onClick={() => {
                        const lock = { ...uiConfig.lockLayout, [pathname[1]]: !userDetails.lockLayout[pathname[1]] }
                        setUiConfig({ ...uiConfig, lockLayout: lock })
                      }}
                    >
                      {userDetails.lockLayout[pathname[1]] ? <RxLockOpen2 style={{ width: '40px' }} /> : <RxLockClosed style={{ width: '40px' }} />}
                      {userDetails.lockLayout[pathname[1]] ? t('unlock') : t('lock')}
                    </Dropdown.Item>
                  </DropdownButton>
                </>
                : <></>}

              <Button variant="light" className="d-flex w-100 footer-button-navbar" title={t('notification')} onClick={() => { setNotification(true); WsSend({ method: 'getMemos', oid: 26, seen: null, pageSize: 10, page: 1 }) }}>
                <span className="footer-button-icon">
                  <FaBell className="fs-4 icon-navbar-dropdown" />
                </span>
                <span className="sidebar-menu-nav-title">
                  {sideBar ? t('notification') : ''}
                </span>
              </Button>

              <DropdownButton
                id="dropdown-button-navbar"
                drop="up-centered"
                variant="light"
                title={<>
                  <span className="footer-button-icon">
                    <Suspense>
                      <Avatar size="30" textSizeRatio={1.75} maxInitials={1} round={true} name={userDetails.user.name} />
                    </Suspense>
                  </span>
                  <span className="sidebar-menu-nav-title">
                    {sideBar ? userDetails.user.name : ''}
                  </span>
                </>}
              >
                <Dropdown.Item onClick={logout} >
                  <SlLogout style={{ width: '40px' }} />
                  {t('logout')}
                </Dropdown.Item>
                <LinkContainer to="/profile">
                  <Dropdown.Item>
                    <SlUser style={{ width: '40px' }} />
                    {t('profile')}
                  </Dropdown.Item>
                </LinkContainer>
              </DropdownButton>
            </SidebarMenu.Footer>
          </div>
        </SidebarMenu.Collapse>
      </SidebarMenu >

      <Offcanvas show={notification} onHide={() => { setNotification(false); WsSend({ method: 'unregister', id: 26 }) }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t('notification')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {userDetails.notification && userDetails.notification.list.length > 0 ? (userDetails.notification.list.map(n => {
            return <div key={n.id} className="bg-white d-flex mb-3 px-3 py-2 rounded-3 shadow">
              <div className="me-3 mt-2"><IoInformationCircleOutline className="fs-3 text-secondary" /></div>
              <div className="w-100">
                <div className="fw-bold">{n.subject}</div>
                <div >{n.body}</div>
                <div className="fs-7 text-secondary text-end mt-1">{format(parseISO(n.stamp), 'dd.MM.yyyy HH:mm')}</div>
              </div>
            </div>
          })) : (<div className="text-center">
            <FaBell className="fs-1" />
            <div>{t('notificationEmpty')}</div>
          </div>)}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default NavBar;