import { listMenu } from "../pages/Menu/listMenu";

export const uiConfigDefault = () => {
    let menuDefault = [];
    listMenu.forEach(m => { menuDefault.push({ code: m.code, icon: m.icon, index: m.index, url: m.url }) })
    return (
        {
            sideBar: true,
            lockLayout: { overview: false, rides: false, tacho: false },
            datePickerTime: false,
            map: { layer: 0, animation: true, center: true, cluster: true },
            editorRides: false,
            navbar: menuDefault

        }
    )
}

// merge two object and if some miss in first add from second
export const mergeObjects = (obj1, obj2) => {
    const mergedObj = { ...obj1 };

    for (const key in obj2) {
        if (!(key in mergedObj)) {
            mergedObj[key] = obj2[key];
        }
    }

    return mergedObj;
}
