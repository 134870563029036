import React, { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Form, Accordion } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { client } from 'websocket';
import GridTable from '@nadavshaar/react-grid-table';
import FenceModal from './FenceModal'
import { ToastContainer, toast, Slide } from 'react-toastify';
import Loading from "../../components/Loading";
import { Transition } from 'react-transition-group';
import { normalizeText } from "../../components/tools";

import { IoIosArrowBack } from "react-icons/io";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { UserContext, isBrand } from "../../components";
import { FilterComponentDevices } from '../../components/SelectComponent/SelectComponent'

import { iconKind } from "../../images/kind"
import { CarsGroup } from "../../images";

import "./Config.css"

export default function Fences() {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'fences' });
    const { clientFilters, devR, setDevR, setNotification, costumer, uiConfig } = useContext(UserContext);
    const userDetails = useContext(UserContext);
    const [contacts, setContacts] = useState(false);
    const [fencesData, setFencesData] = useState(false);
    const [fencesDataWithFilter, setFencesDataWithFilter] = useState(false);
    const [fencesBlocks, setFencesBlocks] = useState(false);
    const [loadingSetFence, setLoadingSetFence] = useState(false);
    const [devices, setDevices] = useState(devR);
    const [openRow, setOpenRow] = useState(false);
    const [filterDevice, setFilterDevice] = useState();
    const [openEventt, setOpenEventt] = useState();

    const refWS22 = useRef(null);
    const refWS4 = useRef(null);
    const refWS23 = useRef(null);
    const refWS24 = useRef(null);
    const test = useRef(null);
    const navigate = useNavigate();

    if (!contacts && !refWS24.current) {
        refWS24.current = true;
        WsSend({ method: 'getContacts', oid: 24 })
    }

    if (!fencesData && !refWS22.current) {
        refWS22.current = true;
        WsSend({ method: 'getFences', oid: 22, type: [12, 29, 34] })
    }

    if (!devices && !refWS4.current) {
        refWS4.current = true;
        WsSend({ method: 'getDevices', oid: 4, restriction: 'view_report' })
    }

    client.onmessage = (message) => {
        let data = WsAnal(message)
        //  console.log('new data fences', data);

        if (data) {
            // update message
            if (data.length > 0) {
                setFencesData(WSdataList(data, fencesData, 22));
                setContacts(WSdata(data, contacts, 24))
            }

            // first new message from query
            else {
                if (data) {
                    if (data.id === 4) {
                        const res = WSdata(data, null, 4).list;
                        setDevices(res);
                        setDevR(res);
                    }
                    if (data.id === 22) setFencesData(WSdata(data, null, 22));
                    if (data.id === 24) setContacts(WSdata(data, null, 24).list);
                    if (data.oid && data.oid === 23) {
                        refWS23.current = true;
                        setOpenRow(false);
                        setLoadingSetFence(false);
                        setTimeout(() => {
                            toast.success(t('toastSuccess'))
                        }, 200);
                    }
                    if (data.id === 26) setNotification(WSdata(data, null, 26));
                }

            }
        }
    }


    useEffect(() => {
        if (devices && refWS4.current) WsSend({ method: 'unregister', id: 4 })
    }, [devices])

    useEffect(() => {
        if (filterDevice) {
            const devValue = filterDevice.value < 0 ? (filterDevice.value * (-1) + 1000000) : filterDevice.value;
            const res = fencesData.list.filter(f => f.param === devValue)
            setFencesDataWithFilter({ ...fencesData, list: res })
        }
        else setFencesDataWithFilter(fencesData)
    }, [filterDevice, fencesData])

    useEffect(() => {
        if (fencesDataWithFilter && fencesDataWithFilter.list.length > 0) {
            let res = {}
            fencesDataWithFilter.list.forEach(f => {
                if (!res[f.eventt]) res[f.eventt] = [f]
                else res[f.eventt].push(f)
            })

            // zde se vymaže aby se zobrazovalo na začátku pole
            if (res[2105]) {
                let temp = res[2105];
                delete res[2105];
                res = { 5: temp, ...res };
            }
            setFencesBlocks(res)
        }
    }, [fencesDataWithFilter])

    const renderDevice = ({ value }) => {
        if (value < 1000000) {
            const dev = devices.find(i => i.id === parseInt(value));
            if (dev) return (
                <div className='rgt-cell-inner rgt-cell-component'>
                    <span className='rgt-text-truncate d-flex align-items-center'>
                        <span className="icon" style={{ minWidth: '50px' }}>
                            {iconKind(dev, true)}
                        </span>
                        <div className="d-flex flex-row">
                            <span>{dev.name}</span>
                            <span className="device-plate ms-2">{dev.plate}</span>
                        </div>
                    </span>
                </div>
            )
        }
        else {
            const valueFilter = parseInt(value) - 1000000
            const filter = clientFilters.find(f => f.id === valueFilter)
            if (filter) return (
                <div className='rgt-cell-inner rgt-cell-component'>
                    <span className='rgt-text-truncate d-flex align-items-center'>
                        <span>
                            {<CarsGroup height={20} className="ms-2 me-3" />}
                        </span>
                        <div className="d-flex flex-row">
                            <span>{filter.name}</span>
                        </div>
                    </span>
                </div>
            )
            else if (parseInt(value) === 1000000) return (
                <div className='rgt-cell-inner rgt-cell-component'>
                    <span className='rgt-text-truncate d-flex align-items-center'>
                        <span>
                            {<CarsGroup height={20} className="ms-2 me-3" />}
                        </span>
                        <div className="d-flex flex-row">
                            <span>{t('filterAll')}</span>
                        </div>
                    </span>
                </div>
            )
        }
    }

    const switcher = ({ value }) => {
        const checked = value === 'true' ? true : false;
        return (
            <div className="text-center ">
                <Form.Check
                    className="cursorpointer"
                    type="switch"
                    checked={checked}
                    disabled
                />
            </div>
        )
    }

    const getBitEvenett1602 = (aux1, b) => {
        return (((aux1 >> b) & 0x1)) ? true : false
    }


    const getRows = ((fences) => {
        let res = fences.map((f) => {
            const contact = contacts.find(i => i.id === f.contact);
            const sensor = f.eventt === 213 ? fenceEventt213Aux.filter(c => c.value === f.aux1)[0] : null
            if (devices.some(d => d.id === f.param) || f.param >= 1000000) return ({
                'eventt': f.eventt,
                'id': f.id,
                'type': f.eventt !== 213 ? t('type' + f.aux1) : 'sensors',
                'aux1': f.aux1,
                'device': f.param,
                // 'contact': contact.name ? contact.name : (t('contacType_' + contact.code) + contact.text),
                'contact': contact ? (contact.contactt === 29 ? (contact.dbuser ? userDetails.user.name : contact.ref) : contact.text) : f.contacttext,
                'contactId': f.contact,
                'isactive': f.isactive,
                'day': f.day,
                'ranges': f.ranges,
                'sensor': sensor ? sensor.label : null,
                'sensorValue': f.ranges.length > 0 && sensor ? (f.ranges.length === 20 ? (f.ranges[0] + sensor.current + ' - ' + f.ranges[1] + sensor.current) : f.ranges[1] + ' ' + sensor.current) : null,
                'ign_on': f.aux1 ? getBitEvenett1602(f.aux1, 16) : null,
                'ign_off': f.aux1 ? getBitEvenett1602(f.aux1, 0) : null,
                'alternative_ride': f.aux1 ? getBitEvenett1602(f.aux1, 17) : null,
                'alarm': f.aux1 ? getBitEvenett1602(f.aux1, 18) : null,
                'manipulation': f.aux1 ? getBitEvenett1602(f.aux1, 25) : null,
                'towing': f.aux1 ? getBitEvenett1602(f.aux1, 26) : null,
                'green_drive': f.aux1 ? getBitEvenett1602(f.aux1, 27) : null,
                'crash': f.aux1 ? getBitEvenett1602(f.aux1, 31) : null,
                'sos': f.aux1 ? getBitEvenett1602(f.aux1, 29) : null,
            })
        })
        return res.filter(r => r !== undefined)
    })

    const fenceEventt213Aux = [
        { value: 246, id: 'age1', current: 's', label: i18n.t('devices.age') },
        { value: 2, id: 'pwr1', current: 'mV', label: i18n.t('devices.pwr1') },
        { value: 3, id: 'pwr2', current: 'mV', label: i18n.t('devices.pwr2') },
        { value: 8, id: 'bat', current: '%', label: i18n.t('devices.bat') },
        { value: 10, id: 'tmp0', current: '°C', label: i18n.t('devices.tmp0') },
        { value: 11, id: 'tmp1', current: '°C', label: i18n.t('devices.temperature') + ' ' + 1 },
        { value: 12, id: 'tmp2', current: '°C', label: i18n.t('devices.temperature') + ' ' + 2 },
        { value: 13, id: 'tmp3', current: '°C', label: i18n.t('devices.temperature') + ' ' + 3 },
        { value: 14, id: 'tmp4', current: '°C', label: i18n.t('devices.temperature') + ' ' + 4 },
        { value: 15, id: 'tmp5', current: '°C', label: i18n.t('devices.temperature') + ' ' + 5 },
        { value: 16, id: 'tmp6', current: '°C', label: i18n.t('devices.temperature') + ' ' + 6 },
        { value: 17, id: 'tmp7', current: '°C', label: i18n.t('devices.temperature') + ' ' + 7 },
        { value: 18, id: 'tmp8', current: '°C', label: i18n.t('devices.temperature') + ' ' + 8 },
        { value: 251, id: 'rpm', current: 'rpm', label: i18n.t('devices.rpm') }
    ]

    const getColumns = ((eventt) => {
        return [
            {
                id: 0,
                field: 'type',
                label: t('type'),
                sortable: false,
                width: 'auto',
                editable: false,
                visible: (eventt === 213 || eventt === 1602) ? false : true
            },
            {
                id: 1,
                field: 'device',
                label: t('device'),
                width: 'auto',
                cellRenderer: renderDevice,
                editable: false,
                sort: (({ a, b, isAscending }) => {
                    const aa = a < 1000000 ? normalizeText(devices.find(i => i.id === parseInt(a)).name):'a';
                    const bb = b < 1000000 ?normalizeText(devices.find(i => i.id === parseInt(b)).name): 'a';
                    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0)
                })
            },
            {
                id: 2,
                field: 'sensor',
                label: t('sensor'),
                width: 'auto',
                editable: false,
                visible: eventt === 213 ? true : false
            },
            {
                id: 3,
                field: 'sensorValue',
                label: t('sensorValue'),
                width: 'auto',
                editable: false,
                visible: eventt === 213 ? true : false
            },
            {
                id: 4,
                field: 'contact',
                label: (eventt === 213 || eventt === 1602) ? t('contact') : t('contact_email'),
                width: 'auto',

            },
            {
                id: 5,
                field: 'day',
                label: t('event2102Day'),
                width: 'auto',
                editable: false,
                visible: eventt === 2102 ? true : false
            },
            {
                id: 6,
                field: 'isactive',
                label: t('isactive'),
                cellRenderer: switcher,
                width: 'auto',
                editable: false
            },
            {
                id: 7,
                field: 'ign_on',
                label: t('ign_on'),
                width: '75px',
                cellRenderer: switcher,
                editable: false,
                visible: eventt === 1602 ? true : false
            },
            {
                id: 8,
                field: 'ign_off',
                label: t('ign_off'),
                width: '75px',
                cellRenderer: switcher,
                editable: false,
                visible: eventt === 1602 ? true : false
            },
            {
                id: 9,
                field: 'alternative_ride',
                label: t('alternative_ride'),
                width: '75px',
                cellRenderer: switcher,
                editable: false,
                visible: eventt === 1602 && !isBrand('alfakomtemp') ? true : false
            },
            {
                id: 10,
                field: 'alarm',
                label: t('alarm'),
                width: '75px',
                cellRenderer: switcher,
                editable: false,
                visible: eventt === 1602 && !isBrand('alfakomtemp') ? true : false
            },
            {
                id: 11,
                field: 'manipulation',
                label: t('manipulation'),
                width: '75px',
                cellRenderer: switcher,
                editable: false,
                visible: eventt === 1602 && !isBrand('alfakomtemp') ? true : false
            },
            {
                id: 12,
                field: 'towing',
                label: t('towing'),
                width: '75px',
                cellRenderer: switcher,
                editable: false,
                visible: eventt === 1602 && !isBrand('alfakomtemp') ? true : false
            },
            {
                id: 13,
                field: 'green_drive',
                label: t('green_drive'),
                width: '75px',
                cellRenderer: switcher,
                editable: false,
                visible: eventt === 1602 && !isBrand('alfakomtemp') ? true : false
            },
            {
                id: 14,
                field: 'crash',
                label: t('crash'),
                width: '75px',
                cellRenderer: switcher,
                editable: false,
                visible: eventt === 1602 && !isBrand('alfakomtemp') ? true : false
            },
            {
                id: 15,
                field: 'sos',
                label: t('sos'),
                width: '75px',
                cellRenderer: switcher,
                editable: false,
                visible: eventt === 1602 && !isBrand('alfakomtemp') ? true : false
            },
            {
                id: 'my-buttons-column',
                width: 'max-content',
                pinned: true,
                sortable: false,
                resizable: false
            }
        ]
    })

    function TableFances({ eventt, fences }) {
        if (eventt === 2105 || eventt === 2101 || eventt === 2102 || eventt === 213 || eventt === 1602) {
            let namesDevice = [];
            // if (!openEventt || !openEventt.includes(eventt)) {
            fences.forEach(f => {
                const dev = devices.filter(d => d.id === f.param);
                if (dev.length > 0 && !namesDevice.some(i => i === dev[0].name)) namesDevice.push(dev[0].name)
                const fil = clientFilters.filter(fil => (parseInt(fil.id) + 1000000) === f.param)
                if (fil.length > 0 && !namesDevice.some(i => i === fil[0].name)) namesDevice.push(fil[0].name)
                if (f.param === 1000000 && !namesDevice.some(i => i === t('filterAll'))) namesDevice.push(t('filterAll'))
            })
            namesDevice.sort()
            const show = !openEventt || !openEventt.includes(eventt);

            return (
                <Accordion.Item key={eventt} eventKey={eventt}>
                    <Accordion.Header>
                        {<div className="my-2">
                            <div className="fw-bold">{t('eventt' + eventt)}</div>
                            <div style={{ opacity: show ? 1 : 0 }}>{namesDevice.join(', ')}</div>
                        </div>}
                    </Accordion.Header>
                    <Accordion.Body className="">
                        <GridTable
                            style={{ minHeight: 'unset' }}
                            className={'fences'}
                            columns={getColumns(eventt)}
                            rows={getRows(fences)}
                            showSearch={false}
                            showColumnVisibilityManager={false}
                            showRowsInformation={false}
                            isPaginated={false}
                            enableColumnsReorder={false}
                            // sort={{ colId: 1, isAsc: true }}
                            isVirtualScroll={false}
                            onRowClick={({ data }) => {
                                setOpenRow(data);
                            }}
                        />
                    </Accordion.Body>
                </Accordion.Item>

            )
        }
    }

    useEffect(() => {
        //delete all if switch costumer
        if (!devR && refWS22.current && refWS24.current) {
            refWS4.current = false;
            refWS22.current = false;
            refWS24.current = false;
            setFencesData(false);
            setContacts(false);
            setDevices(false);
            setFilterDevice(false);
            console.log('costumer change delete data from Fences');
        }
    }, [costumer])

    if (devices && fencesData) {
        return (
            <div className="bg-white d-flex align-items-center justify-content-center config" style={{ minHeight: '100vh' }}>
                <div className={`d-flex flex-column align-items-center shadow mb-5 bg-body rounded col-12 col-lg-11`}>
                    <div className="d-flex w-100 align-items-center">
                        <div className="back ms-4">
                            <IoIosArrowBack className="fs-3 text-secondary cursorpointer" onClick={() => navigate(-1)} />
                        </div>
                        <div className="d-flex flex-column align-items-center ms-4">
                            <h3 className="my-4">{i18n.t('widget.fences')}</h3>
                        </div>
                        <div className="m-auto"></div>

                        {fencesData.list.length > 0 ?
                            <div className="me-4">
                                <FilterComponentDevices
                                    devices={devices}
                                    filters={clientFilters}
                                    filterDevice={null}
                                    setFilterDevice={setFilterDevice}
                                    height={400}
                                    style={{ cursor: 'pointer', zIndex: 1, position: 'relative' }}
                                />
                            </div> : <></>}

                        <Button onClick={() => { setOpenRow({ aux1: null, contact: null, contactId: null, device: null, eventt: null, id: null, isactive: true, type: null, ranges: [-1, 15] }) }} variant="outline-secondary me-5" style={{ height: '3rem' }}>{t('createNew')} <BsFillPlusCircleFill className="fs-5 ms-2 mb-1" /></Button>
                    </div>
                    <div className="w-100" >
                        {fencesDataWithFilter && fencesDataWithFilter.list.length > 0 && contacts ?
                            <Accordion alwaysOpen defaultActiveKey={[]} onSelect={(e) => { setTimeout(() => { setOpenEventt(e) }, 400); }}>
                                {Object.entries(fencesBlocks).map(([eventt, fencesArray]) => {
                                    return <TableFances key={'Table' + eventt} eventt={parseInt(eventt) === 5 ? 2105 : parseInt(eventt)} fences={fencesArray} />
                                })}
                            </Accordion> : <h2 className="text-secondary text-center my-5">{t('noFence')}</h2>
                        }
                    </div>
                </div>
                <FenceModal
                    fence={openRow}
                    setFence={setOpenRow}
                    devices={devices}
                    contats={contacts}
                    loadingSetFence={loadingSetFence}
                    setLoadingSetFence={setLoadingSetFence}
                    filters={clientFilters}
                    utexts={fencesData.aux}
                />
                <ToastContainer
                    position="bottom-center"
                    autoClose={1500}
                    pauseOnHover={false}
                    theme="light"
                    transition={Slide}
                    closeOnClick
                    hideProgressBar
                />
            </div >
        )

    } else return <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} />
}