import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import VectorTileLayer from 'ol/layer/VectorTile.js';
// import { Style, Icon, Text, Fill, Stroke, Circle as CircleStyle, RegularShape } from "ol/style";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import LineString from 'ol/geom/LineString';
import { defaults as defaultControls, ScaleLine } from 'ol/control';
import Cluster from 'ol/source/Cluster';
import Overlay from 'ol/Overlay';
import LayerPopup from 'ol-ext/control/LayerPopup'
import Path from 'ol-ext/featureanimation/Path';
import { linear } from 'ol/easing';

import "./MapView.css";
import { UserContext } from "../../components/userDetails";
import { miliToLonLat, centerall2, center, nativeBounds2, isOnScreen, markerAt, allObjectOnScreen, flashPulse, PopupDevice, GroupDevice, TitleLine, allRidesOnScreen } from "./mapFunction"
import { layers, cLusterStyle, deviceStyle, pointStartStyle, routeStyle, pointStoptStyle, routeStyleFocus, directionStyle, pointGreenDrivingStyle, layersTrafic, pointFuelingStyle } from './mapStyle';
import { WsSend } from '../../scripts/ws';

function MapView({ devices, setDevices, height, width, update, devFocus, setDevFocus, currentRide, ridesPoints, prevDevFocus, ridesIdsFocus, fuelingPoint }) {

  const { t } = useTranslation('translation', { keyPrefix: 'maps' });
  const [map, setMap] = useState();
  //const [layerMarkers, setLayerMarkers] = useState()
  const [markerSource, setMarkerSource] = useState(); //device source
  const [vectorSource, setVectorSource] = useState(); //rides source
  const [layerVectors, setLayerVectors] = useState(); //rides Layer
  const [greenDrivingSource, setGreenDrivingSource] = useState(); //warning green event source
  const [ridesDirectionSource, setRidesDirectionSource] = useState(); //rides direction source
  const [fuelingPointSource, setfuelingPointSource] = useState(); //fuelingPoint source
  const mapElement = useRef();
  const uiConfig = useContext(UserContext).uiConfig;
  const { clientLayers, user } = useContext(UserContext);
  const refUiConfig = useRef();
  const [zoomEnd, setzoomEnd] = useState(false);
  const [idAnimBef, setIdAnimBef] = useState(false);
  const refAnimeEnableMem = useRef(uiConfig.map.animation);
  const refAnimeEnable = useRef(uiConfig.map.animation);
  const refCenterEnable = useRef(uiConfig.map.center);
  const refClusterEnable = useRef(uiConfig.map.cluster)
  const refTitleObject = useRef(uiConfig.map.title);
  const refCluster = useRef(null)
  const overlayRef = useRef(null);
  const popupRef = useRef(null);
  const popupOpen = useRef(null);
  const [popup, setPopup] = useState(null);
  const contentRef = useRef(null);
  const LayerSwitcher = useRef(null)
  const runAnime = useRef(false);
  const idAnim = useRef(false);
  const featureAnime = useRef();
  const firstZoom = useRef(true);
  const animeTimeBreak = useRef();
  const allRidesOnMap = useRef(null);
  const dzoom = useRef(user.dzoom ? parseInt(user.dzoom) : 11);
  const firstPulseAnime = useRef(true);
  const trafficLayer = useRef();

  const startPointFeature = useRef(false);
  const routeFeature = useRef(false);
  const devFeature = useRef(false);
  const routeAnime = useRef(false);

  const { setUiConfig } = useContext(UserContext);
  function focusOn() {
    // console.log('focus');
    if (refAnimeEnableMem.current && !refAnimeEnable.current) {
      //console.log('focus and animation enable');
      refAnimeEnable.current = true;
    }
    // if (featureAnime.current && lastTimeAnime.current && Date.now() - lastTimeAnime.current > 60000) {
    //   console.log('Focus then 1 minute stoped animation and clear id');
    //   featureAnime.current.stop();
    //   featureAnime.current = null;
    //   idAnim.current = null;
    //   setIdAnimBef(false);
    // }
  }


  useEffect(() => {
    refUiConfig.current = uiConfig;
  }, [uiConfig])

  //temp
  // if (map) console.log(map.getView().getZoom());

  // create state ref that can be accessed in OpenLayers onclick callback function
  //  https://stackoverflow.com/a/60643670
  const mapRef = useRef()
  mapRef.current = map

  // initialize map on first render
  useEffect(() => {
    const initialMarkerSource = new VectorSource();
    const initialVectorSource = new VectorSource();
    const initialRidesDirectionSource = new VectorSource();
    // const initalLayerMarkers = new VectorLayer({ source: initialMarkerSource})
    const initialClusterSource = new Cluster({ distance: refClusterEnable.current ? 30 : 0, source: initialMarkerSource });
    refCluster.current = initialClusterSource;

    const initialMap = new Map({
      target: mapElement.current,
      layers: layers(uiConfig.map.layer, clientLayers),
      view: new View({
        projection: 'EPSG:3857',
        center: [1622513.8609642927, 6454265.691269989],
        maxZoom: 19,
        minZoom: 2,
        zoom: 4
      }),
      controls: defaultControls().extend([new ScaleLine()])
    })


    // save map and vector layer references to state
    setMap(initialMap);
    setMarkerSource(initialMarkerSource);
    setVectorSource(initialVectorSource);
    setRidesDirectionSource(initialRidesDirectionSource)

    const initialLayerCLuster = new VectorLayer({
      source: initialClusterSource,
      displayInLayerSwitcher: false,
      visible: true,
      title: 'Group',
      style: function (feature, resolution) {
        const size = feature.get('features').length;
        var style;
        if (size === 1) {
          style = feature.get('features')[0].getStyle()
        }
        else {
          style = cLusterStyle(style, size)
        };
        return style
      }
    })
    initialMap.addLayer(initialLayerCLuster);

    const initialLayerVectors = new VectorLayer({
      source: initialVectorSource,
      displayInLayerSwitcher: false,
      title: 'ride'
    })

    const initialLayerRidesDirection = new VectorLayer({
      source: initialRidesDirectionSource,
      displayInLayerSwitcher: false,
      title: 'direction ride',
      minZoom: 12
    })

    setLayerVectors(initialLayerVectors);
    initialMap.addLayer(initialLayerVectors);
    initialMap.addLayer(initialLayerRidesDirection);

    const overlay = new Overlay({
      element: popupRef.current,//container,
      stopEvent: false,
      offset: [0, -10],
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    overlayRef.current = overlay;
    initialMap.addOverlay(overlay);

    if (window.location.pathname === '/rides') {
      const initialGreenDrivingSource = new VectorSource();
      setGreenDrivingSource(initialGreenDrivingSource)
      const initialGreenDrivingLayer = new VectorLayer({
        source: initialGreenDrivingSource,
        visible: refUiConfig.current.map.greens,
        displayInLayerSwitcher: true,
        title: t('layerGreenDriving'),
        minZoom: 8
      })
      initialGreenDrivingLayer.setZIndex(6)
      initialMap.addLayer(initialGreenDrivingLayer);

      const initialfuelingPointSource = new VectorSource();
      setfuelingPointSource(initialfuelingPointSource)
      const initialfuelingPointLayer = new VectorLayer({
        source: initialfuelingPointSource,
        visible: true,
        displayInLayerSwitcher: false,
        title: 'fueling points'
      })
      initialfuelingPointLayer.setZIndex(6)
      initialMap.addLayer(initialfuelingPointLayer);

    }

    if (window.location.pathname === '/overview') {
      const trafic = layersTrafic(refUiConfig, clientLayers);
      if (refUiConfig.current.map.trafic) trafficLayer.current = trafic;
      trafic.setZIndex(4)
      initialMap.addLayer(trafic);
    }

    initialMap.on('movestart', function () {
      if (!runAnime.current) { refAnimeEnable.current = false }
      if (animeTimeBreak.current) clearTimeout(animeTimeBreak.current)
    });

    let lastHoverPixel
    initialMap.on('pointermove', function (evt) {
      const feature = initialMap.forEachFeatureAtPixel(evt.pixel, (feature) => { return feature });
      if (feature) {
        if (feature.getProperties().dataLine) {
          setTimeout(() => {
            overlay.setPosition(evt.coordinate);
            popupOpen.current = true;
          }, 100);
          setPopup({ type: 'TitleLine', dataLine: feature.getProperties().dataLine })
          lastHoverPixel = evt.pixel;
        }
      }
      else if (lastHoverPixel && initialMap.getFeaturesAtPixel(lastHoverPixel)) {
        const distance = Math.sqrt(Math.pow(lastHoverPixel[0] - evt.pixel[0], 2) + Math.pow(lastHoverPixel[1] - evt.pixel[1], 2));
        if (distance > 20) {
          overlay.setPosition(undefined);
          popupOpen.current = false;
        }
      }
    })

    initialMap.on('click', function (evt) {
      const feature = initialMap.forEachFeatureAtPixel(evt.pixel, (feature) => { return feature });
      if (feature) {
        let f = feature.getProperties().features;
        if (feature.getProperties().device) f = [devFeature.current];
        if (f) {
          // console.log(f);
          if (f.length > 1) {
            if (initialMap.getView().getZoom() > 14) {
              const coordinates = feature.getGeometry().getCoordinates();
              setTimeout(() => {
                overlay.setPosition(coordinates);
                popupOpen.current = true;
              }, 10);
              setPopup({ type: 'group', data: f, feature: feature })
              // let groupDevice;
              // f.map((d) => {
              //   let devs = d.values_.device
              //   if (!groupDevice) groupDevice = '<p class="mb-0">' + devs.name + ' - ' + devs.plate + '</p>';
              //   else groupDevice = groupDevice + '<p class="mb-0">' + devs.name + ' - ' + devs.plate + '</p>';
              // })
              // if (groupDevice) contentRef.current.innerHTML = groupDevice;
            }
            else {
              const devicesGroup = f.map(d => d.getProperties().device);
              allObjectOnScreen(initialMap, devicesGroup)
            }
          }
          else if (f.length === 1) {
            const coordinates = feature.getGeometry().getCoordinates();
            setTimeout(() => {
              overlay.setPosition(coordinates);
              popupOpen.current = true;
            }, 10);
            let d = f[0].values_.device
            // contentRef.current.innerHTML = '<p>' + d.name + ' - ' + d.plate + '</p>';
            setDevFocus(d)
            setPopup({ type: 'device' })
            setTimeout(() => {
              firstZoom.current = false;
            }, 10);
            if (d.status & 1 && devFeature.current) overlay.setPosition(devFeature.current.getGeometry().getCoordinates());
          }
        }
      }
      else {
        overlay.setPosition(undefined);
        popupOpen.current = false;
      }
      if (LayerSwitcher.current) LayerSwitcher.current.element.classList.remove('ol-forceopen');
      if (settingButton) settingButton.classList.remove('settingButtonOpen')
    });


    initialMap.on('moveend', (e) => {
      // if (overlay.getPosition() && !e.frameState.animate) {
      //   overlay.setPosition(undefined);
      // }
      if (refAnimeEnableMem.current && !refAnimeEnable.current) { animeTimeBreak.current = setTimeout(() => { refAnimeEnable.current = true }, 2000) }
      if (LayerSwitcher.current) LayerSwitcher.current.element.classList.remove('ol-forceopen');
      if (settingButton) settingButton.classList.remove('settingButtonOpen')
      if (trafficLayer.current) {
        if (initialMap.getView().getZoom() < 8.5) trafficLayer.current.setVisible(false)
        else trafficLayer.current.setVisible(true)
      }
      // const newZoom = initialMap.getView().getZoom();
      // if (currZoom.current != newZoom) {
      //   currZoom.current = newZoom;
      //   console.log('Zoom: ' + newZoom);
      // }
    });


    initialMap.on('pointermove', (event) => {
      if (!event.dragging) initialMap.getTargetElement().style.cursor = initialMap.hasFeatureAtPixel(initialMap.getEventPixel(event.originalEvent)) ? 'pointer' : 'grab';
    });

    initialMap.on('pointerdrag', (event) => {
      initialMap.getTargetElement().style.cursor = 'grabbing';
    });

    //LayerSwitcher
    LayerSwitcher.current = new LayerPopup({
      mouseover: false,
      collapsed: true,
    })
    LayerSwitcher.current.element.children[3].lastElementChild.innerHTML = '<label class="ol-layer-label">' + t('layer') + '</labal>'
    if (initialMap.getLayers().getArray().filter(layer => layer.values_.displayInLayerSwitcher).length > 1) initialMap.addControl(LayerSwitcher.current)
    LayerSwitcher.current.element.addEventListener('click', function (event) {
      if (settingButton) settingButton.classList.remove('settingButtonOpen')
      let indexActiveLayer = null
      initialMap.getLayers().getArray().filter(layer => !(layer instanceof VectorLayer) && !(layer instanceof VectorTileLayer)).forEach((tileLayer, i) => { if (tileLayer.getVisible()) indexActiveLayer = i });
      if (indexActiveLayer !== refUiConfig.current.map.layer) setUiConfig({ ...refUiConfig.current, map: { ...refUiConfig.current.map, layer: indexActiveLayer } })
      const greenDrivingLayer = initialMap.getLayers().getArray().filter(layer => (layer instanceof VectorLayer))[3] // && layer.values_.title === '"Styl jízdy"'
      if (greenDrivingLayer && (greenDrivingLayer.values_.visible !== refUiConfig.current.map.greens)) setUiConfig({ ...refUiConfig.current, map: { ...refUiConfig.current.map, greens: greenDrivingLayer.values_.visible } })
      const TraficLayer = initialMap.getLayers().getArray().filter(layer => (layer instanceof VectorTileLayer))[0]
      if (TraficLayer && (TraficLayer.values_.visible !== refUiConfig.current.map.trafic)) setUiConfig({ ...refUiConfig.current, map: { ...refUiConfig.current.map, trafic: TraficLayer.values_.visible } })
    })


    // All device center
    const centerAllButton = document.createElement('button');
    centerAllButton.className = 'ol-unselectable ol-control centerAllButton';
    centerAllButton.addEventListener('click', () => {
      if (devices) {
        // console.log(devices);
        const devicesFilter = devices.filter(d => { if (d.pos[0] !== 0 && d.pos[1] !== 0 && d.code) return d });
        allObjectOnScreen(initialMap, devicesFilter);
      }
      if (allRidesOnMap.current) allRidesOnScreen(initialMap, allRidesOnMap.current)
    });
    initialMap.getTargetElement().appendChild(centerAllButton);

    // Setting map
    let settingButton = undefined
    if (window.location.pathname === '/overview') {
      const changeSetting = (element) => {
        switch (element.id) {
          case 'o1':
            refCenterEnable.current = element.checked
            setUiConfig({ ...refUiConfig.current, map: { ...refUiConfig.current.map, center: element.checked } });
            break;
          case 'o2':
            if (featureAnime.current) {
              featureAnime.current.stop();
              featureAnime.current = null;
            }
            idAnim.current = null;
            setIdAnimBef(false);
            // setAnimeEnable(element.checked)
            refAnimeEnable.current = element.checked
            setUiConfig({ ...refUiConfig.current, map: { ...refUiConfig.current.map, animation: element.checked } });
            break;
          case 'o3':
            if (element.checked) refCluster.current.setDistance(30);
            else refCluster.current.setDistance(0);
            refCenterEnable.current = element.checked;
            setUiConfig({ ...refUiConfig.current, map: { ...refUiConfig.current.map, cluster: element.checked } });
            break;
          case 'o4':
            if (element.value !== refTitleObject.current) {
              refTitleObject.current = element.value;
              setUiConfig({ ...refUiConfig.current, map: { ...refUiConfig.current.map, title: element.value } });
            }
            break;
          case 'o5':
            if (parseInt(element.value) !== dzoom.current) {
              WsSend({
                method: 'setUser',
                dzoom: element.value,
                oid: 9
              })
              dzoom.current = parseInt(element.value);
            }
            break;
        }
      }

      settingButton = document.createElement('button');
      settingButton.className = 'ol-unselectable ol-control settingButton';
      let optionsDzoom = '';
      for (let i = 10; i <= 19; i++) {
        optionsDzoom += `<option value="${i}">${i}</option>\n`;
      }
      const settingOpen = `<div class='settingButtonBox'>
        <label>${t('mapSetting')}</label>
        <ul class='panel'>
          <li><input type="checkbox" id="o1" class='cursorpointer'>${t('center')}</li>
          <li><input type="checkbox" id="o2" class='cursorpointer'/>${t('animation')}</li>
          <li><input type="checkbox" id="o3" class='cursorpointer'/>${t('cluster')}</li>
          <label class="mt-1">${t('titleObject')}</label>
          <select id="o4" class='cursorpointer'>
            <option value="name">${t('titleName')}</option>
            <option value="plate">${t('titlePlate')}</option>
            <option value="name+plate">${t('titleName')}+${t('titlePlate')}</option>
            <option value="name+driver">${t('titleName')}+${t('titleDriver')}</option>
          </select>
          <div>
           <label>${t('dzoom')}</label>
           <select id="o5">
              ${optionsDzoom}
           </select>
          </div>
        </ul>
        </div>`;
      settingButton.innerHTML = settingOpen
      initialMap.getTargetElement().appendChild(settingButton)
      if (refCenterEnable.current) settingButton.querySelector('#o1').checked = true
      if (refAnimeEnable.current) settingButton.querySelector('#o2').checked = true
      if (refClusterEnable.current) settingButton.querySelector('#o3').checked = true
      if (refTitleObject.current) settingButton.querySelector('#o4').value = refTitleObject.current;
      if (dzoom.current) settingButton.querySelector('#o5').value = dzoom.current;
      settingButton.addEventListener('click', function (event) {
        if (LayerSwitcher.current) LayerSwitcher.current.element.classList.remove('ol-forceopen');
        const clickedElement = event.target;
        clickedElement.classList.toggle('settingButtonOpen')
        const checkbox = clickedElement.querySelector('input[type="checkbox"]');
        if (clickedElement.closest('li')) {
          if (clickedElement.closest('input[type="checkbox"]')) changeSetting(clickedElement)
          else if (checkbox) {
            checkbox.checked = !checkbox.checked;
            changeSetting(checkbox);
          }
        }
        if (clickedElement.closest('select')) {
          changeSetting(clickedElement)
        }
      })
    }

    initialLayerCLuster.setZIndex(5)
    initialLayerVectors.setZIndex(5)
    initialLayerRidesDirection.setZIndex(6)
    window.addEventListener("focus", focusOn);
    return () => {
      window.addEventListener("focus", focusOn);
      initialMap.setTarget(undefined);
    };


  }, [])


  // useEffect(() => {
  //   if (mapElement.current) {
  //     if (loading) mapElement.current.classList.add('spinner');
  //     else mapElement.current.classList.remove('spinner');
  //   }
  // }, [loading])

  //device/object on map
  useEffect(() => {
    if (devices) {
      // console.log('devices on map update');
      if (!map) return;
      if (devices.length === 0) return
      markerSource.clear()
      const devicesFilter = devices.filter(d => { if (d.pos[0] !== 0 && d.pos[1] !== 0 && d.code) return d })
      devicesFilter.map((dev) => {
        let ic = new Feature({
          geometry: miliToLonLat(dev.pos[1], dev.pos[0]),
          name: dev.name,
          device: dev,
        })
        // if (dev.status & 1) animatePulse(miliToLonLat(dev.pos[1], dev.pos[0]).getFlatCoordinates(), map)
        if (!dev.ride) {
          ic.setStyle(deviceStyle(dev, false, false, devFocus.id === dev.id ? 15 : 10, refTitleObject.current));
          return markerSource.addFeature(ic)
        }
      })

      if (!zoomEnd) {
        allObjectOnScreen(map, devicesFilter);
        setzoomEnd(true);
      }
      map.updateSize()
    }
  }, [devices, map]);

  useEffect(() => {
    if (map && devFocus) {
      let sm = markerAt(devFocus.id, markerSource);
      if (!devFocus.ride && sm) {
        if (devFocus.zoom && map.getView().getZoom() < dzoom.current) {
          map.getView().setZoom(dzoom.current);
          setDevFocus({ ...devFocus, zoom: false });
        }
        if (!isOnScreen(sm, markerSource, map)) {
          map.getView().setCenter(center(sm).getExtent());
        }
        if (!(devFocus.status & 1)) {
          if (firstPulseAnime.current) {
            flashPulse(sm, layerVectors, mapRef.current);
            firstPulseAnime.current = false;
          }
          else {
            if (prevDevFocus) {
              if (devFocus.id !== prevDevFocus.id) flashPulse(sm, layerVectors, mapRef.current);
            }
            else flashPulse(sm, layerVectors, mapRef.current);
          }

        }
      }
    }
  }, [devFocus])

  useEffect(() => {
    if (markerSource && vectorSource && devFocus && map) {
      if (prevDevFocus && devFocus.id !== prevDevFocus.id) {
        if (popupOpen.current === true) overlayRef.current.setPosition(undefined)
        runAnime.current = false;
        firstZoom.current = true;
        if (featureAnime.current) {
          featureAnime.current.stop();
          featureAnime.current = null;
          setDevices(devices.map((i) => {
            // if (i.id === devFocus.id) return { ...i, anime: false }
            // if (i.id === prevDevFocus.id) return { ...i, ride: false }
            return { ...i, ride: false }
            // else return i
          }));
        }
        idAnim.current = false;
        startPointFeature.current = false;
        routeFeature.current = false;
        devFeature.current = false;
        routeAnime.current = false;
        vectorSource.clear();
      }
      if (devFocus && currentRide && currentRide.val.length !== 0 && currentRide.dev === devFocus.id && devFocus.status & 1) {
        // console.log('run current ride');
        setDevices(devices.map((i) => {
          if (i.id === devFocus.id) return { ...i, ride: true }
          else return i
        }));
        let cr = currentRide.val;
        if (!startPointFeature.current) {
          let startPointFeatureInitial = new Feature({
            geometry: miliToLonLat(cr[0].lon, cr[0].lat),
            noDelete: true
          })
          startPointFeatureInitial.setStyle(pointStartStyle());
          vectorSource.addFeature(startPointFeatureInitial);
          startPointFeature.current = startPointFeatureInitial;
        }

        var polyline = new Array();
        var polylineAnime = new Array();
        // let speed = devFocus.pos[3];
        // if (firstZoom.current && speed < 10) speed = 0
        // console.log('cr.length', cr.length, 'speed', devFocus.pos[3]);
        // animation way
        if (refAnimeEnable.current && idAnim.current && cr.length > 5) {
          // if (animeEnable && cr.length > 5) {
          // if (!idAnim.current || idAnim.current.dev !== devFocus.id) {
          //   idAnim.current = { id: cr[cr.length - 1].id, dev: devFocus.id };
          // }
          // console.log('anime way');
          cr.map((i) => {
            if (idAnim.current.id >= i.id) polyline.push([miliToLonLat(i.lon, 0).getCoordinates()[0], miliToLonLat(0, i.lat).getCoordinates()[1], i.id, i.stamp, i.course])
            if (idAnim.current.id <= i.id) polylineAnime.push([miliToLonLat(i.lon, 0).getCoordinates()[0], miliToLonLat(0, i.lat).getCoordinates()[1], i.id, i.stamp, i.course])
          })
          // console.log('polylineAnime way', polylineAnime.length);
          if (document.hidden) {
            // console.log('page hidden animation disable');
            refAnimeEnable.current = false;
            if (featureAnime.current) {
              featureAnime.current.stop();
              featureAnime.current = null;
            }
            idAnim.current = null;
            setIdAnimBef(false);
          }
          // if (polylineAnime.length > 10) {
          //   console.log('polylineAnime clear length > 10');
          //   refAnimeEnable.current = false;
          //   if (featureAnime.current) {
          //     featureAnime.current.stop();
          //     featureAnime.current = null;
          //     idAnim.current = null;
          //     setIdAnimBef(false);
          //   }
          // }
          if (!devFeature.current) {
            let devFeaturePoint = new Point([polylineAnime[0][0], polylineAnime[0][1]]);
            let devFeatureInitial = new Feature({
              geometry: devFeaturePoint,
              name: devFocus.name,
              device: devFocus
            })
            devFeatureInitial.setStyle(deviceStyle(devFocus, true, polylineAnime[0][4], 20, refTitleObject.current));
            vectorSource.addFeature(devFeatureInitial)
            devFeature.current = devFeatureInitial;

          }
        }
        // noAnimation way
        else {
          if (refAnimeEnable.current && (!idAnim.current || idAnim.current.dev !== devFocus.id)) {
            idAnim.current = { id: cr[cr.length - 1].id, dev: devFocus.id }
          }
          // console.log('noAnime way');

          // vectorSource.forEachFeature(function (f) {
          // console.log(vectorSource.getFeatures());
          // })

          // if (featureAnime.current) {
          //   console.log('remove animation');
          //   featureAnime.current.stop();
          //   featureAnime.current = null;
          //   idAnim.current = null;
          //   setIdAnimBef(false);
          // }
          cr.map((i, index) => {
            // if (cr.length > 6) {
            //   if (index < (cr.length - 4)) polyline.push([miliToLonLat(i.lon, 0).getCoordinates()[0], miliToLonLat(0, i.lat).getCoordinates()[1], i.id, i.stamp, i.course])
            // }
            // else 
            polyline.push([miliToLonLat(i.lon, 0).getCoordinates()[0], miliToLonLat(0, i.lat).getCoordinates()[1], i.id, i.stamp, i.course])
          })

          // polylineAnime.push([miliToLonLat(cr[cr.length - (cr.length > 6 ? 5 : 1)].lon, 0).getCoordinates()[0], miliToLonLat(0, cr[cr.length - (cr.length > 6 ? 5 : 1)].lat).getCoordinates()[1], cr[cr.length - (cr.length > 6 ? 5 : 1)].id, cr[cr.length - (cr.length > 6 ? 5 : 1)].stamp])

          polylineAnime.push([miliToLonLat(cr[cr.length - 1].lon, 0).getCoordinates()[0], miliToLonLat(0, cr[cr.length - 1].lat).getCoordinates()[1], cr[cr.length - 1].id, cr[cr.length - 1].stamp])

          let devPoint = new Point([polyline[polyline.length - 1][0], polyline[polyline.length - 1][1]]);
          if (!devFeature.current) {
            let devFeatureInitial = new Feature({
              geometry: devPoint,
              name: devFocus.name,
              device: devFocus
            })
            devFeatureInitial.setStyle(deviceStyle(devFocus, false, polyline[polyline.length - 1][4], 20, refTitleObject.current));
            vectorSource.addFeature(devFeatureInitial)
            devFeature.current = devFeatureInitial;
          }
          else {
            devFeature.current.setGeometry(devPoint);
            devFeature.current.setStyle(deviceStyle(devFocus, false, polyline[polyline.length - 1][4], 20, refTitleObject.current));
          }

          if (firstZoom.current && !popupOpen.current) {
            if (map.getView().getZoom() < dzoom.current) map.getView().setZoom(dzoom.current)
            map.getView().setCenter(devPoint.getCoordinates())
            firstZoom.current = false;
            flashPulse(devFeature.current, layerVectors, mapRef.current)
          }

          if (refCenterEnable.current && !isOnScreen(devFeature.current, vectorSource, map)) {
            // console.log('out of display');
            map.getView().setCenter(devFeature.current.getGeometry().getCoordinates())
          }

        }

        if (!routeFeature.current) {
          let routeFeatureIntial = new Feature({
            geometry: new LineString(polyline),
          });
          routeFeatureIntial.setStyle(routeStyle());
          vectorSource.addFeature(routeFeatureIntial)
          routeFeature.current = routeFeatureIntial;
        }
        else {
          routeFeature.current.setGeometry(new LineString(polyline));
          routeFeature.current.setStyle(routeStyle());
        }

        //temp for development future road for animation
        // let routeFuture = new Feature({
        //   geometry: new LineString(polylineAnime),
        // });
        // routeFuture.setStyle(routeStyleFuture());
        // vectorSource.addFeature(routeFuture)

        var anime = new Path({
          path: new LineString(polylineAnime),
          duration: 18000,
          easing: linear,
          rotate: true,
        });


        if (!runAnime.current && polylineAnime.length > 1 && idAnim.current) {
          runAnime.current = true;
          let startAnimeArr = [[polylineAnime[0][0], polylineAnime[0][1]]];
          let startAnimePoint = new Point([polylineAnime[0][0], polylineAnime[0][1]])

          // if (firstZoom.current) {
          //   if (map.getView().getZoom() < dzoom.current) map.getView().setZoom(dzoom.current)
          //   if (!isOnScreen(devFeature.current, vectorSource, map)) map.getView().setCenter(devFeature.current.getGeometry().getCoordinates())
          //   //temp
          //   // animatePulse(startAnimePoint.getFlatCoordinates(), map)
          //   firstZoom.current = false;
          // }

          if (!routeAnime.current) {
            let routeAnimeIntial = new Feature({
              geometry: new LineString(startAnimeArr),
            });
            routeAnimeIntial.setStyle(routeStyle());
            vectorSource.addFeature(routeAnimeIntial)
            routeAnime.current = routeAnimeIntial;
          }

          devFeature.current.setGeometry(startAnimePoint);
          devFeature.current.setStyle(deviceStyle(devFocus, true, polylineAnime[0][4], 20, refTitleObject.current));

          anime.on('animationstart', function (e) {
            // lastTimeAnime.current = Date.now();
            // console.log('animation start');
            if (refCenterEnable.current && !isOnScreen(devFeature.current, vectorSource, map)) map.getView().setCenter(devFeature.current.getGeometry().getCoordinates())
            // animatePulse(startAnimePoint.getFlatCoordinates(), map)
          });

          anime.on('animating', (e) => {
            startAnimeArr.push(e.geom.getCoordinates());
            routeAnime.current.getGeometry().setCoordinates(startAnimeArr);
          })

          anime.on('animationend', function (e) {
            // console.log('animation end');
            runAnime.current = false;
            setIdAnimBef(polylineAnime[polylineAnime.length - 1][2]);
            idAnim.current = { id: polylineAnime[polylineAnime.length - 1][2], dev: devFocus.id };
            devFeature.current.setGeometry(new Point([polylineAnime[polylineAnime.length - 1][0], polylineAnime[polylineAnime.length - 1][1]]));
            devFeature.current.setStyle(deviceStyle(devFocus, false, polylineAnime[polylineAnime.length - 1][0], 20, refTitleObject.current));
            setTimeout(() => {
              if (refCenterEnable.current && !isOnScreen(devFeature.current, vectorSource, map) && devFeature.current) map.getView().setCenter(devFeature.current.getGeometry().getCoordinates())
            }, 500);
            // animatePulse(new Point([polylineAnime[polylineAnime.length - 1][0], polylineAnime[polylineAnime.length - 1][1]]).getFlatCoordinates(), map)
          });

          setTimeout(() => {
            featureAnime.current = layerVectors.animateFeature(devFeature.current, anime);
          }, 100);

        }
      }
      else {
        if (!(devFocus & 1)) {
          // console.log('device not current ride, clear data');
          runAnime.current = false;
          firstZoom.current = true;
          if (featureAnime.current) {
            featureAnime.current.stop();
            featureAnime.current = null;
          }
          setDevices(devices.map((i) => { return { ...i, ride: false } }));
          idAnim.current = false;
          startPointFeature.current = false;
          routeFeature.current = false;
          devFeature.current = false;
          routeAnime.current = false;
          vectorSource.clear();
        }
      }
    }
  }, [vectorSource, currentRide, devFocus, idAnimBef])

  //rides history
  useEffect(() => {
    if (ridesPoints && Object.keys(ridesPoints).length > 0) {
      if (markerSource && vectorSource && map) {
        //  console.log('rides method', ridesPoints);
        vectorSource.clear();
        greenDrivingSource.clear();
        ridesDirectionSource.clear();
        Object.values(ridesPoints).forEach((arrRide, index) => {
          let seg = { line: new Array(), dataLine: new Array() }
          arrRide.points.forEach((item, i) => {
            if (item.rideId) {
              seg.line.push([miliToLonLat(item.lon, 0).getCoordinates()[0], miliToLonLat(0, item.lat).getCoordinates()[1]]);
              seg.dataLine.push(item);
            }
            if (item.action) {
              if (item.action === 'start') {
                let starFeature = new Feature({
                  geometry: miliToLonLat(item.lon, item.lat),
                  dataLine: {
                    stamp: item.stamp,
                    action: item.action
                  }
                })
                starFeature.setStyle(pointStartStyle());
                vectorSource.addFeature(starFeature)
              }
              else if ((item.action === 'stop') || i === (arrRide.points.length - 1)) {
                let stopFeature = new Feature({
                  geometry: miliToLonLat(item.lon, item.lat),
                  dataLine: {
                    stamp: item.stamp,
                    action: item.action
                  }
                })
                stopFeature.setStyle(pointStoptStyle());
                vectorSource.addFeature(stopFeature)
              }
            }
            else if (item.greent > 0) {
              let warningFeature = new Feature({
                geometry: miliToLonLat(item.lon, item.lat),
                dataLine: {
                  greent: item.greent,
                  greenv: item.greenv,
                  stamp: item.stamp,
                  speed: item.speed
                }
              })
              warningFeature.setStyle(pointGreenDrivingStyle(item.greent));
              greenDrivingSource.addFeature(warningFeature)
            }
          })

          let polyline = new LineString(seg.line)
          polyline.forEachSegment((start, end, i) => {
            // let segmentCoords = polyline.getCoordinates().slice(start, end + 1);
            let segCoords = [start, end]
            const segindex = seg.line.findIndex(i => JSON.stringify(i) === JSON.stringify(segCoords[0]))

            let routeFeature = new Feature({
              geometry: new LineString(segCoords),
              dataLine: seg.dataLine[segindex],
            });
            const rideId = Object.keys(ridesPoints)[index];

            const dx = end[0] - start[0];
            const dy = end[1] - start[1];
            const midPoint = [
              (start[0] + end[0]) / 2,
              (start[1] + end[1]) / 2,
            ];
            const length = Math.sqrt(dx * dx + dy * dy);
            if (length > 250) {
              const directionFeature = new Feature({
                geometry: new Point(midPoint),
                dataLine: seg.dataLine[segindex]
              });
              // const directionStyle = new Style({
              //   image: new Icon({
              //     src: notstatic_1_90,
              //     anchor: [0.75, 0.5],
              //     rotateWithView: true,
              //     rotation: -rotation,
              //   })
              // })
              directionFeature.setStyle(directionStyle(Math.atan2(dy, dx), seg.dataLine[segindex].speed, ridesIdsFocus.includes(parseInt(rideId))));
              ridesDirectionSource.addFeature(directionFeature);
            }
            if (ridesIdsFocus.includes(parseInt(rideId))) {
              routeFeature.setStyle(routeStyleFocus(seg.dataLine[segindex].speed));
            }
            else routeFeature.setStyle(routeStyle(seg.dataLine[segindex].speed));
            vectorSource.addFeature(routeFeature)


            // old code without speed and etc..
            //  let polyline = new LineString(seg.list)
            // let routeFeature = new Feature({
            //   geometry: polyline
            // });

            // const rideId = Object.keys(ridesPoints)[index];
            // if (ridesIdsFocus.includes(parseInt(rideId))) {
            //   routeFeature.setStyle(routeStyleFocus());
            // }
            // else routeFeature.setStyle(routeStyle());
            // vectorSource.addFeature(routeFeature)
          });
        })

        const r = Object.values(ridesPoints).map(item => { return item.points }).reduce((a, c) => a.concat(c), []);
        const fr = r.filter(item => { if (ridesIdsFocus.includes(item.rideId)) return item })
        const allr = fr.length > 0 ? fr : r;
        allRidesOnMap.current = r;
        const z = map.getView().getZoomForResolution(map.getView().getResolutionForExtent(nativeBounds2(allr)));
        if (map.getView().getZoom() !== 19 && z !== 19 && map.getView().getZoom() !== z) {
          map.getView().setZoom(z - 0.3);
          map.getView().setCenter(centerall2(allr));
        }
        map.updateSize()
      }
    }
    if (ridesPoints && Object.keys(ridesPoints).length === 0 && vectorSource) {
      vectorSource.clear();
      greenDrivingSource.clear();
      ridesDirectionSource.clear();
    }
  }, [ridesPoints])

  //fuelingPoint
  useEffect(() => {
    if (fuelingPoint && Object.keys(fuelingPoint).length > 0) {
      if (fuelingPointSource && map) {
        Object.values(fuelingPoint).forEach((point, index) => {
          let starFeature = new Feature({
            geometry: miliToLonLat(point.lon, point.lat),
            dataLine: {
              stamp: point.stamp1,
              fueling: point
            }
          })
          starFeature.setStyle(pointFuelingStyle());
          fuelingPointSource.addFeature(starFeature)
        })
        map.updateSize()
      }
    }
    if (fuelingPoint && Object.keys(fuelingPoint).length === 0 && fuelingPointSource) {
      fuelingPointSource.clear();
    }
  }, [fuelingPoint])

  useEffect(() => {
    if (map) {
      setTimeout(() => {
        map.updateSize();
      }, 400);
      if (mapElement.current) {
        const panelMap = mapElement.current.querySelector('.panel-container .panel');
        if (panelMap) panelMap.style.maxHeight = `${height - 60}px`;
      }
    }
  }, [update])



  return (
    <div>
      <div ref={mapElement} className="ol-map" style={{ height: height, width: width }}></div>
      <div id="popup" className="ol-popup" ref={popupRef}>
        <div id="popup-content" ref={contentRef}>
          {popup && devFocus && popup.type === 'device' ? < PopupDevice d={devFocus} overlay={overlayRef.current} feature={(devFocus.status & 1 ? devFeature.current : markerAt(devFocus.id, markerSource))} popup={popupOpen.current} set={setPopup} /> : ''}
          {popup && devFocus && popup.type === 'group' && popup.data ? (<GroupDevice group={popup.data} feature={popup.feature} overlay={overlayRef.current} popup={popupOpen.current} set={setPopup} setDevFocus={setDevFocus} />) : ''}
          {popup && popup.type === 'TitleLine' ? <TitleLine dataLine={popup.dataLine} /> : ''}
        </div>
      </div>
    </div>
  )
}

export default MapView