import React, { Suspense, lazy } from 'react';
import { lazyDefault } from '../components/tools'

import { ReactComponent as Park } from './park.svg';
import { ReactComponent as Ride } from './ride.svg';
import { ReactComponent as Ign } from './ign.svg';
import { ReactComponent as Off } from './off.svg';
import { ReactComponent as On } from './on.svg';
import { ReactComponent as SmallCircel } from './smallCircel.svg'
import { ReactComponent as Acceleration } from './acceleration.svg'
import { ReactComponent as Breaking } from './breaking.svg'
import { ReactComponent as Cornering } from './cornering.svg'
import { ReactComponent as EditRides } from './editRides.svg';
import { ReactComponent as CarsGroup } from './carsGroup.svg';
import { ReactComponent as FilterTime } from './filterTime.svg';
import { ReactComponent as StatsVehicle } from './statsVehicle.svg';
import { ReactComponent as StatsDriverCheck } from './statsDriverCheck.svg';
import { ReactComponent as StatsDriverCheck2 } from './statsDriverCheck2.svg';
import { ReactComponent as StatsSelection } from './statsSelection.svg';
import { ReactComponent as Equipments } from './equipments.svg';
import { ReactComponent as ConfigDrivers } from './configDrivers.svg';
import { ReactComponent as ModulProgress } from './modulProgress.svg';

const LogoLogiscare = lazyDefault(() => import('./logo.svg'))
const LogominLogiscare = lazyDefault(() => import('./logomin.svg'))
const LogoGlobalsec = lazyDefault(() => import('../brand/globalsec/images/logo.svg'))
const LogominGlobalsec = lazyDefault(() => import('../brand/globalsec/images/logomin.svg'))
const Logo_alfakomtemp = lazyDefault(() => import('../brand/alfakomtemp/images/logo.svg'))
const Logomin_alfakomtemp2 = lazyDefault(() => import('../brand/alfakomtemp/images/logomin.svg'))
const LogoAutopujcovna = lazyDefault(() => import('./logo_CRo_Autopujcovna.svg'))

let main = LogoLogiscare;
let min = LogominLogiscare;
if (process.env.REACT_APP_BRAND === 'globalsec') {
    main = LogoGlobalsec;
    min = LogominGlobalsec;
} else if (process.env.REACT_APP_BRAND === 'alfakomtemp') {
    main = Logo_alfakomtemp;
    min = Logomin_alfakomtemp2;
}

const withSuspense = (Component) => (props) => (
    <Suspense>
        <Component {...props} />
    </Suspense>
);

export {
    Park,
    Off,
    On,
    Ride,
    Ign,
    SmallCircel,
    Acceleration,
    Breaking,
    Cornering,
    EditRides,
    CarsGroup,
    FilterTime,
};

export const Logo = {
    Main: withSuspense(main),
    Min: withSuspense(min),
    Autopujcovna: withSuspense(LogoAutopujcovna),
}

export const IconsMenu = {
    StatsVehicle,
    StatsDriverCheck,
    StatsDriverCheck2,
    StatsSelection,
    Equipments,
    ConfigDrivers,
    ModulProgress
}
