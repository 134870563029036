import { useState, useEffect, useCallback, lazy } from "react";
import { client } from 'websocket';

export function useWindowSize() {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { width: innerWidth, height: innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return windowSize
}

export const setQuery = (key, value, navigate, location) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(key, value);
  navigate(`?${searchParams.toString()}`);
};

export const clearQuery = (navigate) => {
  navigate({ search: '' });
};

export const getQuery = (value, location) => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(value);
};

export const isClient = (name, costumer) => {
  if (costumer) {
    const clientNumber = parseInt(costumer.match(/\[(.*?)\]/)[1]) // String()
    const checkWs = (ws) => {
      return client.url === `wss://${ws}.logiscare.com/`
    }
    
    switch (name) {
      case 'ceskyRozhlas':
        if (clientNumber === 11598 && checkWs('ws01')) return true
        // if (client.url === `ws://ws88.logiscare.com/`) return true
        // if (clientNumber === '00001' && checkWs('ws99')) return true
        break;
      case 'develop':
        if (checkWs('ws99') || checkWs('ws00') || process.env.NODE_ENV === 'development') return true
        break;
      case 'geoTest':
        if (clientNumber === 6065 && checkWs('ws02')) return true
        break;
      default: return false
    }
  }
  return false
};

export const isBrand = (name) => {
  return process.env.REACT_APP_BRAND === name
};

export const useKeyboardShortcut = (shortcutAction, config) => {
  const targetElement = config.shortcutTarget || document;

  const eventHandler = useCallback((e) => {
    // console.log(e);
    const { code, ctrlKey, altKey, shiftKey } = e;
    if (config.code !== code) return;
    if (config.ctrlKey && !ctrlKey) return;
    if (config.shiftKey && !shiftKey) return;
    if (config.altKey && !altKey) return;

    e.preventDefault();  // Zabránění výchozímu chování prohlížeče
    shortcutAction(e);
  }, [shortcutAction, config]);

  useEffect(() => {
    targetElement.addEventListener('keydown', eventHandler);
    return () => targetElement.removeEventListener('keydown', eventHandler);
  }, [targetElement, eventHandler]);
}

export const generatePassword = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters[randomIndex];
  }
  return password;
};

export const masterUser = ['michal.dusek', 'jakub.stefan', 'tom', 'danielap', 'jan', 'radka.duskova', 'milan.kaspar', 'lumir.olbrich'];

export function getJSONParse(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return false;
  }
}

export function checkNavbarItem(code, user) {
  const viewEvents = ['overview', 'map', 'devicesList']
  const viewHistory = ['rides', 'statsVehicles', 'statsSelection']

  switch (true) {
    case viewEvents.some(p => p === code): return ['data_curator', 'view_events']
    case viewHistory.some(p => p === code): return ['data_curator', 'view_history']
    case code.includes('driverCheck'): {
      if (user && user.var && user.var.devs) {
        if (code === 'driverCheck') {
          if (user.var.devs.anal && user.var.devs.truck) return ['data_curator', 'view_history']
          else return ['data_curator']
        }
        else {
          if (user.var.devs.anal > 0) return ['data_curator', 'view_history']
          else return ['data_curator']
        }
      }
      else return ['data_curator', 'view_history']
    }
    case code === 'reports': return ['data_curator', 'view_history', 'view_report']
    case code === 'sensorsReport': return ['data_curator', 'view_report']
    case code === 'tacho': {
      if (user && user.var && user.var.devs) {
        if (user.var.devs.ddd) return ['data_curator', 'view_history', 'edit_maintenances']
        else return ['data_curator']
      }
    }
    case code === 'equipments': return ['data_curator', 'configurator']
    case code === 'fences': {
      if (user.ddriver === null) return ['data_curator', 'view_report']
      else return ['data_curator']
    }
    default: return null
  }
}

export function checkClientRoles(cRoles, code) {
  if (!code) return true
  else return cRoles.some(i => code.some(c => c === i.type.symbol))
}

export function normalizeText(text) {
  return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export const lazyDefault = (importFunc) =>
  lazy(() =>
      importFunc().then((module) => ({ default: module.ReactComponent }))
  );