import { useState, createContext, useMemo, useEffect, useRef } from 'react';
import { WsSend, WSdata, WsAnal } from "../scripts/ws";

const UserContext = createContext();

const UserProvider = (props) => {
    const [user, setUser] = useState('');
    const [costumer, setCostumer] = useState('');
    const [clientGroups, setClientGroups] = useState('');
    const [clientRoles, setClientRoles] = useState('');
    const [clientFilters, setClientFilters] = useState('');
    const [clientLayers, setClientLayers] = useState('');
    const [dev, setDev] = useState(false); // restriction view_events
    const [devH, setDevH] = useState(false); // restriction view_history
    const [devR, setDevR] = useState(false); // restriction view_report
    const [driv, setDriv] = useState(false);
    const [uiConfig, setUiConfig] = useState('');
    const [lockLayout, setLockLayout] = useState(false);
    const [notification, setNotification] = useState('');

    const delaySendUiConfig = useRef(null);
    const callChangeUiConfig = useRef(0);
    /* because we will be providing an object to the provider, it is better to put the value inside a useMemo so that the component will only re-render when there's a change in the value. */
    const value = useMemo(
        () => ({
            user, setUser,
            costumer, setCostumer,
            clientGroups, setClientGroups,
            clientRoles, setClientRoles,
            clientFilters, setClientFilters,
            dev, setDev,
            devH, setDevH,
            devR, setDevR,
            driv, setDriv,
            lockLayout, setLockLayout,
            uiConfig, setUiConfig,
            callChangeUiConfig,
            notification, setNotification,
            clientLayers, setClientLayers
        }),
        [costumer, dev, devH, devR, lockLayout, uiConfig, notification]);

    useEffect(() => {
        if (uiConfig) {
            // console.log('uiConfig change', uiConfig);
            setLockLayout(uiConfig.lockLayout)
            if (delaySendUiConfig.current) clearTimeout(delaySendUiConfig.current);
            delaySendUiConfig.current = setTimeout(() => {
                if (callChangeUiConfig.current === 0) {
                    callChangeUiConfig.current += 1;
                    // console.log('uiConfig save to server skip');
                    return;
                }
                else {
                    console.log('uiConfig save to server');
                    WsSend({
                        method: 'setUser',
                        uiConfig: uiConfig,
                        oid: 9
                    })
                }
                //this is delay when new data uiConfig send to server and skip first call
            }, 500);
            // Cleanup the timer when the component unmounts
            return () => {
                if (delaySendUiConfig.current) clearTimeout(delaySendUiConfig.current);
            };
        }
    }, [uiConfig])

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    );
}
export { UserContext, UserProvider };