import { ReactComponent as Mark1 } from './mark_1.svg';
import { ReactComponent as Mark2 } from './mark_2.svg';
import { ReactComponent as Mark3 } from './mark_3.svg';
import { ReactComponent as Mark4 } from './mark_4.svg';
import { ReactComponent as Mark5 } from './mark_5.svg';
import { ReactComponent as Mark6 } from './mark_6.svg';
import { ReactComponent as Mark7 } from './mark_7.svg';
import { ReactComponent as Mark8 } from './mark_8.svg';
import { ReactComponent as Mark9 } from './mark_9.svg';
import { ReactComponent as Mark10 } from './mark_10.svg';

export function markStar(mark) {
    const starsList = {
        1: <Mark1 />,
        2: <Mark2 />,
        3: <Mark3 />,
        4: <Mark4 />,
        5: <Mark5 />,
        6: <Mark6 />,
        7: <Mark7 />,
        8: <Mark8 />,
        9: <Mark9 />,
        10: <Mark10 />
    }

    return starsList[mark]
}
