export const weekDaysCS = ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"];
export const monthsCS = ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"];

export const weekDaysEL = ["Κυρ", "Δευ", "Τρί", "Τετ", "Πέμ", "Παρ", "Σάβ"];
export const monthsEL = ["Ιανουάριος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάιος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"];

export const weekDaysSK = ["Ne", "Po", "Ut", "St", "Št", "Pi", "So"];
export const monthsSK = ["Január", "Február", "Marec", "Apríl", "Máj", "Jún", "Júl", "August", "September", "Október", "November", "December"];

export const weekDaysPL = ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "Sb"];
export const monthsPL = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"];

export const formatDate = "DD.MM.YYYY";

export const formatDateTime = "DD.MM.YYYY HH:mm";

export const formatDateObjectTime = "YYYY-MM-DD HH:mm";