import React, { useState, useContext, useEffect, Suspense, useRef } from "react";
import { client } from 'websocket';
import { Form, Button, FloatingLabel, InputGroup, Spinner } from "react-bootstrap";
import Avatar from 'react-avatar';
import Modal from 'react-bootstrap/Modal';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
// import PasswordStrengthBar from 'react-password-strength-bar';
import { useTranslation } from 'react-i18next';
import { setDefaultOptions } from 'date-fns'
import { cs, enGB } from 'date-fns/locale'
import { useNavigate, useLocation } from "react-router-dom";

import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { UserContext, isClient, getQuery } from '../../components';
import { SelectComponentDrivers } from "../../components/SelectComponent/SelectComponent"

const PasswordStrengthBar = React.lazy(() => import('react-password-strength-bar'));

export default function Profile() {
    const [userData, setUserData] = useState(false);
    const [edited, setEdited] = useState(false);
    const [save, setSave] = useState(false);
    const [saved, setSaved] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showpass, setShowpass] = useState(false);
    const [acctualPassword, setAcctualPassword] = useState(false);
    const [acctualPasswordWrong, setAcctualPasswordWrong] = useState(false);
    const [aPassCheck, setAPassCheck] = useState(false);
    const [newPassword1, setNewPassword1] = useState(false);
    const [newPassword2, setNewPassword2] = useState(false);
    const [newPasswordWrong, setNewPasswordWrong] = useState(false);
    const [users, setUsers] = useState(false);
    const [validPhone, setValidPhone] = useState(undefined);

    const [drivers, setDrivers] = useState(false)
    const [driversChoose, setDriversChoose] = useState(false)

    const refWS16 = useRef(null);
    const formRef = useRef(null);
    const refWS31 = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { setUser, user, costumer, clientGroups, setUiConfig, uiConfig } = useContext(UserContext);

    const { t, i18n } = useTranslation('translation', { keyPrefix: 'login' });

    document.title = i18n.t('widget.profile')

    if (!userData) {
        WsSend({ method: 'getUser', oid: 8 })
    }

    if (!refWS16.current && !drivers && isClient('ceskyRozhlas', costumer)) {
        WsSend({ method: 'getDrivers', oid: 16, ddd: null });
        refWS16.current = true;
    }

    if (!refWS31.current && !users && isClient('ceskyRozhlas', costumer)) {
        WsSend({ method: 'getUsers', oid: 31 });
        refWS31.current = true;
    }

    client.onmessage = (message) => {
        let data = WsAnal(message)
        console.log('new data Profile', data);
        //after update check if send data same like save data
        if (data && data.length > 0) {
            if (data[0].kind === 'change' && data[0].object === 8) {
                if (!drivers) {
                    setEdited(false);
                    setAPassCheck(false)
                    setSave(false);
                    setSaved(true);
                }
                setUser({ ...user, name: userData.name ? userData.name : userData.username });

                if (userData.lang !== i18n.language.substring(0, 2)) {
                    if (userData.lang === 'auto') i18n.changeLanguage(navigator.language.substring(0, 2));
                    else i18n.changeLanguage(userData.lang)
                    if (i18n.language.substring(0, 2) === 'cs') setDefaultOptions({ locale: cs })
                    else if (i18n.language.substring(0, 2) === 'en') setDefaultOptions({ locale: enGB })
                }
            }
            if (data.some(d => d.object === 16)) setDrivers(WSdata(data, drivers, 16));
            if (data.some(d => d.object === 31)) setUsers(WSdata(data, users, 31));
        } else {
            if (data && data.id === 16) {
                const res = WSdata(data, drivers, 16).list;
                setDrivers(res);
            }
            if (data && data.oid === 33) {
                if (data.value) {
                    setSave(false);
                    setSaved(true);
                    setEdited(false);
                    setAPassCheck(false);
                    navigate('/autopujcovna');
                }
            }
            if (data && data.id === 31) {
                const res = WSdata(data, users, 31).list;
                setUsers(res);
            }
            else if (data && data.oid === 30 && data.value) setDriversChoose({ driver: { ...driversChoose.driver, value: parseInt(data.value) } });
        }
        //update password in browser
        if (aPassCheck && data && data.oid === 9) {
            const dataOldLocaleStorage = JSON.parse(localStorage.getItem('login'));
            const dataOldSesionStorage = JSON.parse(sessionStorage.getItem('login'));
            const md5 = require('md5');
            if (dataOldLocaleStorage) {
                const dataNow = { ...dataOldLocaleStorage, pass: md5(newPassword1).toUpperCase() };
                localStorage.setItem('login', JSON.stringify(dataNow));
                setEdited(false);
                setSave(false);
                setAPassCheck(false)
                setSaved(true);
            }
            else if (dataOldSesionStorage) {
                const dataNow = { ...dataOldSesionStorage, pass: md5(newPassword1).toUpperCase() };
                sessionStorage.setItem('login', JSON.stringify(dataNow));
                setEdited(false);
                setSave(false);
                setAPassCheck(false)
                setSaved(true);
            }
        }
        //first new message
        if (data && data.id === 8) {
            setUserData(WSdata(data, null, 8))
        }
    }

    useEffect(() => {
        if (newPassword2 && newPassword1) setEdited(true)
    }, [newPassword2])

    useEffect(() => {
        if (driversChoose.driver) {
            setEdited(true)
            const driver = drivers.find(d => d.id === driversChoose.driver.value);
            if (driver) setUserData((userData) => { return { ...userData, rid: driver.rid } })
        }
        else setEdited(false)
    }, [driversChoose])

    useEffect(() => {
        if (drivers && users && !driversChoose) {
            const lstusr = drivers.find(d => d.lstusr === user.id)
            if (lstusr) {
                const driversNew = { driver: { label: lstusr.name, value: lstusr.id } }
                setUserData((userData) => { return { ...userData, rid: lstusr.rid } })
                setDriversChoose(driversNew)
            }
            setTimeout(() => {
                setEdited(false)
            }, 50);
        }
    }, [drivers, users])

    const handleOnChange = (value, type) => {
        setEdited(true);
        if (type === 'name') setUserData((userData) => { return { ...userData, name: value } })
        if (type === 'email') setUserData((userData) => { return { ...userData, email: value } })
        if (type === 'mobile') setUserData((userData) => { return { ...userData, mobile: value } })
        if (type === 'telegram') setUserData((userData) => { return { ...userData, telegram: value ? userData.mobile : '' } })
        if (type === 'lang') setUserData((userData) => { return { ...userData, lang: value } })
        if (type === 'rid') setUserData((userData) => { return { ...userData, rid: value } })
        if (type === 'supName') setUserData((userData) => { return { ...userData, supName: value } })
        if (type === 'supEmail') setUserData((userData) => { return { ...userData, supEmail: value } })
    }

    const saveData = (event) => {
        //Prevent page reload submit btn
        event.preventDefault();
        if (!aPassCheck) {
            setSave(true);
            setSaved(false);
            WsSend({
                method: 'setUser',
                name: driversChoose.driver ? driversChoose.driver.label : userData.name,
                mobile: userData.mobile,
                telegram: userData.telegram,
                email: userData.email,
                lang: userData.lang,
                empty: '',
                oid: 9
            })
            if (isClient('ceskyRozhlas', costumer) && driversChoose.driver) {
                const data = {
                    method: 'setDriver',
                    oid: 33,
                    driverid: driversChoose.driver.value,
                    lstusr: user.id,
                }
                if (userData.rid) data.rid = userData.rid;
                if (!driversChoose.driver.value) data.name = driversChoose.driver.label;
                if (userData.supName || userData.supEmail) {
                    let carRentalSup = {}
                    if (uiConfig.carRentalSup) carRentalSup = uiConfig.carRentalSup
                    if (userData.supName) carRentalSup.name = userData.supName;
                    if (userData.supEmail) carRentalSup.email = userData.supEmail;
                    setUiConfig({ ...uiConfig, carRentalSup })
                }
                WsSend(data);
            }
        }
        else {
            if (newPassword1 !== newPassword2) setNewPasswordWrong(true)
            else {
                setNewPasswordWrong(false)
                setSave(true);
                setSaved(false);
                WsSend({
                    method: 'setUser',
                    name: userData.name,
                    mobile: userData.mobile,
                    telegram: userData.telegram,
                    email: userData.email,
                    lang: userData.lang,
                    password: newPassword1,
                    oid: 9
                })
            }
        }
    }

    const checkAcctualPassword = () => {
        const md5 = require('md5');
        if (localStorage.getItem('login') && md5(acctualPassword).toUpperCase() === JSON.parse(localStorage.getItem('login')).pass) {
            setShowModal(false)
            setAPassCheck(true)
        }
        else if (sessionStorage.getItem('login') && md5(acctualPassword).toUpperCase() === JSON.parse(sessionStorage.getItem('login')).pass) {
            setShowModal(false)
            setAPassCheck(true)
        }
        else setAcctualPasswordWrong(true)
    }

    const WaringNewPasswordWrong = (() => {
        if (newPasswordWrong) {
            return (<Form.Text className="text-danger fw-bold mb-3 fs-7">{t('password_not_same')}</Form.Text>)
        }
    })

    const handleshowpass = () => { showpass ? setShowpass(false) : setShowpass(true) }
    if (!userData) return <></>
    return (
        <div className="bg-light d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <Form ref={formRef} className="w-50 d-flex flex-column align-items-center shadow p-3 mb-5 bg-body rounded" onSubmit={saveData}>
                <h2>{t('profile_user')}</h2>
                <Avatar size="80" textSizeRatio={2} maxInitials={2} round={true} className="sidebar-menu-nav-icon mt-1 mb-2" name={userData.name} />
                <FloatingLabel className="w-75 max-w-50vh my-2" label={t('user')} >
                    <Form.Control type="username" placeholder="username" defaultValue={userData.username} disabled />
                </FloatingLabel>
                {!aPassCheck ? <div className="fw-bold cursorpointer" onClick={() => { setShowModal(true); setAcctualPasswordWrong(false) }}>{t('change_password')}</div> : <><FloatingLabel className="w-75 max-w-50vh my-2" label={t('password_new')}>
                    <Form.Control type="password" placeholder="password" minLength={6} required onChange={(e) => { setNewPassword1(e.target.value) }} />
                </FloatingLabel>
                    <Suspense>
                        <PasswordStrengthBar minLength={6} shortScoreWord={[t('password_strength0')]} scoreWords={[t('password_strength0'), t('password_strength0'), t('password_strength2'), t('password_strength3'), t('password_strength4')]} className="w-50" password={newPassword1} /></Suspense>
                    <FloatingLabel className="w-75 max-w-50vh my-2" label={t('password_new_again')}>
                        <Form.Control type="password" placeholder="password" minLength={6} required onChange={(e) => { setNewPassword2(e.target.value) }} />
                    </FloatingLabel>
                    <WaringNewPasswordWrong />
                </>}
                {isClient('ceskyRozhlas', costumer) ? (<>
                    {getQuery('autopujcovna', location) ? <h3 className="mt-3 mb-2 text-center fw-bold">Pro pokračování do autopůjčovny vyplňte prosím následující formulář.</h3> : ''}
                    <FloatingLabel className="w-75 max-w-50vh my-2" style={{ zIndex: 3 }}>
                        <SelectComponentDrivers
                            drivers={drivers}
                            data={driversChoose}
                            setData={setDriversChoose}
                            className={'w-100 '}
                            height={600}
                            create={true}
                            wsSend={true}
                            required={true}
                            placeholder={'Uživatel / Řidič'}
                            notClearable={true}
                        />
                    </FloatingLabel>
                    <FloatingLabel className="w-75 max-w-50vh my-2" label={'Osobní číslo'}>
                        <Form.Control type="text" placeholder="Osobní číslo" required name="rid" value={userData.rid} onChange={(e) => { handleOnChange(e.target.value, 'rid') }} />
                    </FloatingLabel>
                </>) : (<>
                    <FloatingLabel className="w-75 max-w-50vh my-2" label={t('firstname_surname')}>
                        <Form.Control type="text" placeholder="First name and Surname" defaultValue={userData.name} onChange={(e) => { handleOnChange(e.target.value, 'name') }} />
                    </FloatingLabel>
                </>)}

                <FloatingLabel className="w-75 max-w-50vh my-2" label={t('email')}>
                    <Form.Control type="email" required={isClient('ceskyRozhlas', costumer)} placeholder="e-mail" defaultValue={userData.email} onChange={(e) => { handleOnChange(e.target.value, 'email') }} />
                </FloatingLabel>
                <FloatingLabel className="w-75 max-w-50vh my-2" label={t('phone')}>
                    <Form.Control type="text" required={isClient('ceskyRozhlas', costumer)} placeholder="mobile" isInvalid={validPhone === false} isValid={validPhone === true} defaultValue={userData.mobile} onChange={(e) => {
                        setTimeout(() => {
                            if (e.target.value.length > 3) {
                                if (/^\+\d+$/.test(e.target.value)) {
                                    setValidPhone(true)
                                    handleOnChange(e.target.value, 'mobile')
                                }
                                else setValidPhone(false)
                            }
                        }, 2000);
                    }} />
                    <Form.Control.Feedback type="invalid">
                        {t('phoneInvalid')}
                    </Form.Control.Feedback>
                </FloatingLabel>
                <Form.Check role="button" type="switch" label={t('has_telegram')} defaultChecked={userData.mobile && (userData.mobile === userData.telegram) ? true : false} disabled={!userData.mobile} onChange={(e) => { handleOnChange(e.target.checked, 'telegram') }} />
                {!isClient('ceskyRozhlas', costumer) ? <FloatingLabel className="w-75 max-w-50vh my-2" label={t('lang_after_login')}>
                    <Form.Select defaultValue={userData.lang} onChange={(e) => { handleOnChange(e.target.value, 'lang') }} aria-label="Floating label select example">
                        <option value='auto' >{t('lang_auto')}</option>
                        <option value="cs" >{t('lang_cs')}</option>
                        <option value="sk" >{t('lang_sk')}</option>
                        <option value="pl" >{t('lang_pl')}</option>
                        <option value="en" >{t('lang_en')}</option>
                        <option value="el" >{t('lang_el')}</option>
                    </Form.Select>
                </FloatingLabel> : <></>}
                {isClient('ceskyRozhlas', costumer) ? (<>
                    <h2 className="mt-3">Nadřízený, nebo schvalující osoba</h2>
                    <FloatingLabel className="w-75 max-w-50vh my-2" label={'Jméno příjemní'}>
                        <Form.Control type="text" required={!clientGroups.includes('admin')} placeholder="supName" defaultValue={uiConfig.carRentalSup ? uiConfig.carRentalSup.name : ''} onChange={(e) => { handleOnChange(e.target.value, 'supName') }} />
                    </FloatingLabel>
                    <FloatingLabel className="w-75 max-w-50vh my-2" label={t('email')}>
                        <Form.Control type="email" required={!clientGroups.includes('admin')} placeholder="supEmail" defaultValue={uiConfig.carRentalSup ? uiConfig.carRentalSup.email : ''} onChange={(e) => { handleOnChange(e.target.value, 'supEmail') }} />
                    </FloatingLabel>
                </>) : <></>}

                {saved ? <div className="text-success fw-bold">{t('save_confirm')}</div> : ''}
                <Button className={save ? "w2-5" : "w-75 " + "max-w-50vh mb-3 my-2 btn btn-dark"} type="submit" disabled={!edited || validPhone === false}>{
                    save ? <Spinner animation="border" role="status"><span className="visually-hidden">{t('loading')}</span></Spinner> : t('save')
                }</Button>
            </Form>

            <Modal centered show={showModal} onHide={() => { setShowModal(false) }} >
                <Modal.Header closeButton >
                    <Modal.Title>{t('fill_current_password')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="w-100  my-3" >
                        <FloatingLabel controlId="floatingPassword" label={t('current_password')} >
                            <Form.Control style={{ borderRadius: "0.375em", paddingRight: "2.5em" }} type={(showpass) ? "text" : "password"} placeholder="password" required onChange={(e) => { setAcctualPassword(e.target.value); }} />
                        </FloatingLabel>
                        <i className="position-absolute btn-eye pe-auto" role="button" onClick={handleshowpass}>{showpass ? <BsEyeSlash /> : <BsEye />}</i>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer style={{ flexDirection: 'column' }}>
                    {acctualPasswordWrong ? <span className="fw-bold text-danger mb-4">{t('password_bad')} !</span> : ''}
                    <Button variant="primary" onClick={checkAcctualPassword}>
                        {t('confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}