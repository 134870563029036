import React, { useEffect, useRef, useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { UserContext } from "../userDetails";
import { useTranslation } from 'react-i18next';
import { DateObject } from "react-multi-date-picker"
import DatePicker, { Calendar } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import { FiCalendar } from "react-icons/fi"
import { MdAccessTimeFilled, MdAccessTime } from "react-icons/md"
// import { FaCheck } from "react-icons/fa";

import { thisMonth, prevMonth, thisWeek, prevWeek, thisDay, resetTime } from "./DateSelect";
import { weekDaysCS, monthsCS, weekDaysEL, monthsEL, weekDaysSK, monthsSK, weekDaysPL, monthsPL, formatDate, formatDateTime } from "./DatePickerConfig"

import Modal from 'react-bootstrap/Modal';

import "./DatePicker.css"
import "react-multi-date-picker/styles/layouts/mobile.css"

export function DatePickerComponentFull({ date, setDate, option, h, w, m }) {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'date_picker' });

    const dateLocal = useRef(date);
    const datePickerRef = useRef();

    let timerForInputEdit;
    const [buttonActive, setButtonActive] = useState(0);
    const [open, setOpen] = useState(false);
    const mobile = ((w < 620 || h < 350) && !m) ? true : false
    const { setUiConfig } = useContext(UserContext);
    const uiConfig = useContext(UserContext).uiConfig;
    const time = useContext(UserContext).uiConfig.datePickerTime;
    const timeValue = useRef();
    const typeFormat = time ? formatDateTime : formatDate;



    useEffect(() => {
        if (date.length > 1) {
            if (date[0].format(formatDate) === thisDay()[0].format(formatDate) && date[1].format(formatDate) === thisDay()[1].format(formatDate)) setButtonActive(1);
            else if (date[0].format(formatDate) === thisWeek()[0].format(formatDate) && date[1].format(formatDate) === thisWeek()[1].format(formatDate)) setButtonActive(2);
            else if (date[0].format(formatDate) === prevWeek()[0].format(formatDate) && date[1].format(formatDate) === prevWeek()[1].format(formatDate)) setButtonActive(3);
            else if (date[0].format(formatDate) === thisMonth()[0].format(formatDate) && date[1].format(formatDate) === thisMonth()[1].format(formatDate)) setButtonActive(4);
            else if (date[0].format(formatDate) === prevMonth()[0].format(formatDate) && date[1].format(formatDate) === prevMonth()[1].format(formatDate)) setButtonActive(5);
            else setButtonActive(0)

            if (mobile) setOpen(false)
        }

    }, [date])

    useEffect(() => {
        if (open) datePickerRef.current.closeCalendar()
    }, [open])

    const handleSetDate = ((v, e) => {
        dateLocal.current = v;
        if (!mobile) {
            if (!e.isTyping) {
                if (v.length > 1) {
                    const dateWithTime = new DateObject((new Date(v[1])).setHours(timeValue.current.hour !== 0 ? timeValue.current.hour : 23, timeValue.current.minute !== 0 ? timeValue.current.minute : 59, 0, 0));
                    dateLocal.current = [v[0], dateWithTime]
                    datePickerRef.current.closeCalendar()
                }
            }
            else {
                if (v.length > 1) timeValue.current = { hour: v[1].hour, minute: v[1].minute }
            }
        }
        else {
            if (v.length > 1) {
                setOpen(false)
                const dateWithTime = new DateObject((new Date(v[1])).setHours(timeValue.current.hour !== 0 ? timeValue.current.hour : 23, timeValue.current.minute !== 0 ? timeValue.current.minute : 59, 0, 0));
                setDate([v[0], dateWithTime])
            }
        }
    })

    const skipWaiting = (e) => {
        if (e.key === 'Enter' && dateLocal.current) {
            datePickerRef.current.closeCalendar()
        }
    }

    function InputIcon({ onFocus, value, onChange }) {
        if (w > 700 || m) return (
            <div style={{ position: 'relative' }}>
                <input type="text" onKeyDown={skipWaiting} className={`rmdp-input${time ? " time" : ""}`} autoComplete="off" inputMode="none" onFocus={onFocus} value={value} onChange={onChange} />
                <FiCalendar className="icon-calendar fs-5" onClick={() => datePickerRef.current.openCalendar()} />
            </div>
        )
        else return (
            // style={{ position: 'relative', height: '50px', width: '35px' }}
            <Button variant="light py-2" style={{ height: '46px' }} onClick={() => datePickerRef.current.openCalendar()}>
                <FiCalendar className="fs-4" />
            </Button>
        )
    }

    function getLang(type) {
        let r = null;
        if (type === 'weekDays') {
            if (i18n.language.split('-')[0] === 'cs') r = weekDaysCS;
            else if (i18n.language.split('-')[0] === 'el') r = weekDaysEL;
            else if (i18n.language.split('-')[0] === 'sk') r = weekDaysSK;
            else if (i18n.language.split('-')[0] === 'pl') r = weekDaysPL;
        }
        else if (type === 'months') {
            if (i18n.language.split('-')[0] === 'cs') r = monthsCS;
            else if (i18n.language.split('-')[0] === 'el') r = monthsEL;
            else if (i18n.language.split('-')[0] === 'sk') r = monthsSK;
            else if (i18n.language.split('-')[0] === 'pl') r = monthsPL;
        }
        return r
    }

    function DayPicker() {
        return (
            <div className="dayPicker">
                <Button variant="outline-secondary" size="sm"
                    active={buttonActive === 1 ? true : false}
                    className={'mb-2'}
                    onClick={() => {
                        const today = thisDay(dateLocal.current);
                        dateLocal.current = today;
                        if (!mobile) datePickerRef.current.closeCalendar()
                        else setDate(dateLocal.current)
                    }} >
                    {t('today')}
                </Button>
                <Button variant="outline-secondary" size="sm"
                    active={buttonActive === 2 ? true : false}
                    className={'mb-2'} onClick={() => {
                        dateLocal.current = thisWeek();
                        if (!mobile) datePickerRef.current.closeCalendar()
                        else setDate(dateLocal.current)
                    }} >
                    {t('this_week')}
                </Button>
                <Button variant="outline-secondary" size="sm"
                    active={buttonActive === 3 ? true : false}
                    className={'mb-2'} onClick={() => {
                        dateLocal.current = prevWeek();
                        if (!mobile) datePickerRef.current.closeCalendar()
                        else setDate(dateLocal.current)
                    }} >
                    {t('last_week')}
                </Button>
                <Button variant="outline-secondary" size="sm"
                    active={buttonActive === 4 ? true : false}
                    className={'mb-2'} onClick={() => {
                        dateLocal.current = thisMonth()
                        if (!mobile) datePickerRef.current.closeCalendar()
                        else setDate(dateLocal.current)
                    }} >
                    {t('this_month')}
                </Button>
                <Button variant="outline-secondary" size="sm"
                    active={buttonActive === 5 ? true : false}
                    className={'mb-2'} onClick={() => {
                        dateLocal.current = prevMonth();
                        if (!mobile) datePickerRef.current.closeCalendar()
                        else setDate(dateLocal.current)
                    }} >
                    {t('last_month')}
                </Button>
                <div className="m-auto"></div>
                <Button variant="outline-secondary" size="sm"
                    className=" mb-2 border-0 d-flex align-items-center"
                    onClick={() => {
                        if (time) {
                            timeValue.current = { hour: 23, minute: 59 }
                            dateLocal.current = resetTime(dateLocal.current ? dateLocal.current : date)
                        }
                        setUiConfig({ ...uiConfig, datePickerTime: !time });
                    }}>
                    {time ? <MdAccessTimeFilled className="fs-4 me-1" /> : <MdAccessTime className="fs-4 me-1" />} {time ? t('hide_time') : t('show_time')}
                </Button>
            </div >
        )
    }

    return (
        <>
            <DatePicker
                portal={(option && option.page !== 'dddView') ? 'true' : ''}
                // className={mobile ? "rmdp-mobile" : ''}
                calendarPosition="bottom-center"
                render={<InputIcon />}
                ref={datePickerRef}
                value={date}
                onChange={handleSetDate}
                onOpen={() => {
                    if (mobile) setOpen(true);
                    dateLocal.current = date
                    if (!timeValue.current) timeValue.current = { hour: date[1].hour, minute: date[1].minute }
                }}
                onClose={() => {
                    if (dateLocal.current) setDate(dateLocal.current)
                }}
                weekDays={getLang('weekDays')}
                months={getLang('months')}
                weekStartDayIndex={1}
                displayWeekNumbers={false}
                format={typeFormat}
                dateSeparator=" - "
                range
                rangeHover
                plugins={[<DayPicker position="right" />]}
                mapDays={({ date }) => {
                    let props = {}
                    let isWeekend = [0, 6].includes(date.weekDay.index)
                    if (isWeekend) props.className = "highlight-weekend"
                    return props
                }}
            >
            </DatePicker >
            {/* <Button variant="light" ref={confirm} className="rmdp-container d-none" onClick={() => { setDate(tempDate.current); confirm.current.classList.add('d-none') }}><FaCheck className="text-secondary" /></Button> */}
            {mobile && open ? (<Modal
                centered
                show={open}
                className="d-flex justify-content-center"
                fullscreen='sm-down'
                onHide={() => setOpen(false)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Calendar
                        // className={mobile ? "rmdp-mobile" : ''}
                        render={<InputIcon />}
                        ref={datePickerRef}
                        value={date}
                        onChange={handleSetDate}
                        onOpen={() => setOpen(true)}
                        onClose={() => { setOpen(false) }}
                        weekDays={getLang('weekDays')}
                        months={getLang('months')}
                        weekStartDayIndex={1}
                        displayWeekNumbers={false}
                        format={typeFormat}
                        dateSeparator=" - "
                        range
                        rangeHover
                        plugins={[<DayPicker position="right" />]}
                        mapDays={({ date }) => {
                            let props = {}
                            let isWeekend = [0, 6].includes(date.weekDay.index)
                            if (isWeekend) props.className = "highlight-weekend"
                            return props
                        }}
                    />
                </Modal.Body>
            </Modal>) : ''
            }
        </>
    )
}

export function DatePickerComponent({ date, setDate, noTime, setSelectedTime, selectedTime, type, admin }) {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'date_picker' });

    const [time, setTime] = useState();
    const datePickerRef = useRef();
    const typeFormat = !noTime ? (time ? formatDateTime : formatDate) : formatDate;

    function InputIcon({ onFocus, value, onChange }) {
        return (
            <Button variant="light" onClick={() => datePickerRef.current.openCalendar()}>
                <FiCalendar className="fs-5 mb-1 me-3 text-secondary" />
                <span>{value}</span>
            </Button>
        )
    }

    useEffect(() => {
        if (type === 'dateFrom') setTime(selectedTime.dateFrom)
        else if (type === 'dateTo') setTime(selectedTime.dateTo)
        datePickerRef.current.closeCalendar()
    }, [selectedTime])

    function TimeSelect() {
        return (
            <Form.Select
                style={!time ? { background: '#9BD6FF' } : {}}
                value={time}
                onChange={(e) => {
                    if (e.target.value !== 'null') {
                        const o = e.target.value.split(':');
                        const newDate = new Date(date.toDate().setHours(parseInt(o[0]), parseInt(o[1]), 0, 0));
                        setDate(new DateObject(newDate))
                        if (type === 'dateFrom') setSelectedTime({ ...selectedTime, dateFrom: e.target.value })
                        else if (type === 'dateTo') setSelectedTime({ ...selectedTime, dateTo: e.target.value })
                    }
                }} className="text-center">
                <option value={'null'}>Vyberte čas*</option>
                <option>00:00</option>
                <option>01:00</option>
                <option>02:00</option>
                <option>03:00</option>
                <option>04:00</option>
                <option>05:00</option>
                <option>06:00</option>
                <option>07:00</option>
                <option>08:00</option>
                <option>09:00</option>
                <option>10:00</option>
                <option>11:00</option>
                <option>12:00</option>
                <option>13:00</option>
                <option>14:00</option>
                <option>15:00</option>
                <option>16:00</option>
                <option>17:00</option>
                <option>18:00</option>
                <option>19:00</option>
                <option>20:00</option>
                <option>21:00</option>
                <option>22:00</option>
                <option>23:00</option>
                <option>23:59</option>
            </Form.Select>
        )
    }

    return (
        <>
            <DatePicker
                calendarPosition="bottom-center"
                render={<InputIcon />}
                ref={datePickerRef}
                value={date}
                onChange={(e) => {
                    setDate(e)
                    if (!time) {
                        setTimeout(() => {
                            datePickerRef.current.openCalendar()
                        }, 1);
                    }
                }}
                weekDays={weekDaysCS}
                months={monthsCS}
                weekStartDayIndex={1}
                displayWeekNumbers={false}
                format={typeFormat}
                // plugins={!noTime ? [<TimePicker hideSeconds position="bottom" hStep={1} mStep={60} />] : []}
                plugins={!noTime ? [<TimeSelect position="bottom" />] : []}
                minDate={admin ? undefined : new Date().setHours(0, 0, 0, 0)}
                mapDays={({ date }) => {
                    let props = {}
                    let isWeekend = [0, 6].includes(date.weekDay.index)
                    if (isWeekend) props.className = "highlight-weekend"
                    if (!admin) {
                        const isPastDate = date.toDate() < new Date().setHours(0, 0, 0, 0);
                        if (isPastDate) props.disabled = true;
                    }
                    return props
                }}
            >
            </DatePicker >
            {type === 'dateFrom' && !selectedTime.dateFrom ? <div className='text-danger ms-3 cursorpointer' onClick={() => datePickerRef.current.openCalendar()}>Vyberte čas*</div> : ''}
            {type === 'dateTo' && !selectedTime.dateTo ? <div className='text-danger ms-3 cursorpointer' onClick={() => datePickerRef.current.openCalendar()}>Vyberte čas*</div> : ''}
        </>
    )
}