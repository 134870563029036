import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
// import { Button, Container, Dropdown, DropdownButton, Form, Accordion } from "react-bootstrap"
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Modal from 'react-bootstrap/Modal';
import { Form, Button, Spinner, InputGroup, Tooltip, OverlayTrigger, ButtonGroup } from "react-bootstrap";

import { FilterComponentDevices } from '../../components/SelectComponent/SelectComponent'
import { UserContext, isBrand } from "../../components";
import { WsSend } from "../../scripts/ws";

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';


export default function FenceModal({ fence, setFence, devices, contats, loadingSetFence, setLoadingSetFence, filters, linkAll, utexts }) {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'fences' });

    const userDetails = useContext(UserContext);
    const { clientGroups } = useContext(UserContext);
    const [filterDevice, setFilterDevice] = useState();
    const [eventt, setEventt] = useState();
    const [contactType, setContactType] = useState();
    const [contactVal, setContactVal] = useState();
    const [range, setRange] = useState([-1, 15]);
    const [rangeValue, setRangeValue] = useState(0)
    const [aux1, setAux1] = useState();
    let contact = contats.filter(c => c.id === parseInt(fence.contactId))[0];
    let dev;

    let event1602array = [
        { key: 'ign_on', val: 16, bitDec: 65536 },
        { key: 'ign_off', val: 0, bitDec: 1 }
    ]
    if (!isBrand('alfakomtemp')) {
        event1602array.push(
            { key: 'alternative_ride', val: 17, bitDec: 131072 },
            { key: 'alarm', val: 18, bitDec: 262144 },
            { key: 'manipulation', val: 25, bitDec: 33554432 },
            { key: 'towing', val: 26, bitDec: 67108864 },
            { key: 'green_drive', val: 27, bitDec: 134217728 },
            { key: 'crash', val: 31, bitDec: 2147483648 },
            { key: 'sos', val: 29, bitDec: 536870912 }
        )
    }

    const fenceEventt = [
        { value: 2105, label: t('eventt' + 2105), period: true },
        { value: 2101, label: t('eventt' + 2101), period: true },
        { value: 2102, label: t('eventt' + 2102), period: true },
        { value: 1602, label: t('eventt' + 1602) },
        { value: 213, label: t('eventt' + 213) }
    ]

    const fenceEventtReportAux1 = [
        { value: 11, label: t('type' + 11) },
        { value: 12, label: t('type' + 12) },
        { value: 1, label: t('type' + 1) },
        { value: 2, label: t('type' + 2) },
        { value: 7, label: t('type' + 7) },
        { value: 8, label: t('type' + 8) },
        { value: 9, label: t('type' + 9) },
        { value: 10, label: t('type' + 10) }
    ]

    const fenceEventt213Aux = [
        { value: 246, id: 'age1', min: 60, max: 7200, step: 60, current: 's', label: i18n.t('devices.age') }
    ]
    const eventtReport = [2105, 2101, 2102];
    const editData = useRef()
    const dayRef = useRef()

    if (!filterDevice) {
        if (fence.device < 1000000) {
            dev = devices.filter(d => d.id === parseInt(fence.device))[0];
        }
        else {
            dev = filters.filter(f => f.id === (parseInt(fence.device) - 1000000))[0]
            if (dev) dev = { ...dev, id: dev.id * (-1) }
            else if (parseInt(fence.device) === 1000000) dev = { value: -1000000, label: t('filterAll'), name: t('filterAll') }
        }
        if (dev) setFilterDevice({ value: dev.id, label: dev.name + ' ' + dev.plate, name: dev.name, plate: dev.plate, data: (dev.id > 0 ? dev : null) })
    }

    if (filterDevice) {
        editData.current = { ...editData.current, param: filterDevice.value > 0 ? filterDevice.value : (filterDevice.value === -1000000 ? 1000000 : (filterDevice.value * (-1) + 1000000)) }
        if (eventt === 213) {
            if (filterDevice.data) {
                Object.keys(filterDevice.data.astatus).forEach(astatus => {
                    switch (astatus) {
                        // copy id from PresenterFences sensors:
                        case 'pwr1': return fenceEventt213Aux.push({ value: 2, id: 'pwr1', min: 0, max: 30000, step: 100, current: 'mV', label: i18n.t('devices.pwr1') });
                        case 'pwr2': return fenceEventt213Aux.push({ value: 3, id: 'pwr2', min: 0, max: 10000, step: 100, current: 'mV', label: i18n.t('devices.pwr2') });
                        case 'bat': return fenceEventt213Aux.push({ value: 8, id: 'bat', min: 0, max: 100, step: 1, current: '%', label: i18n.t('devices.bat') });
                        case 'tmp0': return fenceEventt213Aux.push({ value: 10, id: 'tmp0', label: i18n.t('devices.tmp0') });
                        case 'tmp1': return fenceEventt213Aux.push({ value: 11, id: 'tmp1', label: i18n.t('devices.temperature') + ' 1' + (filterDevice.data.sensors.tmp1 ? ' - ' + filterDevice.data.sensors.tmp1 : '') });
                        case 'tmp2': return fenceEventt213Aux.push({ value: 12, id: 'tmp2', label: i18n.t('devices.temperature') + ' 2' + (filterDevice.data.sensors.tmp2 ? ' - ' + filterDevice.data.sensors.tmp2 : '') });
                        case 'tmp3': return fenceEventt213Aux.push({ value: 13, id: 'tmp3', label: i18n.t('devices.temperature') + ' 3' + (filterDevice.data.sensors.tmp3 ? ' - ' + filterDevice.data.sensors.tmp3 : '') });
                        case 'tmp4': return fenceEventt213Aux.push({ value: 14, id: 'tmp4', label: i18n.t('devices.temperature') + ' 4' + (filterDevice.data.sensors.tmp4 ? ' - ' + filterDevice.data.sensors.tmp4 : '') });
                        case 'tmp5': return fenceEventt213Aux.push({ value: 15, id: 'tmp5', label: i18n.t('devices.temperature') + ' 5' + (filterDevice.data.sensors.tmp5 ? ' - ' + filterDevice.data.sensors.tmp5 : '') });
                        case 'tmp6': return fenceEventt213Aux.push({ value: 16, id: 'tmp6', label: i18n.t('devices.temperature') + ' 6' + (filterDevice.data.sensors.tmp6 ? ' - ' + filterDevice.data.sensors.tmp6 : '') });
                        case 'tmp7': return fenceEventt213Aux.push({ value: 17, id: 'tmp7', label: i18n.t('devices.temperature') + ' 7' + (filterDevice.data.sensors.tmp7 ? ' - ' + filterDevice.data.sensors.tmp7 : '') });
                        case 'tmp8': return fenceEventt213Aux.push({ value: 18, id: 'tmp8', label: i18n.t('devices.temperature') + ' 8' + (filterDevice.data.sensors.tmp8 ? ' - ' + filterDevice.data.sensors.tmp8 : '') });
                        case 'rpm': return fenceEventt213Aux.push({ value: 251, id: 'rpm', min: 0, max: 10000, step: 100, current: 'rpm', label: i18n.t('devices.rpm') });
                    }
                })
            } else {
                fenceEventt213Aux.push(
                    { value: 2, id: 'pwr1', min: 0, max: 30000, step: 100, current: 'mV', label: i18n.t('devices.pwr1') },
                    { value: 3, id: 'pwr2', min: 0, max: 10000, step: 100, current: 'mV', label: i18n.t('devices.pwr2') },
                    { value: 8, id: 'bat', min: 0, max: 100, step: 1, current: '%', label: i18n.t('devices.bat') },
                    { value: 10, id: 'tmp0', label: i18n.t('devices.gine') },
                    { value: 11, id: 'tmp1', label: i18n.t('devices.temperature') + ' ' + 1 },
                    { value: 12, id: 'tmp2', label: i18n.t('devices.temperature') + ' ' + 2 },
                    { value: 13, id: 'tmp3', label: i18n.t('devices.temperature') + ' ' + 3 },
                    { value: 14, id: 'tmp4', label: i18n.t('devices.temperature') + ' ' + 4 },
                    { value: 15, id: 'tmp5', label: i18n.t('devices.temperature') + ' ' + 5 },
                    { value: 16, id: 'tmp6', label: i18n.t('devices.temperature') + ' ' + 6 },
                    { value: 17, id: 'tmp7', label: i18n.t('devices.temperature') + ' ' + 7 },
                    { value: 18, id: 'tmp8', label: i18n.t('devices.temperature') + ' ' + 8 },
                    { value: 251, id: 'rpm', min: 0, max: 10000, step: 100, current: 'rpm', label: i18n.t('devices.rpm') });
            }
        }
    }

    const handleOnChange = (value, type, bitDec) => {
        if (type === 'eventt') editData.current = { ...editData.current, eventt: value }
        if (type === 'isactive') editData.current = { ...editData.current, isactive: value }
        if (type === 'contact') editData.current = { ...editData.current, contact: value }
        if (type === 'aux1') {
            if (eventt === 213) {
                setAux1(value)
                editData.current = { ...editData.current, aux1: value.value };
                setRange([-1, 15])
                setRangeValue(0)
            }
            else {
                editData.current = { ...editData.current, aux1: value };
            }
            if (value === 12 && eventt === 2102) {
                dayRef.current.value = 28;
                editData.current = { ...editData.current, day: 28 }
            }
        }
        if (type === 'aux1eventt1602') {
            const aux = editData.current.aux1 ? editData.current.aux1 : 0;
            const newAux = value ? (aux + bitDec) : (aux - bitDec);
            editData.current = { ...editData.current, aux1: newAux };
        }
        if (type === 'day') editData.current = { ...editData.current, day: value }
    }

    const listContacts = ((contats, code) => {
        if (contats && contats.length > 0) {
            let res = [];
            if (code === 'memobox') {
                contats.forEach((f) => {
                    if (f.code === code && !res.some(r => r.label === f.ref)) res.push({ value: f.id, label: t('user') + ' - ' + (f.ref === undefined ? userDetails.user.name : f.ref) })
                })
            }
            else {
                contats.forEach((f) => {
                    if (f.code === code && !res.some(r => r.label === f.text)) res.push({ value: f.id, label: f.text })
                })
            }
            return res
        }
    })


    if (eventt === 213 && fence.aux1 && !aux1) {
        const res = fenceEventt213Aux.filter(c => c.value === fence.aux1);
        if (res.length > 0) setAux1(res[0]);
    }


    useEffect(() => {
        if (fence) {
            setEventt(fence.eventt);
            setTimeout(() => {
                if (contact) {
                    setContactVal({ value: contact.id, label: (contact.contactt === 29 ? t('user') + ' - ' + (contact.ref === undefined ? userDetails.user.name : contact.ref) : contact.text) })
                    setContactType(contact.code)
                }
            }, 50);
            if (fence.ranges && fence.ranges.length === 10) setRangeValue(fence.ranges[1])
            if (fence.ranges && fence.ranges.length === 20) setRange([fence.ranges[0], fence.ranges[1]])
            if (fence.eventt === 1602 && fence.aux1 && !editData.current.aux1) editData.current.aux1 = fence.aux1

        }
    }, [fence])

    useEffect(() => {
        if (eventt && eventt !== 213) setContactType('email')
        else setContactType(undefined)
    }, [eventt])

    useEffect(() => {
        if (contactVal) handleOnChange(contactVal.value, 'contact')
        else handleOnChange(null, 'contact')
    }, [contactVal])

    const handleNew = (value) => {
        if (contactType === 'email' && clientGroups.includes('admin')) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(value)) return true
            else return false
        }
    }

    return (
        <Modal size='xl' fullscreen='sm-down' centered scrollable={true} show={fence} onHide={() => {
            editData.current = false;
            contact = null;
            dev = null;
            setFence(false);
            setContactVal(false);
            setFilterDevice(false);
            setEventt(false);
            setRangeValue(0);
            setAux1(false);
            setContactType(false);
        }} >
            <Modal.Header closeButton >
                <Modal.Title>{t('header_' + (fence.id ? 'edit' : 'new'))}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    if (editData.current) {
                        setLoadingSetFence(true);
                        const params = {
                            method: 'setFence',
                            oid: 23
                        }
                        if (editData.current.isactive !== undefined) params.isactive = editData.current.isactive;
                        if (editData.current.eventt) params.eventt = editData.current.eventt;
                        if (editData.current.aux1 || editData.current.aux1 === 0) params.aux1 = editData.current.aux1;
                        if (editData.current.param) params.device = editData.current.param;
                        if (editData.current.contact) {
                            if (typeof editData.current.contact === 'number') params.contact = editData.current.contact
                            else params.newContact = editData.current.contact
                        }
                        if (editData.current.day) params.day = editData.current.day;
                        if (fence.id) params.id = fence.id;
                        else {
                            params.id = null;
                            params.mask = 2147483647;
                            params.day = !editData.current.day ? 1 : editData.current.day
                            if (!params.aux1 && fence.aux1) params.aux1 = fence.aux1;
                            if (!params.isactive && fence.isactive) params.isactive = fence.isactive;
                            if (!params.contact && fence.contactId) params.contact = fence.contactId;
                        }
                        if (eventt && (eventt === 213)) {
                            let text = [];
                            if (aux1.id.startsWith('tmp')) {
                                params.ranges = [range[0], range[1], 0.00, 0.00, 0.00, (range[1] - 0.20), null, 0.00, 0.00, 0.00, -100.00, (range[0] + 0.20), 0.00, 0.00, 0.00, null, -100.00, 0.00, 0.00, 0.00]
                                text = [t('utextEvent213_tmp_ok'), null, t('utextEvent213_tmp_high'), null, t('utextEvent213_tmp_low'), null, t('utextEvent213_tmp_error'), null];
                            }
                            else if (aux1.id === 'pwr1') {
                                params.ranges = [null, rangeValue, 900, 0, 0, rangeValue, null, 900, 0, 0];
                                text = [t('utextEvent213_pwr_low'), null, t('utextEvent213_pwr_ok'), null];
                            }
                            else if (aux1.id === 'bat') {
                                params.ranges = [null, rangeValue, 300, 0, 0, rangeValue, null, 300, 0, 0];
                                text = [t('utextEvent213_bat_low'), null, t('utextEvent213_bat_ok'), null];
                            }
                            else if (aux1.id === 'age1') {
                                params.ranges = [null, rangeValue, 0, 0, 0, rangeValue, null, 0, 0, 0];
                                text = [t('utextEvent213_age_ok'), null, t('utextEvent213_age_loss'), null];
                            }
                            else {
                                params.ranges = [null, rangeValue, 0, 0, 0, rangeValue, null, 0, 0, 0];
                                text = [t('utextEvent213_def_low'), null, t('utextEvent213_def_ok'), null];
                            }
                            params.utextss = text
                        }
                        if (params.eventt && (params.eventt === 2105 || params.eventt === 2101)) params.day = 1
                        editData.current = false;
                        WsSend(params);
                        editData.current = false;
                        contact = null;
                        dev = null;
                        setFence(false);
                        setContactVal(false);
                        setFilterDevice(false);
                        setEventt(false);
                        setRangeValue(0);
                        setAux1(false);
                        setContactType(false);
                    }
                    else {
                        editData.current = false;
                        contact = null;
                        dev = null;
                        setFence(false);
                        setContactVal(false);
                        setFilterDevice(false);
                        setEventt(false);
                        setRangeValue(0);
                        setAux1(false);
                        setContactType(false);
                    }
                }}>
                    <div className="d-flex flex-column align-items-center">
                        <div className="w-50  mb-3">
                            <span className="fw-bold">{t('kind')}</span>
                            <Select
                                // defaultMenuIsOpen
                                className="react-select-container"
                                classNamePrefix="react-select"
                                required
                                placeholder={t('placeHolderKind')}
                                // 11 only for statisticVehicle in future it is necesary edit
                                options={fence.aux1 === 11 ? fenceEventt.filter(fE => fE.period) : fenceEventt}
                                defaultValue={fence.eventt ? { value: fence.eventt, label: t('eventt' + fence.eventt) } : null}
                                noOptionsMessage={() => t('notFound')}
                                onChange={(selected) => { handleOnChange(selected.value, 'eventt'); setEventt(selected.value) }}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                        {eventtReport.includes(eventt) ?
                            <div className="w-50 mb-3">
                                <span className="fw-bold">{t('type')}</span>
                                <Select
                                    // defaultMenuIsOpen
                                    placeholder={t('placeHolderType')}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={linkAll ? fenceEventtReportAux1.slice(0, (eventt === 2102 ? 2 : 1)) : eventt !== 2102 ? fenceEventtReportAux1.filter(fe => fe.value !== 12) : fenceEventtReportAux1}
                                    defaultValue={fence.aux1 ? { value: fence.aux1, label: t('type' + fence.aux1) } : null}
                                    noOptionsMessage={() => t('notFound')}
                                    onChange={(selected) => { handleOnChange(selected.value, 'aux1') }}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    required
                                //  isDisabled={linkAll && eventt!== 2102 ? true : false}
                                />
                            </div>
                            : ''}
                        <div className="w-50  mb-3">
                            <span className="fw-bold">{t('object')}</span>
                            <div>
                                <FilterComponentDevices
                                    devices={devices}
                                    filters={filters}
                                    placeholder={t('placeHolderDevice')}
                                    filterDevice={filterDevice}
                                    setFilterDevice={setFilterDevice}
                                    height={400}
                                    isClearable={false}
                                    style={{ cursor: 'pointer', zIndex: 1, position: 'relative' }}
                                    required={true}
                                    admin={clientGroups.includes('admin')}
                                />
                            </div>
                        </div>

                        {eventt === 213 ?
                            <div className="w-50 mb-3">
                                <span className="fw-bold">{t('sensor')}</span>
                                <Select
                                    // defaultMenuIsOpen
                                    menuPlacement="auto"
                                    maxMenuHeight={200}
                                    placeholder={t('placeHolderSenzor')}
                                    className="react-select-container mb-3"
                                    classNamePrefix="react-select"
                                    options={fenceEventt213Aux}
                                    defaultValue={aux1} //fence.aux1 ? fenceEventt213Aux.filter(c => c.value === fence.aux1) : null
                                    noOptionsMessage={() => t('notFound')}
                                    onChange={(selected) => { handleOnChange(selected, 'aux1') }}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    required
                                    isDisabled={linkAll ? true : false}
                                />
                                {aux1 && aux1.id.startsWith('tmp') ?
                                    <>
                                        <span className="fw-bold">{t('monitored_range')}</span>
                                        <div className="d-flex justify-content-between align-items-center mt-1">
                                            <Form.Control
                                                min={-70}
                                                max={130}
                                                step={0.1}
                                                type="number"
                                                className="inpuRange"
                                                value={range[0]}
                                                onChange={(e) => { setRange([parseFloat(e.target.value), range[1]]) }}
                                            />
                                            <RangeSlider
                                                min={-25}
                                                max={60}
                                                step={1}
                                                className="mx-3"
                                                onInput={(e) => setRange(e)}
                                                value={range}
                                            />
                                            <Form.Control
                                                min={-70}
                                                max={130}
                                                step={0.1}
                                                type="number"
                                                className="inpuRange"
                                                value={range[1]}
                                                onChange={(e) => { setRange([range[0], parseFloat(e.target.value)]) }}
                                            />
                                        </div>
                                    </> : <></>}
                                {aux1 && !aux1.id.startsWith('tmp') ?
                                    <>
                                        <span className="fw-bold">{t('monitored_value')}</span>
                                        <div className="d-flex justify-content-between align-items-center mt-1">
                                            <Form.Range
                                                min={aux1.min}
                                                max={aux1.max}
                                                step={aux1.step}
                                                value={rangeValue}
                                                onChange={(e) => { setRangeValue(parseFloat(e.target.value)) }}
                                                className="me-2"
                                            />
                                            <InputGroup className="config">
                                                <Form.Control
                                                    min={aux1.min}
                                                    step={aux1.step}
                                                    type="number"
                                                    className="inpuRange"
                                                    value={rangeValue}
                                                    onChange={(e) => { setRangeValue(parseFloat(e.target.value)) }}
                                                />
                                                <InputGroup.Text>{aux1.current}</InputGroup.Text>
                                            </InputGroup>
                                        </div>
                                    </> : <></>}


                            </div>
                            : ''}

                        {eventt === 1602 ? (
                            <table className="table table-borderless w-25">
                                <tbody>
                                    <tr className="d-flex flex-column">
                                        {event1602array.map(event => {
                                            return (
                                                <td key={event.val} className="d-flex justify-content-between">
                                                    <div className="fw-bold me-5">{t(event.key)}</div>
                                                    <Form.Check
                                                        className="cursorpointer text-center ms-5"
                                                        type="switch"
                                                        defaultChecked={(((fence.aux1 >> event.val) & 0x1)) ? true : false}
                                                        onChange={(e) => {
                                                            handleOnChange(e.target.checked, 'aux1eventt1602', event.bitDec)
                                                        }}
                                                    />
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        ) : <></>}

                        {eventt === 213 || eventt === 1602 ?
                            <div className="w-50  mb-3">
                                <span className="fw-bold">{t('contact_type')}</span>
                                <ButtonGroup className="my-2 ms-4">
                                    <Button
                                        active={contactType === 'email'}
                                        variant="light"
                                        onClick={() => { setContactType('email'); setContactVal(null) }}
                                    >{t('contact_email')}
                                    </Button>
                                    <Button
                                        active={contactType === 'memobox'}
                                        variant="light"
                                        onClick={() => { setContactType('memobox'); setContactVal(null) }}
                                    >{t('notification')}
                                    </Button>
                                    <Button
                                        active={contactType === 'telegram'}
                                        variant="light"
                                        onClick={() => { setContactType('telegram'); setContactVal(null) }}
                                    >{t('telegram')}</Button>
                                </ButtonGroup>
                            </div> : <></>}

                        {contactType ?
                            <div className="w-50  mb-3">
                                <span className="fw-bold">{t('contact_' + contactType)}</span>
                                <CreatableSelect
                                    // defaultMenuIsOpen
                                    menuPlacement="auto"
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={listContacts(contats, contactType)}
                                    // menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    maxMenuHeight={200}
                                    placeholder={t('placeHolderContact')}
                                    value={contactVal}
                                    onChange={(selected) => { setContactVal(selected); }}
                                    required
                                    isValidNewOption={handleNew}
                                    formatCreateLabel={(e) => t('contact_' + contactType + 'Create') + ' "' + e + '"'}
                                    noOptionsMessage={() => contactType === 'email' ? (clientGroups.includes('admin') ? t('no_' + contactType + '_admin') : t('no_' + contactType)) : t('no_' + contactType)}
                                />
                            </div> : <></>}

                        {eventt && eventt === 2102 ?
                            <InputGroup className="w-25 mb-3">
                                <InputGroup.Text className="cursorhelp">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 400, hide: 250 }}
                                        overlay={(props) => (
                                            <Tooltip {...props}>
                                                {t('event2102DayTolltip')}
                                            </Tooltip>
                                        )}
                                    >
                                        <div>{t('event2102Day')}</div>
                                    </OverlayTrigger>
                                </InputGroup.Text>
                                <Form.Control min={1} max={28} ref={dayRef} defaultValue={fence.day ? fence.day : 1} type="number" onChange={(e) => { handleOnChange(e.target.value > 0 && e.target.value < 29 ? e.target.value : 1, 'day') }} />
                            </InputGroup>
                            : <></>}
                        <div className="mb-3 d-flex">
                            <div className="fw-bold me-3">{t('isactive')}</div>
                            <Form.Check
                                className="cursorpointer text-center"
                                type="switch"
                                defaultChecked={fence.isactive}
                                onChange={(e) => {
                                    handleOnChange(e.target.checked, 'isactive')
                                }}
                            />
                        </div>

                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <Button className={loadingSetFence ? "w2-5" : "w-50 " + "max-w-50vh mb-3 my-2 btn btn-dark"} type="submit" disabled={contactType ? false : true}>{
                            loadingSetFence ? <Spinner animation="border" role="status"><span className="visually-hidden">{t('loading')}</span></Spinner> : t('save')
                        }</Button>
                        {linkAll ? <Link to="/config/fences"><Button variant="link" className="text-secondary">{t('overvieFence')}</Button></Link> : <></>}
                    </div>
                </Form>
            </Modal.Body>
        </Modal >
    )
}