import { startOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subMonths, addMonths, subWeeks } from 'date-fns';
import { DateObject } from "react-multi-date-picker"

export function newDate(dateInput) {
    const stamFrom = new Date(dateInput[0]);
    const stamTo = new Date(dateInput[1]);
    const start = new DateObject(stamFrom);
    const end = new DateObject(stamTo);
    return [start, end]
}

export function thisDay(dateInput) {
    const now = new Date();
    let start;
    if (dateInput && dateInput.length === 1) start = dateInput[0]
    else start = new DateObject(now.setHours(0, 0, 0, 0));
    const end = new DateObject(now.setHours(23, 59, 0, 0));
    return [start, end]
}

export function thisWeek() {
    const now = new Date();
    const start = new DateObject(startOfWeek(now));
    const end = new DateObject(endOfWeek(now));
    return [start, end]
}

export function prevWeek() {
    const now = new Date();
    const previousWeek = subWeeks(now, 1)
    const start = new DateObject(startOfWeek(previousWeek));
    const end = new DateObject(endOfWeek(previousWeek));
    return [start, end]
}

export function thisMonth() {
    const now = new Date();
    const start = new DateObject(startOfMonth(now));
    const end = new DateObject(endOfMonth(now));
    return [start, end]
}

export function prevMonth() {
    const now = new Date();
    const previousMonth = subMonths(now, 1)
    const start = new DateObject(startOfMonth(previousMonth));
    const end = new DateObject(endOfMonth(previousMonth));
    return [start, end]
}

export function lastThreeMonths() {
    const now = new Date();
    const previousMonth = subMonths(now, 3)
    const start = new DateObject(startOfMonth(previousMonth));
    const end = new DateObject(endOfMonth(now));
    return [start, end]
}

export function resetTime(dateInput) {
    const stamFrom = new Date(dateInput[0]).setHours(0, 0, 0, 0);
    const stamTo = new Date(dateInput[1]).setHours(23, 59, 0, 0);
    const start = new DateObject(stamFrom);
    const end = new DateObject(stamTo);
    return [start, end]
}