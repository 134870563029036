import { parseISO, format, isSameYear, isToday, formatDistance, formatDistanceStrict, getTime } from 'date-fns'
import i18n from '../../dictionary/i18n';

import { Park, Ign, Off, On } from "../../images"
import { iconKind } from "../../images/kind"

export const iconStatus = ({ value, data }) => {

    if (!data.isstatic) return (
        <div className='rgt-cell-inner rgt-cell-component' style={{ margin: '0 5px' }}>
            <span className='rgt-text-truncate' style={{ marginRigh: '10px' }}>
                <span style={{ transform: 'scale(0.9)' }}>
                    {value & 1 ? <Ign /> : <Park />}
                </span>
                {iconKind(data, true)}
            </span>
        </div>
    )
    else return (
        <div className='rgt-cell-inner rgt-cell-component' style={{ margin: '0 5px' }}>
            <span className='rgt-text-truncate' style={{ marginRigh: '10px' }}>
                <span style={{ transform: 'scale(0.9)' }}>
                    {value & 1 ? <On /> : <Off />}
                </span>
                {iconKind(data, true)}
            </span>
        </div>
    )
}
export const addressstamp = ({ value, data }) => {
    if (!data.addressstamp) return (<></>)
    let aStamp = parseISO(value);
    return (
        <div className='rgt-cell-inner rgt-cell-component'>
            <span className='rgt-text-truncate' title={i18n.t('devices.addressstampdetail')}>
                {isToday(aStamp) ? format(aStamp, 'HH:mm') : (isSameYear(aStamp, new Date()) ? format(aStamp, 'dd.MM HH:mm') : format(aStamp, 'dd.MM.yyyy HH:mm'))}
            </span>
        </div>
    )
}
export const statusstamp = ({ value }) => {
    if (!value) return (<></>)
    return (
        <div className='rgt-cell-inner rgt-cell-component'>
            <span className='rgt-text-truncate'>
                {formatDistance(parseISO(value), new Date())}
            </span>
        </div>
    )
}

export const ridestamp = ({ value }) => {
    if (!value) return (<></>)
    return (
        <div className='rgt-cell-inner rgt-cell-component'>
            <span className='rgt-text-truncate'>
                {formatDistanceStrict(parseISO(value), new Date(), { addSuffix: true })}
            </span>
        </div>
    )
}

export const statusstampSort = ({ a, b, isAscending }) => {
    let aa = a ? getTime(parseISO(a)) : getTime(new Date(1));
    let bb = b ? getTime(parseISO(b)) : getTime(new Date(1));
    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
}

export const nameSort = ({ a, b, isAscending }) => {
    return a.localeCompare(b) * (isAscending ? 1 : -1);
}

export const statusSort = ({ a, b, isAscending }) => {
    let aa = parseInt(a) & 1;
    let bb = parseInt(b) & 1;
    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
}

export const getCallRender = (name => {
    if (name === 'status') return iconStatus;
    else if (name === 'addressstamp') return addressstamp;
    else if (name === 'statusstamp') return statusstamp;
    else if (name === 'lastRide') return ridestamp;
    else return null
})

export const getSortF = (name => {
    if (name === 'statusstamp') return statusstampSort;
    if (name === 'name') return nameSort;
    if (name === 'status') return statusSort;
    else return null
})

export const getLabel = (name => {
    return i18n.t('devices.' + name);
})

export const columnsDevicesView = [
    {
        id: 1,
        index: 0,
        field: 'status',
        cellRenderer: iconStatus,
        width: '115px',
        visible: true,
        sort: statusSort,
    },
    {
        id: 2,
        index: 1,
        field: 'name',
        width: 'auto',
        visible: true,
        sort: nameSort,
    },
    {
        id: 3,
        index: 2,
        field: 'drivername',
        width: 'auto',
        visible: false,
        sort: nameSort,
    },
    {
        id: 4,
        index: 3,
        field: 'plate',
        width: 'auto',
        visible: true,
    },
    {
        id: 5,
        index: 4,
        field: 'address',
        width: 'auto',
        visible: true,
    },
    {
        id: 6,
        index: 5,
        field: 'addressstamp',
        cellRenderer: addressstamp,
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 7,
        index: 6,
        field: 'statusstamp',
        cellRenderer: statusstamp,
        width: 'auto',
        visible: true,
        searchable: false,
        sort: statusstampSort,
    },
    {
        id: 8,
        index: 7,
        field: 'lastRide',
        cellRenderer: ridestamp,
        width: 'auto',
        visible: false,
        searchable: false,
        sort: statusstampSort,
    },
    {
        id: 9,
        index: 8,
        field: 'speed',
        width: 'auto',
        visible: true,
    },
    {
        id: 10,
        index: 9,
        field: 'tacho',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 11,
        index: 10,
        field: 'tank',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 12,
        index: 10,
        field: 'tmps',
        width: 'auto',
        visible: false,
        searchable: false,
    },
];

