import React, { useEffect, useState, useRef } from "react";
import GridTable from '@nadavshaar/react-grid-table';
import { useTranslation } from 'react-i18next';
import { Button } from "react-bootstrap"

import { AiOutlineSearch } from 'react-icons/ai'
import { FiDownload } from 'react-icons/fi';

import { dddListFilesColumns } from "./DDDViewColumns";
import { dddListFilesRows, dddListFilesRowsAll } from "./DDDViewRows";
import { formatDateObjectTime } from "../../components/DatePickerComponent/DatePickerConfig"
import { newDate, thisWeek } from "../DatePickerComponent/DateSelect";
import { FilterComponentDevices, FilterComponentDrivers } from "../SelectComponent/SelectComponent"
import { DatePickerComponentFull } from "../DatePickerComponent/DatePickerComponent"
import { WsSend } from "../../scripts/ws";
import Loading from "../Loading";

import './DDDViewStyle.css'

export default function DDDViewListFiles({ filesAll, files, devices, drivers, height, width, max, modal, stamp, setOpenFile, filesList, setFileDownload, loading, setLoading, setSelectedModal }) {
    const { t } = useTranslation('translation');
    const [rows, setRows] = useState([]);
    const [filterDevice, setFilterDevice] = useState(false);
    const [filterDrivers, setFiletrDrivers] = useState(false);
    // const [fileDownload, setFileDownload] = useState(false);

    const [date, setDate] = useState(false);
    const page = 1;
    const pageSize = 5000;
    // const [page, setPage] = useState(1);
    // const [pageSize, setPageSize] = useState(5000);
    const [selected, setSelected] = useState([]);
    const refRenderDate = useRef(null);
    const refWS19 = useRef(null);
    // const refTimeout = useRef(null);

    const c = dddListFilesColumns(setFileDownload, setOpenFile, modal);

    useEffect(() => {
        if (files) {
            const rows = dddListFilesRows(files, stamp).filter(i => i.kind === filesList.param);
            setRows(rows);
        }
    }, [files, stamp])

    useEffect(() => {
        if (filesAll) {
            setRows(dddListFilesRowsAll(filesAll, page, pageSize))
            setLoading(false)
        }
    }, [filesAll])

    if (!modal && !refWS19.current && !filesAll && date.length > 1) {
        refWS19.current = true;
        WsSend({
            method: 'getFiles',
            ddd: null,
            oid: 21,
            stampFrom: new Date(date[0]),
            stampTo: new Date(date[1]),
            page: page,
            pageSize: pageSize,
        });

    }

    useEffect(() => {
        if (!date && sessionStorage.getItem('rides.date')) setDate(newDate(JSON.parse(sessionStorage.getItem('rides.date'))))
        else if (!date) {
            const firstDate = thisWeek();
            if (firstDate.length > 1) {
                setDate(firstDate)
            }
        }
        if (date.length > 1) {
            // setPage(1)
            if (refRenderDate.current) {
                WsSend({
                    method: 'modify',
                    id: 21,
                    params: {
                        stampFrom: new Date(date[0]),
                        stampTo: new Date(date[1]),
                        page: 1
                    }
                });
                sessionStorage.setItem('rides.date', JSON.stringify([date[0].format(formatDateObjectTime), date[1].format(formatDateObjectTime)]))
                setLoading(true);
            }
            //skip first render where set first date
            setTimeout(() => {
                refRenderDate.current = true;
            }, 100);
        }
    }, [date])

    useEffect(() => {
        if (refRenderDate.current) {
            setLoading(true);
            let params = { device: filterDevice ? filterDevice.value : null };
            if (filterDrivers) params.driver = filterDrivers.value;
            WsSend({
                method: 'modify',
                id: 21,
                params: params
            });
        }
    }, [filterDevice])

    useEffect(() => {
        if (refRenderDate.current) {
            setLoading(true);
            let params = { driver: filterDrivers ? filterDrivers.value : null };
            if (filterDevice) params.device = filterDevice.value;
            WsSend({
                method: 'modify',
                id: 21,
                params: params
            });
        }
    }, [filterDrivers])

    // const handleNextPage = (nextPage) => {
    //     setPage(nextPage);
    //     if (refTimeout.current) clearTimeout(refTimeout.current);
    //     refTimeout.current = setTimeout(() => {
    //         WsSend({
    //             method: 'modify',
    //             id: 21,
    //             params: {
    //                 page: nextPage
    //             }
    //         })
    //     }, 1000);
    // }

    // const handlePageSize = (newPageSize) => {
    //     setPageSize(newPageSize);
    //     setPage(1)
    //     if (refTimeout.current) clearTimeout(refTimeout.current);
    //     refTimeout.current = setTimeout(() => {
    //         WsSend({
    //             method: 'modify',
    //             id: 21,
    //             params: {
    //                 pageSize: newPageSize,
    //                 page: 1
    //             }
    //         });
    //     }, 1000);
    // }

    return (
        <div style={{ height: height + 'px' }}>
            {modal ? null : (
                <div className="d-flex pt-3 pb-3">
                    <FilterComponentDevices
                        devices={devices}
                        filterDevice={filterDevice}
                        setFilterDevice={setFilterDevice}
                        height={height}
                    />
                    <FilterComponentDrivers
                        drivers={drivers}
                        filterDrivers={filterDrivers}
                        setFiletrDrivers={setFiletrDrivers}
                        height={height}
                    />
                    <DatePickerComponentFull
                        date={date}
                        setDate={setDate}
                        w={width}
                        h={height}
                        m={max}
                    />
                    <Button variant="light my-2 ms-2"
                        disabled={!selected.length}
                        onClick={() => {
                            WsSend({
                                method: 'getDownloadsFiles',
                                ids: selected,
                                oid: 25,
                            })
                        }}
                    ><FiDownload className="fs-5" /></Button>

                </div>
            )}
            {!loading ? (
                <GridTable
                    style={{ minHeight: (rows.length > 0 ? 'unset' : '150px'), height: rows.length > 10 ? (height - 95) + 'px' : (height - 95) + 'px' }}
                    columns={c}
                    isLoading={loading}
                    rows={rows}
                    showSearch={false}
                    showColumnVisibilityManager={false}
                    showRowsInformation={rows.length > 10 ? true : false}
                    isPaginated={rows.length > 10 ? true : false}
                    pageSizes={[10, 20, 100, 500]}
                    texts={{
                        search: '',
                        noResults: t('devices.notFind'),
                        totalRows: t('tablePaginated.totalRows'),
                        rows: t('tablePaginated.rows'),
                        rowsPerPage: t('tablePaginated.rowsPerPage'),
                        page: t('tablePaginated.page'),
                        of: t('tablePaginated.of'),
                        prev: t('tablePaginated.prev'),
                        next: t('tablePaginated.next')
                    }}
                    className="DDDViewListFiles"
                    icons={{ search: <AiOutlineSearch /> }}
                    // onPageChange={nextPage => handleNextPage(nextPage)}
                    // onPageSizeChange={newPageSize => handlePageSize(newPageSize)}
                    enableColumnsReorder={false}
                    selectAllMode={'all'}
                    onSelectedRowsChange={(selectedRowsIds, tableManager) => {
                        if (setSelectedModal) setSelectedModal(selectedRowsIds)
                        else setSelected(selectedRowsIds)
                    }}
                />) : (<Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} className="mt-5" height={height - 100} />)}
        </div>

    )
}